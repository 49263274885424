import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yearsToFormatted'
})
export class YearsToFormattedPipe implements PipeTransform {

  transform(yearsDecimal: number): string {
    if (isNaN(yearsDecimal)) {
      return 'Invalid input';
    }

    const totalMonths = Math.round(yearsDecimal * 12);
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    let result = '';
    if (years > 0) {
      result += `${years} yr `;
    }
    if (months > 0 || years === 0) {
      result += `${months} mos`;
    }

    if (result.trim() === '0 mos') {
      return '';
    }

    return result.trim();
  }
}
