import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgObjectPipesModule, NgArrayPipesModule } from 'ngx-pipes-updated';
import { SocketioModule } from 'ngx-socketio2-updated';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CandidateSidebarModule } from './components/pr-candidate-sidebar/pr-candidate-sidebar.module';
import { HeaderBarModule } from './components/pr-header-bar/pr-header-bar.module';
import { JobDescDialogModule } from './components/pr-job-desc-dialog/pr-job-desc-dialog.module';
import { NoteDialogModule } from './components/pr-note-dialog/pr-note-dialog.module';
import { NotesListModule } from './components/pr-notes-list/pr-notes-list.module';
import { OmniSearchModule } from './components/pr-omni-search/pr-omni-search.module';
import { ResumesDialogModule } from './components/pr-resumes-dialog/pr-resumes-dialog.module';
import { TaskDialogModule } from './components/pr-task-dialog/pr-task-dialog.module';
import { TasksListModule } from './components/pr-tasks-list/pr-tasks-list.module';

import { AdminGuard } from './guards/admin.guard';
import { AdminAITrainerGuard } from './guards/admin-ai-trainer.guard';
import { AdminBillingGuard } from './guards/admin-billing.guard';
import { AdminCompanySettingsGuard } from './guards/admin-company-settings.guard';
import { AdminRedisGuard } from './guards/admin-redis.guard';
import { AdminUserManagementGuard } from './guards/admin-user-management.guard';
import { AuthGuard } from './guards/auth.guard';
import { CoreAppGuard } from './guards/core-app.guard';
import { IframeGuard } from './guards/iframe.guard';
import { NegateAuthGuard } from './guards/auth-negate.guard';

import { HomeModule } from './routes/home/home.module';
import { EmailActionsModule } from './routes/email-actions/email-actions.module';
import { ErrorAccessDeniedModule } from './routes/errors/error-access-denied/error-access-denied.module';
import { ErrorNotFoundModule } from './routes/errors/error-not-found/error-not-found.module';
import { IntakeFormModule } from './routes/intake-form/intake-form.module';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxEditorModule } from 'ngx-editor';
import { BnNgIdleService } from 'bn-ng-idle';

const socketIoTransport = window.location.hostname !== 'localhost' ? ['websocket'] : ['polling', 'websocket'];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    NgArrayPipesModule,
    NgObjectPipesModule,
    SocketioModule.forRoot({ url: '', options: { transports: socketIoTransport } }),

    AppRoutingModule,

    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatTooltipModule,

    CandidateSidebarModule,
    HeaderBarModule,
    JobDescDialogModule,
    NoteDialogModule,
    NotesListModule,
    OmniSearchModule,
    ResumesDialogModule,
    TaskDialogModule,
    TasksListModule,

    HomeModule,
    EmailActionsModule,
    ErrorAccessDeniedModule,
    ErrorNotFoundModule,
    IntakeFormModule,

    NgxChartsModule,
    NgxEditorModule
  ],
  providers: [
    AdminGuard,
    AdminAITrainerGuard,
    AdminBillingGuard,
    AdminCompanySettingsGuard,
    AdminRedisGuard,
    AdminUserManagementGuard,
    AuthGuard,
    CoreAppGuard,
    IframeGuard,
    NegateAuthGuard,

    CookieService,
    BnNgIdleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
