import { Component, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements AfterViewInit, OnDestroy {
  @Input() value?: string;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() inputType = 'text';
  @Input() fieldClass?: string;
  @Input() width?: string;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() disabled = false;
  @Input() multiline = false;
  @Input() clear = false;
  @Input() autoFocus = false;
  @Input() iconPrefix?: string;
  @Input() iconSuffix?: string;
  @Input() textPrefix?: string;
  @Input() textSuffix?: string;
  @Input() min?: number;
  @Input() max?: number;
  @Input() matAutocomplete?: MatAutocomplete;
  @Output() focused = new EventEmitter<any>();
  @Output() blurred = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter<any>();

  private destroy$ = new Subject<void>();

  textChange$ = new Subject<string>();
  @ViewChild('inputRef1', { static: false }) inputRef1?: ElementRef<HTMLInputElement>;
  @ViewChild('inputRef2', { static: false }) inputRef2?: ElementRef<HTMLInputElement>;

  constructor() {
    this.textChange$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (this.inputType === 'number') {
        value = value.replace(/^0+/, '');
      }

      this.value = value;
      this.valueChange.emit(value);
    });

    this.blurred.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.clear) {
        if (this.inputRef1) this.inputRef1.nativeElement.value = '';
        if (this.inputRef2) this.inputRef2.nativeElement.value = '';
      }
    });
  }

  ngAfterViewInit() {
    if (this.autoFocus) {
      if (this.inputRef1) this.inputRef1.nativeElement.focus();
      if (this.inputRef2) this.inputRef2.nativeElement.focus();
    }
  }

  getInputClass() {
    if (this.iconPrefix && !this.iconSuffix) {
      return 'form-control form-control__prefix';
    } else if (this.iconSuffix && !this.iconPrefix) {
      return 'form-control form-control__suffix';
    } else if (this.iconPrefix && this.iconSuffix) {
      return 'form-control form-control__prefix form-control__suffix';
    } else {
      return 'form-control';
    }
  }

  focus(event: string) {
    this.focused.next(event);
  }

  blur(event: string) {
    this.blurred.next(event);
  }

  emit(value: string) {
    this.value = value;
    this.valueChange.emit(value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
