import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class AuthGuard {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.userInfo$.pipe(
      map(() => {
        if (this.check(route)) {
          return true;
        }

        void this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return false;
      })
    );
  }

  check(route: ActivatedRouteSnapshot) {
    return this.auth.isAuthenticated || route.queryParams.iframeSource;
  }
}