<input #inputRef type="text" matInput [value]="_text || ''" (input)="onTextInput(inputRef.value)" (focus)="focus()"
  (blur)="blur($event)" [matAutocomplete]="auto" [matAutocompleteDisabled]="!api" />

<mat-autocomplete #auto [displayWith]="displayFn" (optionSelected)="setItem($event.option.value)">
  <ng-container *ngIf="items$ | async as items">
    <ng-container *ngIf="items.success">
      <mat-option *ngFor="let item of items.data" [value]="item" (mousedown)="$event.preventDefault()">
        {{item?.text}}
      </mat-option>
    </ng-container>
  </ng-container>
</mat-autocomplete>