import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { filter, take, takeUntil, switchMap, debounceTime, map } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

import { distinctUntilChangedDeep } from 'src/app/utils/rxjs-operators';

import { ProgressService } from './progress.service';

@Injectable({
  providedIn: 'root'
})
export class NavService implements OnDestroy {
  private destroy$ = new Subject<void>();

  public hasLoadedOnce = false;
  public params: Record<number, Param<any>> = {};

  private changeQueryParams$ = new Subject<void>();

  private foundParams: Params = {};

  constructor(
    private progress: ProgressService,

    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      take(1),
      switchMap(() => this.activatedRoute.queryParams),
      takeUntil(this.destroy$)
    ).subscribe(newParams => {
      if (!this.hasLoadedOnce) {
        this.foundParams = newParams;
        setTimeout(() => this.foundParams = {}, 1000);
      }

      this.hasLoadedOnce = true;
      // console.log(newParams);

      Object.values(this.params).forEach(param => {
        const curValue = param.parse(param.subject$.value);
        const newValue = param.parse(newParams[param.key] || param.defaultValue);

        if (!isEqual(curValue, newValue)) {
          param.subject$.next(newValue || param.defaultValue);
        }
      });
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      take(1),
      switchMap(() => this.changeQueryParams$),
      debounceTime(200),
      map(() => {
        const queryParams: Record<string, any> = {};

        for (const param of Object.values(this.params)) {
          const defaultValue = param.stringify(param.defaultValue);
          const value = param.stringify(param.subject$.value);

          // console.log([param.key, param.defaultValue, param.subject$.value, defaultValue, value]);

          // eslint-disable-next-line eqeqeq
          if (value != defaultValue) {
            queryParams[param.key] = value;
          }
        }

        return queryParams;
      }),
      distinctUntilChangedDeep(),
      takeUntil(this.destroy$)
    ).subscribe(queryParams => {
      void this.router.navigate([], { queryParams });
    });
  }

  get qs() {
    return this.activatedRoute.snapshot.queryParams;
  }

  createNumericParam<T = number>(key: string, defaultValue: NoInfer<T>, destroy$: Subject<void>): BehaviorSubject<NoInfer<T>> {
    return this.createParam('number', false, key, defaultValue, destroy$);
  }

  createNumericArrayParam<T = number[]>(key: string, defaultValue: NoInfer<T>, destroy$: Subject<void>): BehaviorSubject<NoInfer<T>> {
    return this.createParam('number', true, key, defaultValue, destroy$);
  }

  createStringParam<T = string>(key: string, defaultValue: NoInfer<T>, destroy$: Subject<void>): BehaviorSubject<NoInfer<T>> {
    return this.createParam('string', false, key, defaultValue, destroy$);
  }

  createStringArrayParam<T = string[]>(key: string, defaultValue: NoInfer<T>, destroy$: Subject<void>): BehaviorSubject<NoInfer<T>> {
    return this.createParam('string', true, key, defaultValue, destroy$);
  }

  createParam<T>(type: 'string' | 'number', isArray: boolean, key: string, defaultValue: T, destroy$: Subject<void>): BehaviorSubject<T> {
    // console.log('create', key);

    const stringify = (x: any) => isArray ? x.join(',') : x === 0 ? x : (x || '') + '';
    const parse = (x: any) => {
      if (x == null) return isArray ? [] : x;

      if (isArray && !(x instanceof Array)) x = x.split(',');
      if (!isArray && x instanceof Array) x = x[0];

      if (x instanceof Array) {
        return x.map(y => type === 'number' ? +y : y).filter(y => y != null);
      } else {
        return type === 'number' ? +x : x;
      }
    };

    const currentValue = parse(this.foundParams[key] || this.activatedRoute.snapshot.queryParams[key]) ?? defaultValue;

    const subject$ = new BehaviorSubject<any>(currentValue);
    // const destroy$ = new Subject<void>();

    const i = Math.random();
    const param: Param<T> = {
      key,
      defaultValue,
      stringify,
      parse,
      subject$
      // destroy$
    };
    this.params[i] = param;

    destroy$.subscribe(() => {
      delete this.params[i];
      // console.log('remove', key);
      this.changeQueryParams$.next();
    });

    subject$.pipe(
      // skip(1),
      takeUntil(destroy$),
      // takeUntil(destroy$),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      // console.log('change', key);
      this.changeQueryParams$.next();
    });

    return subject$;
  }

  public goToPath(path: string) {
    void this.router.navigateByUrl(path);
  }

  openWindow(url: string) {
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

type NoInfer<T> = [T][T extends any ? 0 : never];

interface Param<T> {
  key: string
  defaultValue: T,
  stringify: (x: any) => string
  parse: (x: string) => any
  subject$: BehaviorSubject<T>
  // destroy$: Subject<any>
}