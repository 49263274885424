<div class="d-flex flex-column" style="min-height: 100vh">
  <div class="h-100 d-flex justify-content-center align-items-center" style="flex: 1; background: linear-gradient(180deg, rgba(144, 202, 249, 1) 2%, rgba(227, 242, 253, 1) 100%)">
    <ng-container
      *ngIf="{
       emailAction: emailAction$ | async,
     } as actions">
      <ng-container *ngIf="!showQuestionnaire">
        <div class="thank-dialog bg-white">
          <div class="heading mb-3 fw-semibold mat-color-primary-500">Thank you!</div>
          <div class="sub-heading mb-4 fw-normal">
            {{ candidateResponse === CandidateResponse.INTEREST ? 'A recruiter will be in touch shortly.' : 'Your response has been received.' }}
          </div>
          <div class="mb-3 body-lg fw-medium" *ngIf="jobsMatched.length">
            {{
              candidateResponse === CandidateResponse.INTEREST
                ? 'In the meantime, here are some other jobs you may be interested in:'
                : 'If you are exploring other options, here are some other jobs you may be interested in:'
            }}
          </div>

          <div class="jobs" *ngIf="jobsMatched.length">
            <ng-container *ngFor="let job of jobsMatched; let i = index">
              <div class="w-100 d-flex align-items-center border job" *ngIf="i < 5">
                <div class="text-truncate">{{ job.jobTitle || 'No job title listed' }}</div>
                <div class="text-truncate">{{ job.cityState || 'No location listed' }}</div>
                <div class="text-truncate">{{ job.salary ? '$' + job.salary + ' ' + job.payRate : 'No pay range listed' }}</div>
                <div class="d-flex align-items-center">
                  <button
                    mat-icon-button
                    style="width: 25px; height: 25px; border-radius: 5px; padding: 0; margin-right: 15px"
                    class="mat-background-13-500"
                    (click)="handleMatchedJob(CandidateResponse.INTEREST, job.jobId)">
                    <mat-icon class="md-icon-20 text-white">check</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    style="width: 25px; height: 25px; border-radius: 5px; padding: 0"
                    class="mat-background-warn-500"
                    (click)="handleMatchedJob(CandidateResponse.DECLINE, job.jobId)">
                    <mat-icon class="md-icon-18 text-white">close</mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showQuestionnaire">
        <div class="container-fluid align-items-stretch questionnaire" style="background: linear-gradient(180deg, rgba(144, 202, 249, 1) 2%, rgba(227, 242, 253, 1) 100%)">
          <!--FORM-->
          <div class="row questionnaire-row" *ngIf="actions.emailAction">
            <div class="col">
              <div class="form-wrapper bg-white px-4 pt-4 pb-0 overflow-y-auto">
                <ng-container>
                  <div class="border-bottom pb-3">
                    <span class="body-xl fw-medium"> Candidate Questionnaire </span>
                  </div>

                  <mat-stepper
                    #stepper
                    class="overflow-hidden"
                    labelPosition="bottom"
                    [selectedIndex]="selectedTab"
                    (selectedIndexChange)="selectedTab = $event"
                    [orientation]="(stepperOrientation | async)!">
                    <!--DEMOGRAPHICS-->
                    <mat-step [completed]="false">
                      <ng-template matStepLabel>
                        <span class="fw-normal body-md">Demographics</span>
                      </ng-template>

                      <ng-template matStepContent [formGroup]="candidateInfoForm">
                        <ng-container *ngIf="selectedTab === 0">
                          <ng-container
                            *ngIf="{
                              demographics: demographics$ | async
                            } as results">
                            <div class="container">
                              <div class="content-height">
                                <div class="row pb-2">
                                  <div class="col-lg">
                                    <label class="form-label"> First Name </label>
                                    <div class="w-100"></div>
                                    <input #fname type="text" formControlName="firstName" class="form-control" />
                                    <mat-error *ngIf="!candidateInfoForm.controls.firstName.valid">First name is required.</mat-error>
                                  </div>

                                  <div class="w-100 d-lg-none" style="height: 1rem"></div>

                                  <div class="col-lg">
                                    <label class="form-label"> Last Name </label>
                                    <div class="w-100"></div>
                                    <input #lname formControlName="lastName" class="form-control" />
                                    <mat-error *ngIf="!candidateInfoForm.controls.lastName.valid">Last name is required.</mat-error>
                                  </div>
                                </div>

                                <div class="row py-2">
                                  <div class="col-lg">
                                    <label class="form-label"> Preferred First Name </label>
                                    <div class="w-100"></div>
                                    <input type="text" class="form-control" formControlName="preferredName" />
                                  </div>
                                  <div class="w-100 d-lg-none" style="height: 1rem"></div>
                                  <div class="col-lg">
                                    <label class="form-label"> Pronouns </label>
                                    <select class="form-select" formControlName="pronounsId">
                                      <ng-container *ngIf="{ pronouns: pronouns$ | async } as result">
                                        <ng-container *ngIf="result.pronouns">
                                          <option *ngFor="let item of result.pronouns" [value]="item.id">
                                            {{ item.pronounsDesc }}
                                          </option>
                                        </ng-container>
                                      </ng-container>
                                    </select>
                                  </div>
                                </div>

                                <div class="row py-2">
                                  <div class="col-lg">
                                    <label class="form-label"> Email Address </label>
                                    <div class="w-100 d-lg-none"></div>
                                    <input type="text" class="form-control" formControlName="email" />
                                    <div class="w-100 d-lg-none"></div>
                                    <mat-error *ngIf="!candidateInfoForm.controls.email.valid">Please enter valid email address.</mat-error>
                                  </div>
                                </div>

                                <div class="row py-2">
                                  <div class="col-lg">
                                    <label class="form-label"> Location </label>

                                    <app-input-select
                                      label=""
                                      iconSuffix="search"
                                      [api]="searchCitystate"
                                      fieldClass="form-control"
                                      [text]="candidateInfoForm.controls.geoId.value?.text"
                                      [value]="''"
                                      (itemChange)="candidateInfoForm.controls.geoId.setValue($event)">
                                    </app-input-select>
                                  </div>
                                </div>

                                <div class="row py-2">
                                  <div class="col">
                                    <div class="d-flex">
                                      <div>
                                        <label class="form-label"> Phone Number </label>
                                        <div class="w-100 d-lg-none"></div>
                                        <input type="text" class="form-control" formControlName="phoneNumber" appPhoneFormControlMask placeholder="(___)___-___" />
                                        <div class="w-100 d-lg-none"></div>
                                        <mat-error *ngIf="!candidateInfoForm.controls.phoneNumber.valid">Please enter valid phone number.</mat-error>
                                      </div>
                                      <div class="align-self-end ps-2">
                                        <mat-checkbox formControlName="smsOptOut"> Opt in to SMS updates </mat-checkbox>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row py-2">
                                  <div class="col">
                                    <label class="form-label"> Resume </label>
                                    <div class="w-100"></div>
                                    <input #uploadFile type="file" accept=".pdf, .docx, .doc, .html" class="form-control" (change)="onFileSelected($event)" class="d-none" />
                                    <div class="w-100"></div>
                                    <div class="d-flex align-items-center justify-content-between">
                                      <button mat-raised-button color="primary" (click)="uploadFile.click()">
                                        <mat-icon class="md-icon-18">cloud_upload</mat-icon>
                                        Upload
                                      </button>
                                      <div class="my-2" *ngIf="this.file">{{ this.file.name }}</div>
                                    </div>
                                    <div class="w-100"></div>
                                    <span class="body-sm mat-color-gray-600">Supported file formats: .pdf, .docx, .doc, .rtf, .html. File should be less than 100 MB.</span>
                                  </div>
                                </div>
                              </div>
                              <!--PAGINATOR-->
                              <div class="row border-top mt-3 align-self-bottom">
                                <div class="col mt-3">
                                  <div class="d-flex align-items-center justify-content-end">
                                    <label class="form-label me-2 mb-0"> Next</label>
                                    <button class="modal-close-icon-btn" [disabled]="!candidateInfoForm.valid || loading" (click)="saveDemographics()">
                                      <span class="material-symbols-outlined"> arrow_forward </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-template>
                    </mat-step>

                    <!--WORK PREFERENCES-->
                    <mat-step [completed]="false">
                      <ng-template matStepLabel>
                        <span class="fw-normal body-md">Work Preferences</span>
                      </ng-template>

                      <ng-template matStepContent [formGroup]="workStatusForm">
                        <ng-container *ngIf="selectedTab === 1">
                          <ng-container
                            *ngIf="{
                              workPreferences: workPreferences$ | async,
                            } as results">
                            <div class="container">
                              <div class="content-height">
                                <!--LINE 1-->
                                <div class="row pb-2">
                                  <div class="col">
                                    <label class="form-label"> Work Status </label>
                                    <ng-container *ngIf="{ WorkStatus: WorkStatus$ | async } as result">
                                      <ng-container *ngIf="result.WorkStatus">
                                        <select class="form-select" formControlName="workStatusId">
                                          <option *ngFor="let item of result.WorkStatus" [value]="item.workStatusId">
                                            {{ item.workStatusDesc }}
                                          </option>
                                        </select>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </div>
                                <!--LINE 2-->
                                <div class="row py-2">
                                  <div class="col">
                                    <label class="form-label"> Desired Employment Type </label>
                                    <div class="w-100"></div>

                                    <ng-container *ngIf="{ positionTypes: positionTypes$ | async } as result">
                                      <ng-container *ngIf="result.positionTypes">
                                        <mat-form-field class="w-100 mat-multi-select" style="max-height: 35px" subscriptSizing="dynamic">
                                          <mat-select multiple="true" formControlName="positionTypeId">
                                            <mat-option *ngFor="let item of result.positionTypes" [value]="item.positionTypeId">
                                              {{ item.positionType }}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="!workStatusForm.controls.positionTypeId.valid">Desired employment type is required.</mat-error>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </div>
                                <!--LINE 3-->
                                <div class="row py-2">
                                  <div class="col">
                                    <label class="form-label">Desired Range (USD)</label>
                                    <div class="d-flex justify-content-between">
                                      <div class="align-self-center me-2"><span>From:</span></div>
                                      <input type="number" formControlName="minPayRate" class="form-control" />

                                      <div class="align-self-center mx-2"><span>To:</span></div>
                                      <input type="number" formControlName="maxPayRate" class="form-control" />

                                      <select class="form-select ms-3" formControlName="payTypeId">
                                        <ng-container *ngIf="{ payRateTypes: payRateTypes$ | async } as result">
                                          <ng-container *ngIf="result.payRateTypes">
                                            <option *ngFor="let item of result.payRateTypes" [value]="item.payRateId">
                                              {{ item.payRateDesc }}
                                            </option>
                                          </ng-container>
                                        </ng-container>
                                      </select>
                                    </div>
                                    <div>
                                      <mat-error *ngIf="!workStatusForm.controls.minPayRate.valid">Minimum pay rate should be less than maximum.</mat-error>
                                    </div>
                                  </div>
                                </div>
                                <!--LINE 4-->
                                <div class="row py-2">
                                  <div class="col">
                                    <label class="form-label"> Earliest Available Date </label>
                                    <div class="input-group">
                                      <div class="input-group-text input-group-text__prefix p-0">
                                        <mat-datepicker-toggle [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                      </div>
                                      <input class="form-control" [matDatepicker]="picker1" formControlName="availableDate" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!--PAGINATOR-->
                              <div class="row border-top mt-3 d-xs-none d-lg-flex">
                                <div class="col mt-3">
                                  <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                      <button class="modal-close-icon-btn" matStepperPrevious>
                                        <span class="material-symbols-outlined"> arrow_back</span>
                                      </button>
                                      <label class="form-label ms-2 mb-0"> Previous</label>
                                    </div>
                                    <div class="d-flex align-items-center">
                                      <label class="form-label me-2 mb-0"> Next</label>
                                      <button class="modal-close-icon-btn" [disabled]="!workStatusForm.valid || loading" (click)="saveWorkPreferencesAndStatus()">
                                        <span class="material-symbols-outlined"> arrow_forward </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-template>
                    </mat-step>

                    <!--EEO-->
                    <mat-step [completed]="false">
                      <ng-template matStepLabel>
                        <span class="fw-normal body-md">EEO</span>
                      </ng-template>

                      <ng-template matStepContent [formGroup]="eeocForm">
                        <div class="content-height">
                          <div class="row">
                            <div class="col">
                              <label class="body-sm mat-color-gray-400 fw-light">
                                {{ companyInfo?.EEOstatement }}
                              </label>
                            </div>
                          </div>
                          <!--LINE 1-->
                          <div class="row py-3">
                            <ng-container *ngIf="selectedTab === 2">
                              <ng-container
                                *ngIf="{
                                  eeoc: eeoc$ | async,
                                } as results">
                                <div class="col">
                                  <label class="form-label">Please select your gender (optional) </label>
                                  <select class="form-select" id="genderId" formControlName="genderId">
                                    <ng-container *ngIf="{ genders: genders$ | async } as result">
                                      <ng-container *ngIf="result.genders">
                                        <option *ngFor="let item of result.genders" [value]="item.genderId">
                                          {{ item.gender }}
                                        </option>
                                      </ng-container>
                                    </ng-container>
                                  </select>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                          <!--LINE 2-->
                          <div class="row py-3">
                            <div class="col">
                              <label class="form-label">Please select the race/ethnicity which most accurately describes how you identify yourself (optional) </label>
                              <ng-container>
                                <select class="form-select" formControlName="ethnicityId">
                                  <ng-container *ngIf="{ ethnicities: ethnicities$ | async } as result">
                                    <ng-container *ngIf="result.ethnicities">
                                      <option *ngFor="let item of result.ethnicities" [value]="item.ethnicityId">
                                        {{ item.ethnicityDesc }}
                                      </option>
                                    </ng-container>
                                  </ng-container>
                                </select>
                              </ng-container>
                            </div>
                          </div>
                          <!--LINE 3-->
                          <div class="row py-3">
                            <div class="col">
                              <label class="form-label">Please indicate your veteran status (optional) </label>

                              <select class="form-select" formControlName="veteranStatusId">
                                <ng-container *ngIf="{ veteranStatuses: veteranStatuses$ | async } as result">
                                  <ng-container *ngIf="result.veteranStatuses">
                                    <option *ngFor="let item of result.veteranStatuses" [value]="item.veteranStatusId">
                                      {{ item.veteranStatusDesc }}
                                    </option>
                                  </ng-container>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <!--PAGINATOR-->
                        <div class="row border-top mt-3">
                          <div class="col mt-3">
                            <div class="d-flex align-items-center justify-content-between">
                              <div class="d-flex align-items-center">
                                <button class="modal-close-icon-btn" matStepperPrevious>
                                  <span class="material-symbols-outlined"> arrow_back</span>
                                </button>
                                <label class="form-label ms-2 mb-0"> Previous</label>
                              </div>
                              <div class="d-flex align-items-center">
                                <label class="form-label me-2 mb-0"> Next</label>
                                <button class="modal-close-icon-btn" [disabled]="!eeocForm.valid || loading" (click)="saveEEOC()">
                                  <span class="material-symbols-outlined"> arrow_forward </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </mat-step>

                    <!--REVIEW-->
                    <mat-step [completed]="false">
                      <ng-template matStepLabel>
                        <span class="fw-normal body-md">Review</span>
                      </ng-template>

                      <ng-template matStepContent>
                        <div class="container overflow-y-auto content-height">
                          <div class="row pb-3">
                            <div class="col-lg">
                              <label class="form-label m-0"> First Name </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ candidateInfoForm.value.firstName }} </label>
                            </div>
                            <div class="col-lg">
                              <div class="w-100 d-lg-none"></div>
                              <label class="form-label m-0"> Last Name </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ candidateInfoForm.value.lastName }} </label>
                            </div>
                          </div>

                          <div class="row py-2" *ngIf="candidateInfoForm.value.preferredName || candidateInfoForm.value.pronouns">
                            <div class="col-lg" *ngIf="candidateInfoForm.value.preferredName">
                              <label class="form-label m-0"> Preferred first Name </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ candidateInfoForm.value.preferredName }} </label>
                            </div>
                            <div class="col-lg" *ngIf="candidateInfoForm.value.pronouns">
                              <label class="form-label m-0"> Pronouns </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ candidateInfoForm.value.pronouns }} </label>
                            </div>
                          </div>
                          <div class="row py-2">
                            <div class="col">
                              <label class="form-label m-0"> Email Address </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ candidateInfoForm.value.email }} </label>
                            </div>
                          </div>
                          <div class="row py-2" *ngIf="candidateInfoForm.value.geoId?.text">
                            <div class="col">
                              <label class="form-label m-0"> Location </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ candidateInfoForm.value.geoId?.text }} </label>
                            </div>
                          </div>
                          <div class="row py-2 align-items-center" *ngIf="candidateInfoForm.value.phoneNumber">
                            <div class="col-lg">
                              <label class="form-label m-0"> Phone Number </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ candidateInfoForm.value.phoneNumber }} </label>
                            </div>
                            <div class="col-lg">
                              <div class="d-flex align-items-center">
                                <fieldset [disabled]="true">
                                  <input type="checkbox" [formControl]="candidateInfoForm.controls.smsOptOut" />
                                </fieldset>

                                <label class="hint ms-2">Opt in to SMS updates </label>
                              </div>
                            </div>
                          </div>
                          <div class="row py-2" *ngIf="file">
                            <div class="col">
                              <label class="form-label m-0"> Resume </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ file?.name }} </label>
                            </div>
                          </div>
                          <div class="row py-2" *ngIf="workStatusForm.value.workStatus">
                            <div class="col">
                              <label class="form-label m-0"> Work Status </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ workStatusForm.value.workStatus }} </label>
                            </div>
                          </div>
                          <div class="row py-2" *ngIf="workStatusForm.value.positionTypeIds?.length">
                            <div class="col">
                              <label class="form-label m-0"> Desired Employment Type </label>
                              <div class="w-100"></div>
                              <label *ngFor="let type of workStatusForm.value.positionTypeIds; let i = index" class="mt-2 hint">
                                <span *ngIf="i !== 0">, </span>
                                <span>{{ getPositionType(type) }}</span>
                              </label>
                            </div>
                          </div>
                          <div class="row py-2" *ngIf="workStatusForm.value.minPayRate && workStatusForm.value.maxPayRate && workStatusForm.value.payType">
                            <div class="col">
                              <label class="form-label m-0"> Desired Range </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> From: {{ workStatusForm.value.minPayRate }} To: {{ workStatusForm.value.maxPayRate }} {{ workStatusForm.value.payType }} </label>
                            </div>
                          </div>
                          <div class="row py-2" *ngIf="workStatusForm.value.availableDate">
                            <div class="col">
                              <label class="form-label m-0"> Earliest Available Date </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ workStatusForm.value.availableDate | date }} </label>
                            </div>
                          </div>
                          <div class="row py-2" *ngIf="gender">
                            <div class="col">
                              <label class="form-label m-0"> Gender </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ gender }} </label>
                            </div>
                          </div>
                          <div class="row py-2" *ngIf="race">
                            <div class="col">
                              <label class="form-label m-0"> Race/Ethnicity </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ race }} </label>
                            </div>
                          </div>
                          <div class="row py-2" *ngIf="veteran">
                            <div class="col">
                              <label class="form-label m-0"> Veteran Status </label>
                              <div class="w-100"></div>
                              <label class="mt-2 hint"> {{ veteran }} </label>
                            </div>
                          </div>
                        </div>

                        <div class="row border-top mt-3">
                          <div class="col mt-3">
                            <div class="d-flex align-items-center justify-content-between">
                              <div class="d-flex align-items-center">
                                <button class="modal-close-icon-btn" matStepperPrevious>
                                  <span class="material-symbols-outlined"> arrow_back</span>
                                </button>
                                <label class="form-label ms-2 mb-0"> Previous</label>
                              </div>
                              <button mat-raised-button color="primary" (click)="submit()">
                                <mat-icon class="md-icon-18">check_circle</mat-icon>
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </mat-step>
                  </mat-stepper>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <!--FOOTER-->
  <div class="container-fluid">
    <div class="row align-items-center bg-white py-3">
      <div class="col-xs col-md-4 col-xl-2">
        <img class="c-pointer logo" style="outline: 0" alt="ProRank" [src]="companyInfo?.logoUrl" *ngIf="companyInfo?.logoUrl" />
      </div>
      <div class="col-0 col-md-4 col-xl-8"></div>
      <div class="col-sm col-md-4 col-xl-2">
        <div class="text-center text-md-end">
          <label> {{ companyInfo?.addressLine1 }}{{ companyInfo?.addressLine1 && companyInfo?.addressLine2 ? (', ' + companyInfo?.addressLine2) : '' }}</label>
          <div class="w-100"></div>

          <label> {{ companyInfo?.cityState }} {{ companyInfo?.zipCode }} </label>
          <div class="w-100"></div>

          <label> Phone: {{ companyInfo?.phoneNumber }} </label>
          <div class="w-100"></div>

          <label> Email: {{ companyInfo?.email }} </label>
        </div>
      </div>
    </div>
  </div>
</div>
