import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { addMinutes, format, getDate, getHours, getMinutes, getMonth, getSeconds, getYear, isAfter, set, startOfDay } from 'date-fns';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() label?: string;
  @Input() minuteInterval = 15;
  @Input() fieldClass?: string;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() format = 'datetime';
  @Input() disabled = false;
  @Input() iconPrefix?: string;
  @Input() iconSuffix?: string;
  @Output() valueChange = new EventEmitter<number>();

  @Input()
  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value || Date.now() / 1000;
    this._valueDate = new Date(this.value * 1000);
    // this.pText = this.pValue.local().format({ date: 'll', time: 'LT', datetime: 'lll' }[this.format]);
  }
  _value = Date.now() / 1000;
  _valueDate = new Date();

  times: { value: number, text: string, group: number }[] = [];
  partsOfDay = [
    { text: 'Night', firstHour: 0, show: true },
    { text: 'Morning', firstHour: 6, show: true },
    { text: 'Afternoon', firstHour: 12, show: true },
    { text: 'Evening', firstHour: 17, show: true },
    { text: 'Night', firstHour: 21, show: true }
  ];

  overlayPositions: ConnectedPosition[] = [
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
    { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' }
  ];

  openOverlay?: HTMLElement;

  constructor() {
    const setTimes = (date?: number) => {
      const d = startOfDay(date || new Date()).getTime();

      this.times = new Array(24 * 60 / this.minuteInterval)
        .fill(0)
        .map((_x, i) => i)
        .map(x => addMinutes(d, x * this.minuteInterval).getTime())
        .map(x => ({ value: x, text: format(x, 'p'), group: this.partsOfDay.filter(y => getHours(x) >= y.firstHour).length }));
    };

    this.valueChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      setTimes(value * 1000);
    });

    setTimes(this.value * 1000);
  }

  ngOnInit() {
    this.valueChange.next(Math.floor(this.value));
  }

  setDate(date: Date) {
    try {
      this.value = set(this.value * 1000, { year: getYear(date), month: getMonth(date), date: getDate(date), milliseconds: 0 }).getTime() / 1000;
    }
    catch (ex) { null; }

    this.valueChange.next(Math.floor(this.value));
  }

  setTime(time: number) {
    this.value = set(this.value * 1000, { hours: getHours(time), minutes: getMinutes(time), seconds: getSeconds(time), milliseconds: 0 }).getTime() / 1000;
    this.valueChange.next(Math.floor(this.value));

    this.openOverlay = undefined;
  }

  filterFuture = (date: Date | null) => date ? isAfter(date, Date.now()) : false;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
