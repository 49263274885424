import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiService } from './../api.service';

@Injectable({
  providedIn: 'root'
})
export class GroupsService implements OnDestroy {
  private destroy$ = new Subject<void>();

  search = (searchValue?: string) => this.api.get<Group[]>({
    path: 'recruiter/groups', cacheDuration: -1, //////////////
    params: { searchValue }
  }).pipe(
    takeUntil(this.destroy$)
  );

  save = (group: Group) => this.api.post<Group>({
    path: 'recruiter/groups',
    body: { groupId: group.groupId, groupName: group.groupName, isActive: group.isActive }
  }).pipe(
    takeUntil(this.destroy$)
  );

  getEmployees = (groupId: number) => this.api.get<GroupMember[]>({
    path: 'recruiter/groups/members',
    params: { groupId }
  }).pipe(
    takeUntil(this.destroy$)
  );

  saveEmployee = (groupId: number, user: GroupMember) => this.api.post<GroupMember[]>({ ////////////// Merge groupId into GroupMember
    path: 'recruiter/groups/members',
    body: { groupId, userId: user.userId, isActive: user.isActive }
  }).pipe(
    takeUntil(this.destroy$)
  );

  constructor(
    private api: ApiService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface Group {
  groupId: number;
  groupName: string;
  isActive: 0 | 1;
}

export interface GroupMember {
  userId: number;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  isAdmin: 0 | 1;
  isActive: 0 | 1;
}
