import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, BehaviorSubject, of, combineLatest, merge } from 'rxjs';
import { takeUntil, filter, map, switchMap, startWith, take } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEqual } from 'lodash-es';

import { successData, truthy, switchMapSuccess } from 'src/app/utils/rxjs-operators';

import { NotesService, Note, Filter } from 'src/app/services/api/notes.service';

import { ApiResult, DataTable } from 'src/app/services/api.service';
import { ContextService } from 'src/app/services/context.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-pr-notes-list',
  templateUrl: './pr-notes-list.component.html',
  styleUrls: ['./pr-notes-list.component.scss']
})
export class NotesListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() header = true;
  @Input() filter = true;
  @Input() sidebar = true;
  @Input() profileRef?: string;

  private _listResult$ = new BehaviorSubject<ApiResult<DataTable<Note>> | undefined>(undefined);

  @Input()
  public get listResult() {
    return this._listResult$.value;
  }

  public set listResult(value) {
    this._listResult$.next(value);
  }

  noteFilters$ = this.notes.filters().pipe(
    takeUntil(this.destroy$)
  );

  noteFilter$ = new BehaviorSubject<Filter | undefined>(undefined);

  paginationOffset$ = new BehaviorSubject(0);
  notes$ = merge(
    this._listResult$.pipe(
      truthy()
    ),
    combineLatest([
      this.noteFilter$.pipe(
        truthy()
      ),
      this.paginationOffset$
    ]).pipe(
      switchMap(([_filter, offset]) => this.notes.get(_filter.filterId, this.profileRef, { offset }))
    )
  ).pipe(
    takeUntil(this.destroy$)
  );

  saveNote$ = new Subject<{ item: Note, updates: Partial<Note> }>();
  saveNoteResult$ = this.saveNote$.pipe(
    map(({ item, updates }) => ({
      origItem: { ...item },
      newItem: { ...item, ...updates }
    })),
    filter(({ origItem, newItem }) => !isEqual(origItem, newItem)),
    switchMap(({ origItem, newItem }) =>
      this.notes.save(newItem).pipe(
        switchMap(result =>
          of(result).pipe(
            switchMapSuccess(() =>
              this.snackBar.open('Saved', 'Undo', { duration: 5 * 1000 }).onAction().pipe(
                switchMap(() => this.notes.save(origItem)),
                startWith(result)
              )
            )
          )
        )
      )
    ),
    takeUntil(this.destroy$)
  );

  constructor(
    private notes: NotesService,

    private context: ContextService,
    private dialog: DialogService,
    private snackBar: MatSnackBar
  ) {
    this.saveNoteResult$.pipe(
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngOnInit() {
    this._listResult$.pipe(
      take(1),
      switchMap(data =>
        !data ? this.noteFilters$.pipe(
          successData(),
          map(filters => filters[0])
        ) : of(null)
      ),
      takeUntil(this.destroy$)
    ).subscribe(filter => {
      if (filter) this.noteFilter$.next(filter);
    });
  }

  changePage(event: PageEvent) {
    this.paginationOffset$.next(event.pageIndex * event.pageSize);
    // this.paginationNumResults$.next(event.pageSize);
  }

  viewNote(note: Note) {
    if (note.isOwner === 1) {
      this.noteDialog('Edit Note', note);
    } else {
      this.noteDialog(note.noteTitle, note);
    }
  }

  noteDialog(title: string, note?: Note) {
    if (!note) {
      note = { ...this.context.latest, isOwner: 1 } as Note;
    }

    this.dialog.note({ dialog: { title }, item: { ...note } }).pipe(
      truthy(),
      takeUntil(this.destroy$)
    ).subscribe(newNote => {
      const item = { ...newNote, isActive: 0 as const };
      const updates = { isActive: 1 as const };

      this.saveNote(item, updates);
    });
  }

  saveNote(note: Note, updates: Partial<Note>) {
    this.saveNote$.next({ item: note, updates });
  }

  closeSidebar() {
    this.notes.closeSidebar();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
