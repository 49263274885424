import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, switchMap, startWith } from 'rxjs/operators';

import { successData, tapSuccess } from 'src/app/utils/rxjs-operators';

import { ApiService, DataTable } from './../api.service';
import { XrefService } from './xref.service';

@Injectable({
  providedIn: 'root'
})
export class NotesService implements OnDestroy {
  private destroy$ = new Subject<void>();

  sidebarShow$ = new Subject<void>();
  sidebarClose$ = new Subject<void>();

  filters = () =>
    this.xref
      .get<Filter[]>({
        path: 'note-filters'
      })
      .pipe(takeUntil(this.destroy$));

  public refreshNotes$ = new BehaviorSubject<any>(null);

  get = (filterId: number, profileRef?: string, pagination = {}) =>
    this.refreshNotes$.pipe(
      startWith(null),
      switchMap(() =>
        this.api.get<DataTable<Note>>({
          path: 'notes',
          params: { filterId, profileRef, ...pagination }
        })
      ),
      takeUntil(this.destroy$)
    );

  save = (note: Note) =>
    this.api
      .post<void>({
        path: 'notes',
        body: note
      })
      .pipe(
        tapSuccess(() => this.refreshNotes$.next(true)),
        takeUntil(this.destroy$)
      );

  showSidebar() {
    this.sidebarShow$.next();
  }

  closeSidebar() {
    this.sidebarClose$.next();
  }

  constructor(private api: ApiService, private xref: XrefService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getNotes = (profileRef: string, pagination = {}, filterId = 1000, noteId?: number) =>
    this.api
      .get<DataTable<NoteItem>>({
        path: 'notes',
        params: { profileRef, filterId, ...pagination, noteId }
      })
      .pipe(
        successData(),

        takeUntil(this.destroy$)
      );

  getNoteJobs = (noteId?: number) =>
    this.api
      .get<NoteJob[]>({
        path: 'notes/jobs',
        params: { noteId }
      })
      .pipe(successData(), takeUntil(this.destroy$));

  saveNote = (note: NoteBody) =>
    this.api
      .post<void>({
        path: 'notes',
        body: note
      })
      .pipe(
        tapSuccess(() => this.refreshNotes$.next(true)),
        successData(),
        takeUntil(this.destroy$)
      );
}

export interface Note {
  noteId: number;
  noteTypeId: number;
  noteType: string;
  noteTitle: string;
  note: string;
  recruiterId: number;
  userId: number;
  userDisplayName: string;
  profileId: number;
  profileRef: string;
  candidateDisplayName: string;
  jobId: number;
  jobTitle: string;
  jobs: { jobId: number; jobTitle: string }[];
  companyId: number;
  companyName: string;
  createdDate: string; //////////////////// Convert to epoch
  candidateThumbUrl: string;
  recruiterThumbUrl: string;
  taskDueDateEpoch: number; //////////////////// Remove epoch from label
  isActive: 0 | 1;
  isOwner: 0 | 1;
}

export interface Filter {
  filterId: number;
  filterDesc: string;
}

export interface NoteItem {
  noteId: number;
  noteTypeId: number;
  noteType: string;
  recruiterId: number;
  companyId: any;
  profileId: number;
  profileRef: string;
  candidateDisplayName: string;
  candidateResponseId: any;
  candidateResponse: any;
  candidateInterestId: any;
  candidateInterest: any;
  userId: number;
  userDisplayName: string;
  noteTitle: string;
  note: string;
  createdDate: string;
  candidateThumbUrl: any;
  recruiterThumbUrl: any;
  atsNoteForExport: any;
  isOwner: number;
  show: boolean;
  isConfidential: 0 | 1;
  shortNote: string;
  companyName: string;
}

export interface NoteBody {
  noteId?: number;
  companyId?: number;
  profileRef: string;
  noteTypeId: number;
  noteTitle?: string | null;
  note?: string | null;
  taskDueDateEpoch: string;
  jobs: number[];
  isActive: 0 | 1;
  isConfidential: 0 | 1;
}

export interface NoteJob {
  noteId: number;
  jobPostingId: number;
  jobTitle: string;
  jobId: number;
}
