import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { enUS } from 'date-fns/locale';
import { NgArrayPipesModule } from 'ngx-pipes-updated';

import { OverlayModule } from '@angular/cdk/overlay';

import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';

import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { DateTimePickerComponent } from './date-time-picker.component';

const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'PPP pp'
  },
  display: {
    dateInput: 'PPP p',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'PPP p',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};

@NgModule({
  declarations: [DateTimePickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgArrayPipesModule,

    OverlayModule,

    MatButtonModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule
  ],
  exports: [DateTimePickerComponent],
  providers: [
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT },
    { provide: MAT_DATE_LOCALE, useValue: enUS }
  ]
})
export class DateTimePickerModule { }