import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'shortDate'
})
export class ShortDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(dateStr: string | null): string {
    if (dateStr === 'Unknown Start Date' || dateStr === 'Present') {
      return dateStr;
    }

    if (!dateStr) {
      return 'No date provided';
    }

    const dateParts = dateStr.split(', ');
    if (dateParts.length !== 2 || isNaN(Date.parse(dateParts[1] + ', ' + dateParts[0] + ' 1'))) {
      return 'Invalid date';
    }

    const month = new Date(Date.parse(dateParts[0] + ' 1, 2000')).getMonth();
    const year = parseInt(dateParts[1], 10);
    const date = new Date(year, month, 1);

    const formattedDate = this.datePipe.transform(date, 'MMM yyyy');
    return formattedDate || 'Invalid date';
  }
}
