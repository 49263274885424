import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpEventType, HttpResponse, HttpRequest, HttpHeaders, HttpParams, HttpEvent, HttpParameterCodec } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil, map, filter, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class HttpService implements OnDestroy {
  private destroy$ = new Subject<void>();

  apiVersion = 2;

  constructor(
    private http: HttpClient
  ) { }

  private createHttpParams = (params: Record<string, any>) => {
    let ret = new HttpParams();

    Object.keys(params)
      .filter(key => params[key] != null)
      .forEach(key => {
        ret = ret.set(key, params[key]);
      });

    return ret;
  };

  private createHttpHeaders = (headers: Record<string, string>) => {
    let ret = new HttpHeaders();

    headers = { ...this.getAuthorization(), ...headers };

    headers['x-location-href'] = location.href;

    if (this.isInIframe) {
      headers['x-document-referer'] = document.referrer;
    }

    Object.keys(headers)
      .filter(key => headers[key] != null)
      .forEach(key => {
        ret = ret.set(key, headers[key]);
      });

    return ret;
  };

  private getAuthorization = () => {
    const token = localStorage.getItem('authorization');

    if (token) return { Authorization: `Bearer ${token}` };

    return;
  };

  private configRequest<T extends HttpRequestBase>(request: T): T {
    const configRequest = cloneDeep(request);

    configRequest.headers = configRequest.headers || {};
    configRequest.params = configRequest.params || {};

    [configRequest.headers, configRequest.params].forEach(a => {
      Object.keys(a).forEach(key => {
        if (a[key] == null) {
          delete a[key];
        }
      });
    });

    if (!configRequest.version) {
      configRequest.version = this.apiVersion;
    }

    if (!configRequest.path.includes('//')) {
      configRequest.path = `api/v${configRequest.version}/${configRequest.path}`;
    }

    return configRequest;
  }

  get<T = any>(request: HttpGetRequest): Observable<ApiResponse<T>> {
    const configRequest = this.configRequest(request);

    return of(null).pipe(
      map(() => new HttpRequest('GET', configRequest.path, {
        responseType: 'json',
        headers: this.createHttpHeaders({ 'Content-Type': 'application/json', ...configRequest.headers || {} }),
        params: new HttpParams({ fromObject: { ...configRequest.params || {} }, encoder: new CustomHttpParamEncoder() })
      })),
      switchMap(httpRequest => this.http.request<ApiResponse<T>>(httpRequest)),
      filter(response => response.type === HttpEventType.Response),
      map(response => (response as HttpResponse<ApiResponse<T>>).body as ApiResponse<T>),
      takeUntil(this.destroy$)
    );
  }

  post<T = any>(request: HttpPostRequest): Observable<ApiResponse<T>> {
    const configRequest = this.configRequest(request);

    return of(null).pipe(
      map(() => new HttpRequest('POST', configRequest.path, request.body, {
        responseType: 'json',
        headers: this.createHttpHeaders({ 'Content-Type': 'application/json', ...configRequest.headers || {} }),
        params: new HttpParams({ fromObject: { ...configRequest.params || {} }, encoder: new CustomHttpParamEncoder() })
      })),
      switchMap(httpRequest => this.http.request<ApiResponse<T>>(httpRequest)),
      filter(response => response.type === HttpEventType.Response),
      map(response => (response as HttpResponse<ApiResponse<T>>).body as ApiResponse<T>),
      takeUntil(this.destroy$)
    );
  }

  upload<T = any>(request: HttpUploadRequest): Observable<HttpEvent<ApiResponse<T>>> {
    const configRequest = this.configRequest(request);

    if (!request.version) {
      configRequest.version = 1;
    }

    return of(null).pipe(
      switchMap(() => this.http.post<ApiResponse<T>>(configRequest.path, request.body, {
        responseType: 'json',
        headers: this.createHttpHeaders({ ...configRequest.headers || {} }),
        params: new HttpParams({ fromObject: { ...configRequest.params || {} }, encoder: new CustomHttpParamEncoder() }),
        reportProgress: true, observe: 'events'
      })),
      takeUntil(this.destroy$)
    );
  }

  get isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string { return encodeURIComponent(key); }
  encodeValue(value: string): string { return encodeURIComponent(value); }
  decodeKey(key: string): string { return decodeURIComponent(key); }
  decodeValue(value: string): string { return decodeURIComponent(value); }
}

export type HttpGetRequest = HttpRequestBase

export interface HttpPostRequest extends HttpRequestBase {
  body?: any;
}

export interface HttpUploadRequest extends HttpRequestBase {
  body: FormData;
}

export interface HttpRequestBase {
  path: string;
  version?: number;
  headers?: Record<string, string>;
  params?: Record<string, any>;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  reportProgress?: boolean;
  cacheDuration?: number; // -1 = infinite
}

export interface ApiResponse<T> {
  data: T;
  status: ApiResponseStatus;
}

export interface ApiResponseStatus {
  code: number;
  status: string;
  message: 'INVALID_AUTH_TOKEN' | 'PAYLOAD_TOO_LARGE';
  socketEvent?: string;
}