import { Component, OnDestroy, Input, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, of, merge } from 'rxjs';
import { takeUntil, debounceTime, map, switchMap, take, filter, distinctUntilChanged, startWith } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEqual } from 'lodash-es';

import { mapSuccessData, truthy, successData, switchMapSuccess } from 'src/app/utils/rxjs-operators';

import { AuthService } from 'src/app/services/auth.service';
import { RestrictedListService } from 'src/app/services/api/restricted-list.service';
import { BaseCandidate, Candidate, CandidatesService } from 'src/app/services/api/candidates.service';
import { EmailsService } from 'src/app/services/api/emails.service';
import { EmployeesService } from 'src/app/services/api/employees.service';
import { GroupsService } from 'src/app/services/api/groups.service';
import { NotesService, Note } from 'src/app/services/api/notes.service';
import { TasksService, Task } from 'src/app/services/api/tasks.service';
import { WatchListService } from 'src/app/services/api/watch-list.service';

import { ContextService } from 'src/app/services/context.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-pr-candidate-menu',
  templateUrl: './pr-candidate-menu.component.html',
  styleUrls: ['./pr-candidate-menu.component.scss']
})
export class CandidateMenuComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() items: BaseCandidate[] = [];
  @Output() candidateChange = new EventEmitter<void>();

  recruiterSearchControl = new FormControl();
  recruiters$ = this.recruiterSearchControl.valueChanges.pipe(
    startWith(undefined),
    map(x => (x !== '' ? x : undefined)),
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(searchValue => this.employees.search(searchValue)),
    mapSuccessData(data => data.map(x => ({ value: x.userId, text: x.nameDisplay }))),
    takeUntil(this.destroy$)
  );

  groupSearchControl = new FormControl();
  groups$ = this.groupSearchControl.valueChanges.pipe(
    startWith(undefined),
    map(x => (x !== '' ? x : undefined)),
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(searchValue => this.groups.search(searchValue)),
    mapSuccessData(data => data.map(x => ({ value: x.groupId, text: x.groupName }))),
    takeUntil(this.destroy$)
  );

  saveCandidates$ = new Subject<{ items: Candidate[]; updates: Partial<Candidate> }>();
  saveCandidatesResult$ = this.saveCandidates$.pipe(
    map(({ items, updates }) => ({
      origItems: items.map(x => ({ ...x } as Candidate)),
      newItems: items.map(x => ({ ...x, ...updates } as Candidate))
    })),
    filter(({ origItems, newItems }) => !isEqual(origItems, newItems)),
    switchMap(({ newItems }) =>
      this.candidates.saveCandidateBulk(newItems).pipe(
        switchMap(result =>
          of(result).pipe(
            switchMapSuccess(() =>
              this.snackBar
                .open('Saved', 'Close', { duration: 5 * 1000 })
                .afterOpened()
                .pipe(
                  map(() => result),
                  startWith(result)
                )
            )
          )
        )
      )
    )
  );

  saveShortList$ = new Subject<{ items: Candidate[]; updates: Partial<Candidate> }>();
  saveShortListResult$ = this.saveShortList$.pipe(
    map(({ items, updates }) => ({
      origItems: items.map(x => ({ ...x } as Candidate)),
      newItems: items.map(x => ({ ...x, ...updates } as Candidate))
    })),
    filter(({ origItems, newItems }) => !isEqual(origItems, newItems)),
    switchMap(({ newItems }) =>
      this.candidates.shortList(newItems).pipe(
        switchMap(result =>
          of(result).pipe(
            switchMapSuccess(() =>
              this.snackBar
                .open('Saved', 'Close', { duration: 5 * 1000 })
                .afterOpened()
                .pipe(
                  map(() => result),
                  startWith(result)
                )
            )
          )
        )
      )
    )
  );

  saveWatchList$ = new Subject<{ items: Candidate[]; updates: Partial<Candidate> }>();
  saveWatchListResult$ = this.saveWatchList$.pipe(
    map(({ items, updates }) => ({
      origItems: items.map(x => ({ ...x } as Candidate)),
      newItems: items.map(x => ({ ...x, ...updates } as Candidate))
    })),
    filter(({ origItems, newItems }) => !isEqual(origItems, newItems)),
    switchMap(({ newItems }) =>
      this.watchList.save(newItems).pipe(
        switchMap(result =>
          of(result).pipe(
            switchMapSuccess(() =>
              this.snackBar
                .open('Saved', 'Close', { duration: 5 * 1000 })
                .afterOpened()
                .pipe(
                  map(() => result),
                  startWith(result)
                )
            )
          )
        )
      )
    )
  );

  saveRestrictedList$ = new Subject<{ items: Candidate[]; updates: Partial<Candidate> }>();
  saveRestrictedListResult$ = this.saveRestrictedList$.pipe(
    switchMap(({ items, updates }) => {
      return this.confirmTemplate
        ? this.dialog
          .showDialog(this.confirmTemplate, { dialog: { title: 'Add to Restricted List' }, item: { candidates: items } })
          .afterClosed()
          .pipe(
            filter(confirm => !!confirm),
            map(() => ({ items, updates }))
          )
        : of({ items, updates });
    }),
    map(({ items, updates }) => ({
      origItems: items.map(x => ({ ...x } as Candidate)),
      newItems: items.map(x => ({ ...x, ...updates } as Candidate))
    })),
    filter(({ origItems, newItems }) => !isEqual(origItems, newItems)),
    switchMap(({ newItems }) =>
      this.restrictedList.save(newItems).pipe(
        switchMap(result =>
          of(result).pipe(
            switchMapSuccess(() =>
              this.snackBar
                .open('Saved', 'Close', { duration: 5 * 1000 })
                .afterOpened()
                .pipe(
                  map(() => result),
                  startWith(result)
                )
            )
          )
        ),
        takeUntil(this.destroy$)
      )
    )
  );

  addNote$ = new Subject<BaseCandidate>();
  addNoteResult$ = this.addNote$.pipe(
    switchMap(item =>
      this.dialog.note({
        dialog: { title: 'Add Note' },
        item: { profileRef: item.profileRef, candidateDisplayName: item.displayName, isOwner: 1 } as Note
      })
    ),
    truthy(),
    switchMap(data =>
      this.notes.save(data).pipe(
        switchMap(result =>
          of(result).pipe(
            switchMapSuccess(() =>
              this.snackBar
                .open('Saved', 'Close', { duration: 5 * 1000 })
                .afterOpened()
                .pipe(
                  map(() => result),
                  startWith(result)
                )
            )
          )
        )
      )
    )
  );

  addTask$ = new Subject<BaseCandidate>();
  addTaskResult$ = this.addTask$.pipe(
    switchMap(item =>
      this.dialog.task({
        dialog: { title: 'Add Task' },
        item: { profileRef: item.profileRef, candidateDisplayName: item.displayName, isOwner: 1 } as Task
      })
    ),
    truthy(),
    switchMap(data =>
      this.tasks.save(data).pipe(
        switchMap(result =>
          of(result).pipe(
            switchMapSuccess(() =>
              this.snackBar
                .open('Saved', 'Close', { duration: 5 * 1000 })
                .afterOpened()
                .pipe(
                  map(() => result),
                  startWith(result)
                )
            )
          )
        )
      )
    )
  );

  @ViewChild('confirmTemplate', { static: false }) confirmTemplate?: TemplateRef<any>;

  constructor(
    public auth: AuthService,
    private restrictedList: RestrictedListService,
    private emails: EmailsService,
    private employees: EmployeesService,
    private groups: GroupsService,
    private candidates: CandidatesService,
    private notes: NotesService,
    private tasks: TasksService,
    private watchList: WatchListService,

    public context: ContextService,
    private dialog: DialogService,
    private snackBar: MatSnackBar
  ) {
    merge(this.saveCandidatesResult$, this.saveShortListResult$, this.saveWatchListResult$, this.saveRestrictedListResult$, this.addNoteResult$, this.addTaskResult$)
      .pipe(successData(), takeUntil(this.destroy$))
      .subscribe(() => {
        this.candidateChange.next();
      });
  }

  saveCandidates(candidates: BaseCandidate[], updates: Partial<Candidate>) {
    const pick = <T, K extends keyof T>(obj: T, keys: K[]) => Object.assign({}, ...keys.map(k => ({ [k]: obj[k] || '' }))) as Partial<T>;
    const items = candidates.map(x => ({ profileRef: x.profileRef, ...pick(x, Object.keys(updates) as (keyof BaseCandidate)[]) })) as Candidate[];

    this.saveCandidates$.next({ items, updates });
  }

  addShortList(candidates: BaseCandidate[], isActive: Candidate['isShortListed']) {
    const pick = <T, K extends keyof T>(obj: T, keys: K[]) => Object.assign({}, ...keys.map(k => ({ [k]: obj[k] || '' }))) as Partial<T>;

    const updates = { isActive } as Partial<Candidate>;
    const items = candidates.map(x => ({ profileRef: x.profileRef, jobId: x.jobId, ...pick(x, Object.keys(updates) as (keyof BaseCandidate)[]) })) as Candidate[];

    this.saveShortList$.next({ items, updates });
  }

  addWatchList(candidates: BaseCandidate[]) {
    const items = candidates.map(x => ({ profileRef: x.profileRef, isActive: '0' })) as Candidate[];
    const updates = { isActive: '1' } as Partial<Candidate>;

    this.saveWatchList$.next({ items, updates });
  }

  addRestrictedList(candidates: BaseCandidate[]) {
    const items = candidates.map(x => ({ profileRef: x.profileRef, isActive: '0' })) as Candidate[];
    const updates = { isActive: '1' } as Partial<Candidate>;

    this.saveRestrictedList$.next({ items, updates });
  }

  addNote(candidates: BaseCandidate[]) {
    this.addNote$.next(candidates[0]);
  }

  addTask(candidates: BaseCandidate[]) {
    this.addTask$.next(candidates[0]);
  }

  uploadResumes(candidates: BaseCandidate[]) {
    this.dialog
      .uploadResumes({
        dialog: { title: 'Upload Resume' },
        item: { profileRef: candidates[0].profileRef }
      })
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe();
  }

  // sendEmail(candidates: BaseCandidate[]) {
  //   this.emails.send(candidates).pipe(
  //     successData(),
  //     switchMap(() => this.snackBar.open('Email(s) have been sent', 'Close', { duration: 5 * 1000 }).afterOpened()),
  //     takeUntil(this.destroy$)
  //   ).subscribe();
  // }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
