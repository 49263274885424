import { Injectable } from '@angular/core';
import { Subject, take } from 'rxjs';

import { Role, Seat } from './auth.types';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  context: Record<string, any>[] = [];

  headerIconSet: HeaderIconSet = '';
  navItems: NavItem[] = [];

  constructor() { }

  push(context: Record<string, any>, pop$: Subject<any>) {
    this.context.push(context);

    pop$.pipe(
      take(1)
    ).subscribe(() => {
      this.context.splice(this.context.indexOf(context), 1);
    });
  }

  popAll() {
    this.context = [];
  }

  clear() {
    this.context = [];
    this.headerIconSet = '';
    this.navItems = [];
  }

  get latest(): Record<string, any> {
    return this.context.reduce((a, x) => ({ ...a, ...x }), {});
  }
}

interface NavItem {
  icon: string;
  name: string;
  url: string;
  newWindow: boolean;

  isAdmin?: boolean;
  role?: Role;
  seat?: Seat;
  hidden?: boolean;
  isSvgIcon?: boolean;
  isProRank?: boolean;
}

type HeaderIconSet = '' | 'home' | 'admin' | 'profile';