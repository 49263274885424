import { Component, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject, of } from 'rxjs';
import { takeUntil, map, switchMap, take, share } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { createSuccessResult } from 'src/app/utils/api-helpers';
import { mapSuccessData, mapSuccessDataTable, successData } from 'src/app/utils/rxjs-operators';

import { CandidatesService } from 'src/app/services/api/candidates.service';
import { EmployeesService } from 'src/app/services/api/employees.service';
import { Note } from 'src/app/services/api/notes.service';
import { JobPostingsService } from 'src/app/services/api/job-postings.service';
import { XrefService, Company, NoteType } from 'src/app/services/api/xref.service';

import { DataTable } from 'src/app/services/api.service';
import { ContextService } from 'src/app/services/context.service';
import { DialogData } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-pr-note-dialog',
  templateUrl: './pr-note-dialog.component.html',
  styleUrls: ['./pr-note-dialog.component.scss']
})
export class NoteDialogComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  form = this.formBuilder.group({
    title: ['', [Validators.required]]
  });

  showOptions: { [key in OptionsKey]?: boolean } = {};
  textKey: { [key in OptionsKey]: OptionsText } = {
    profileRef: 'candidateDisplayName',
    companyId: 'companyName',
    jobId: 'jobTitle',
    taskDueDateEpoch: 'jobTitle' ///////////////////////
  };

  noteTypes$ = this.xref.get<NoteType[]>({
    path: 'note-types'
  }).pipe(
    mapSuccessData(data => data.map(x => ({ value: x.noteTypeId, text: x.noteType }))),
    share(),
    takeUntil(this.destroy$)
  );

  searchRecruiter = (searchValue?: string) => this.employees.search(searchValue).pipe(
    mapSuccessData(data => data.map(x => ({ value: x.userId, text: x.nameDisplay }))),
    takeUntil(this.destroy$)
  );

  searchCandidate = (searchValue?: string) => of(searchValue).pipe(
    map(searchValue => searchValue && searchValue.length >= 3 ? searchValue : undefined),
    switchMap(searchValue => {
      if (searchValue)
        return this.candidates.get({ searchValue }).pipe(
          mapSuccessDataTable(item => ({ value: item.profileRef, text: item.displayName })),
          mapSuccessData(data => data.dataTable)
        );

      return of(createSuccessResult([]));
    }),
    takeUntil(this.destroy$)
  );

  searchCompany = (searchValue?: string) => this.xref.get<DataTable<Company>>({
    path: 'companies',
    params: (searchValue || '')?.length === 0 ? { context: 'jobs' } : { searchValue }
  }).pipe(
    mapSuccessDataTable(item => ({ value: +item.companyId, text: item.companyName, typeaheadText: item.companyName })),
    mapSuccessData(data => data.dataTable),
    takeUntil(this.destroy$)
  );

  searchJob = (searchValue?: string) => this.jobPostings.getJobs({ searchValue, viewId: 1000 }).pipe(
    mapSuccessDataTable(item => ({ value: +item.jobId, text: item.jobTitle })),
    mapSuccessData(data => data.dataTable),
    takeUntil(this.destroy$)
  );

  constructor(
    private candidates: CandidatesService,
    private employees: EmployeesService,
    private jobPostings: JobPostingsService,
    private xref: XrefService,

    private context: ContextService,

    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData<Note>
  ) {
    _data.item = { ...this.context.latest, ..._data.item };

    _data.item.jobs = _data.item.jobs || [];

    if (_data.item.jobs.length > 0 && typeof _data.item.jobs[0] !== 'string') {
      _data.item.jobId = _data.item.jobs[0].jobId;
      _data.item.jobTitle = _data.item.jobs[0].jobTitle;
    }

    Object.keys(this.textKey).forEach(key => {
      this.showOptions[key as OptionsKey] = !!this._data.item[key as OptionsKey];
    });

    this.form.controls.title.setValue(this._data.item.noteTitle);

    if (!this._data.item.noteTypeId) {
      this.noteTypes$.pipe(
        successData(),
        take(1),
        takeUntil(this.destroy$)
      ).subscribe(data => {
        this._data.item.noteTypeId = data[0].value;
      });
    }
  }

  toggleKey(key: OptionsKey) {
    this.showOptions[key] = !this.showOptions[key];

    if (!this.showOptions[key]) {
      (this._data.item as any)[key] = undefined;
      (this._data.item as any)[this.textKey[key]] = undefined;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(result: any): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      if (this._data.item.jobId) {
        this._data.item.jobs = [{ jobId: this._data.item.jobId, jobTitle: this._data.item.jobTitle }];
      } else {
        this._data.item.jobs = [];
      }

      this.dialogRef.close(result);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

type OptionsKey = 'profileRef' | 'companyId' | 'jobId' | 'taskDueDateEpoch';
type OptionsText = 'candidateDisplayName' | 'companyName' | 'jobTitle';