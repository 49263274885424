import { Pipe, PipeTransform } from '@angular/core';

import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';


@Pipe({ name: 'formatContactInfo' })
export class FormatContactInfoPipe implements PipeTransform {
  transform(text: string, type: string) {
    const phoneUtil = PhoneNumberUtil.getInstance();

    if (type.toLowerCase() === 'home' || type.toLowerCase() === 'mobile' || type.toLowerCase() === 'telephone' || type.toLowerCase() === 'fax') {
      const number = tryParseNumber(phoneUtil, text);

      if (number) {
        const format = number.getCountryCode() === 1 ? PhoneNumberFormat.NATIONAL : PhoneNumberFormat.INTERNATIONAL;
        return phoneUtil.format(number, format);
      }
    }

    return text;
  }
}

function tryParseNumber(phoneUtil: PhoneNumberUtil, text: string) {
  try {
    return phoneUtil.parse(text);
  } catch (ex) { null; }

  try {
    return phoneUtil.parse(text, 'US');
  } catch (ex) { null; }

  return null;
}