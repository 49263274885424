/* Progress service determines when the green loading bar below the top nav shows

  Progress service is accessed in an api call's .pipe() with:
    tapLoading(() => this.progress.start()),
    tapFinished(() => this.progress.stop()),
*/

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private i = 0;
  private progressStartTimeout?: NodeJS.Timeout;

  mode = new BehaviorSubject<'none' | ProgressBarMode>('none');
  value = new BehaviorSubject<number | undefined>(undefined);

  constructor() { }

  start(mode?: 'none' | ProgressBarMode, value?: number) {
    this.i += 1;

    if (!this.progressStartTimeout) {
      this.progressStartTimeout = setTimeout(() => {
        this.mode.next(mode || 'indeterminate');
        this.value.next(value || undefined);
      }, 125);
    }
  }

  set(value: number) {
    this.value.next(value || undefined);
  }

  stop() {

    this.i = Math.max(this.i - 1, 0);

    if (this.i === 0) {

      this.mode.next('none');
      this.value.next(undefined);

      clearTimeout(this.progressStartTimeout);
      this.progressStartTimeout = undefined;
    }
  }

  reset() {
    this.i = 0;
    this.mode.next('none');
    this.value.next(undefined);
  }
}
