import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IntakeFormComponent } from './intake-form.component';
import { IntakeFormRoutingModule } from './intake-form.routing.module';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';

import { NgxEditorModule } from 'ngx-editor';

import { ChipTypeaheadModule } from 'src/app/components/pr-chip-typeahead/pr-chip-typeahead.module';
import { InputSelectControlModule } from 'src/app/components/input-select-control/input-select-control.module';

import { ApiResultsMergePipeModule } from 'src/app/pipes/api-results-merge.module';
import { FormatCamelCaseToTextModule } from 'src/app/pipes/format-camel-case-to-text.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,

    IntakeFormRoutingModule,

    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,

    NgxEditorModule,

    ChipTypeaheadModule,
    InputSelectControlModule,

    ApiResultsMergePipeModule,
    FormatCamelCaseToTextModule
  ],
  declarations: [IntakeFormComponent],
  exports: [IntakeFormComponent]

})
export class IntakeFormModule { }
