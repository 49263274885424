import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
//import { BrowserTracing, captureException, routingInstrumentation, init as sentryInit } from '@sentry/angular-ivy';
//import { JwtHelperService } from 'angular-jwt-updated';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (window.location.hostname !== 'localhost') {
  //const jwt = new JwtHelperService();

  // sentryInit({
  //   dsn: environment.sentryDsn,
  //   environment: environment.production ? 'production' : 'development',
  //   beforeSend(event) {
  //     const token = localStorage.getItem('authorization');
  //     if (token) event.tags = { ...event.tags, ...jwt.decodeToken(token) };

  //     return event;
  //   },
  //   ignoreErrors: [
  //     'Non-Error exception captured',
  //     /^ResizeObserver/,
  //     'ChunkLoadError',
  //     'Internal API Error',
  //     'INVALID_AUTH_CREDENTIALS'
  //   ],
  //   integrations: [
  //     new BrowserTracing({
  //       tracingOrigins: ['https://dev.prorank.ai/api', 'https://client.prorank.ai/api'],
  //       routingInstrumentation
  //     })
  //   ],
  //   tracesSampleRate: 1.0
  // });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(error => {
    console.error(error);

    if (error instanceof Error) {
      //captureException(error);
    }
  });
