<ng-container #candidate *ngIf="{ candidate: candidate$ | async } as result">
  <div mat-dialog-title>
    <div class="title-col">
      <app-user-avatar [src]="result.candidate?.imgUrl" size="60px" *ngIf="result.candidate?.imgUrl"> </app-user-avatar>
      <mat-icon *ngIf="!result.candidate?.imgUrl" class="mat-color-gray-600 md-icon-60">account_circle</mat-icon>
    </div>

    <div class="title-col">
      <div class="w-100">
        <ng-container *ngIf="result.candidate">
          <div>
            <div class="d-flex align-items-center mb-2 mt-1 body-xl">
              {{ result.candidate.displayName | titlecase }}
              <button mat-icon-button *ngIf="result.candidate.isDataMaskingEnabled" (click)="unmask(result.candidate.profileRef)">
                <mat-icon class="md-icon-24 ms-2" color="primary" matTooltip="Toggle DEI masking">visibility</mat-icon>
              </button>
              <div class="flag flag__blue ms-2" *ngIf="result.candidate.isAts">ATS</div>
              <div class="flag flag__red ms-2" *ngIf="result.candidate.isRestricted === CandidateRestrictionStatus.RESTRICTED">Restricted</div>
              <div class="flag flag__green ms-2" *ngIf="result.candidate.isFollowed === CandidateFollowStatus.FOLLOWED">Followed</div>
            </div>

            <span class="d-block hint body-md lh-1 fw-normal mb-1">{{ result.candidate.jobTitle ? (result.candidate.jobTitle | titlecase) : ' ' }}</span>
            <span class="d-block hint body-md lh-1 fw-normal">{{ result.candidate.companyName || '' }}</span>
            <div class="d-flex align-items-center header mt-2">
              <mat-icon class="md-icon-14">location_on</mat-icon>
              <mat-spinner class="ms-3" color="accent" *ngIf="loading" [diameter]="16"></mat-spinner>
              <ng-container *ngIf="!loading">
                <span class="d-block hint body-sm ms-2 fw-normal"> {{ result.candidate.cityState || 'No location' }}</span>
              </ng-container>
            </div>

            <div class="d-flex align-items-center header mt-2">
              <mat-icon class="md-icon-14" color="primary">mail_outline</mat-icon>
              <mat-spinner class="ms-3" color="accent" *ngIf="loading" [diameter]="16"></mat-spinner>
              <ng-container *ngIf="!loading">
                <span class="d-block hint body-sm ms-2 fw-normal" *ngIf="result.candidate?.emails && result.candidate.emails.length > 0"> {{ result.candidate.emails[0].email }}</span>
                <span *ngIf="result.candidate?.emails?.length === 0" class="d-block hint body-sm ms-2 fw-normal">No email available </span>
                <a class="d-block hint body-sm ms-2 c-pointer fw-normal" [matTooltip]="result.candidate.emailsString" *ngIf="result.candidate && result.candidate.emails.length - 1 > 0">
                  + {{ result.candidate.emails.length - 1 }} more
                </a>
              </ng-container>

              <mat-icon class="md-icon-14 c-pointer ms-2" (click)="$event.stopPropagation(); editContactInfo(result.candidate.phones, result.candidate.emails)">edit</mat-icon>
            </div>

            <div class="d-flex align-items-center header mt-2">
              <mat-icon class="md-icon-14" color="primary">phone</mat-icon>
              <mat-spinner class="ms-3" color="accent" *ngIf="loading" [diameter]="16"></mat-spinner>
              <ng-container *ngIf="!loading">
                <span class="d-block hint body-sm ms-2 fw-normal" *ngIf="result.candidate?.phones && result.candidate.phones.length > 0"> {{ result.candidate.phones[0].phoneNumber }}</span>
                <span *ngIf="result.candidate && result.candidate.phones?.length === 0" class="d-block hint body-sm ms-2 fw-normal">No phone available </span>
                <a class="d-block hint body-sm ms-2 c-pointer fw-normal" [matTooltip]="result.candidate.phonesString" *ngIf="result.candidate && result.candidate.phones.length - 1 > 0">
                  + {{ result.candidate.phones.length - 1 }} more
                </a>
              </ng-container>

              <mat-icon class="md-icon-14 c-pointer ms-2" (click)="$event.stopPropagation(); editContactInfo(result.candidate.phones, result.candidate.emails)">edit</mat-icon>
            </div>

            <mat-divider class="mt-2"></mat-divider>

            <div class="d-flex align-items-center">
              <a
                class="d-block mat-color-gray-600 c-pointer"
                [ngClass]="{ 'icon-url-disabled': !result.candidate.indeedUrl }"
                target="_blank"
                (click)="$event.stopPropagation()"
                [href]="result.candidate.indeedUrl"
                [matTooltip]="result.candidate.indeedUrl">
                <mat-icon fontSet="fab" svgIcon="indeed" class="md-icon-18 mb-1" color="primary"></mat-icon>
              </a>
              <a
                class="d-block mat-color-gray-600 c-pointer ms-4"
                [ngClass]="{ 'icon-url-disabled': !result.candidate.linkedInUrl }"
                target="_blank"
                (click)="$event.stopPropagation()"
                [href]="result.candidate.linkedInUrl"
                target="_blank"
                [matTooltip]="result.candidate.linkedInUrl">
                <mat-icon fontSet="fab" fontIcon="fa-linkedin" class="md-icon-18" color="primary"></mat-icon>
              </a>
              <a class="d-block mat-color-gray-600 c-pointer ms-4" matTooltip="Upload Resume" target="_blank" (click)="uploadResumes(result.candidate.profileRef)" (click)="$event.stopPropagation()">
                <mat-icon class="md-icon-18" color="primary">cloud_upload</mat-icon>
              </a>

              <a class="d-block mat-color-gray-600 ms-4 c-pointer" matTooltip="Download Resume" target="_blank" (click)="downloadResume()" [ngClass]="{ 'icon-url-disabled': versions.length === 0 }">
                <mat-icon class="md-icon-18" color="primary">download</mat-icon>
              </a>

              <a
                class="d-block mat-color-gray-600 ms-4 c-pointer"
                [ngClass]="{ 'icon-url-disabled': result.candidate.haveResumeFlag !== 1 && versions.length === 0 }"
                target="_blank"
                matTooltip="Preview Resume"
                (click)="$event.stopPropagation(); previewResume()">
                <mat-icon class="md-icon-18" color="primary">preview</mat-icon>
              </a>

              <a
                class="d-block mat-color-gray-600 ms-4 c-pointer"
                [ngClass]="{ 'icon-url-disabled': result.candidate.canRequestContactInfoFlag !== 1 }"
                target="_blank"
                matTooltip="Request Contact Info"
                (click)="$event.stopPropagation(); requestCandidateHeader$.next(result.candidate)">
                <mat-icon class="md-icon-18" color="primary">contact_mail</mat-icon>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="title-col">
      <div class="d-flex align-items-center justify-content-end mb-3">
        <ng-container *ngIf="data.total > 0">
          <button mat-button color="primary" (click)="previous()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <span class="body-lg"> {{ data.offset + currentIndex + 1 }} of {{ data.total }}</span>
          <button [disabled]="currentIndex + 1 === data.total" mat-button color="primary" (click)="next()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </ng-container>

        <button mat-dialog-close class="modal-close-icon-btn">
          <mat-icon class="md-icon-14">close</mat-icon>
        </button>
      </div>

      <div class="d-flex flex-column w-100">
        <div class="d-flex justify-content-end w-100">
          <ng-container *ngIf="!data.isOmniSearch">
            <button mat-stroked-button color="primary" (click)="prioritize()"><mat-icon>priority_high</mat-icon> Prioritize</button>

            <button mat-stroked-button color="primary" class="ms-4 mb-4" (click)="stage()"><mat-icon>sync_alt</mat-icon> Change Stage</button>

            <button mat-stroked-button color="primary" class="ms-4 mb-4" (click)="sendEmail(0)"><mat-icon>email</mat-icon> Send Email</button>

            <button mat-stroked-button color="primary" class="ms-4 mb-4" *ngIf="result.candidate?.showHideReject === 1 || result.candidate?.showHideReject" (click)="sendEmail(21000)">
              <mat-icon>block</mat-icon> Reject
            </button>
            <button mat-stroked-button color="primary" class="ms-4 mb-4" *ngIf="result.candidate?.showHideReject === 0 || !result.candidate?.showHideReject" (click)="hide()">
              <mat-icon>visibility_off</mat-icon> Hide
            </button>
          </ng-container>
        </div>
        <div class="d-flex justify-content-end w-100">
          <button mat-stroked-button class="green-button mat-color-green-800 ms-4 mb-4" [disabled]="isCandidateUpdating" (click)="handleFollow()" *ngIf="result.candidate?.isFollowed; else follow">
            <mat-icon>person_add_disabled</mat-icon> Un-Follow
          </button>

          <ng-template #follow>
            <button mat-stroked-button class="green-button mat-color-green-800 ms-4 mb-4" [disabled]="isCandidateUpdating" (click)="handleFollow()">
              <mat-icon style="font-weight: 600; transform: scaleX(-1)">person_add</mat-icon> Follow
            </button>
          </ng-template>

          <button mat-stroked-button color="warn" [disabled]="isCandidateUpdating" (click)="handleRestriction()" class="warn-button ms-4 mb-4" *ngIf="result.candidate?.isRestricted; else restrict">
            <mat-icon>do_not_disturb_on</mat-icon> Un-Restrict
          </button>

          <ng-template #restrict>
            <button mat-stroked-button color="warn" [disabled]="isCandidateUpdating" (click)="handleRestriction()" class="warn-button ms-4 mb-4">
              <mat-icon style="font-weight: 600; transform: scaleX(-1)">block</mat-icon> Restrict
            </button>
          </ng-template>

          <button mat-stroked-button color="primary" class="ms-4 mb-4 btn-with-caret" [matMenuTriggerFor]="menu"><mat-icon>add_circle</mat-icon> Add to</button>
          <mat-menu #menu="matMenu">
            <mat-radio-group [(ngModel)]="addToSelection" (change)="addToSelection$.next(addToSelection)">
              <div class="d-flex flex-column">
                <mat-radio-button class="example-radio-button" *ngIf="!data.isOmniSearch" [value]="0"> Campaign </mat-radio-button>

                <mat-radio-button class="example-radio-button" [value]="1"> Job </mat-radio-button>
              </div>
            </mat-radio-group>
          </mat-menu>

          <app-input-select
            *ngIf="addToSelection$.value === 1"
            class="d-inline-flex ms-4"
            label="Job"
            iconSuffix="search"
            [api]="searchLocation"
            fieldClass="customHeight mat-color-gray-600"
            (valueChange)="addToJob($event)">
          </app-input-select>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-1" mat-dialog-content>
    <div>
      <div class="row">
        <div class="col-9">
          <mat-tab-group class="split-tabs-content h-100" [selectedIndex]="TabSelected$ | async" (selectedIndexChange)="TabSelected$.next($event)">
            <mat-tab label="OVERVIEW">
              <div *ngIf="TabSelected$.value === 0">
                <div class="d-flex flex-column">
                  <ng-container
                    *ngIf="{
                    overview: overview$ | async, 
                  } as results">
                    <div *ngIf="results.overview && results.overview.experience.length > 0">
                      <mat-expansion-panel class="bg-white" expanded="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title> Experience </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div *ngFor="let item of results.overview?.experience; let i = index">
                          <div class="d-flex flex-column mt-2">
                            <div class="d-flex flex-row mb-2 pb-1">
                              <div class="w-50 d-flex align-items-center">
                                <span class="d-block body-md fw-medium"> {{ item.companyName ? (item.companyName | titlecase) : ' ' }} </span>
                              </div>
                              <div class="w-50 d-flex flex-row justify-content-end">
                                <span class="d-block body-md fw-medium">{{ item.yearsExperience | yearsToFormatted }}</span>
                              </div>
                            </div>

                            <div *ngFor="let experience of item.experience; let i = index" [ngClass]="i > 0 ? 'mt-1' : ''">
                              <div class="d-flex mb-1">
                                <div class="w-50">
                                  <span class="d-flex align-items-center body-sm text-capitalize"> <div class="mat-background-accent me-2 position-relative" [ngClass]="i > 0 ? 'timeline' : ''" style="width: 7px; height: 7px; border-radius: 50%;"></div> {{ experience.title || 'Not provided' }} </span>
                                </div>
                                <div class="w-50 d-flex flex-row justify-content-end">
                                  <span class="d-block body-sm"> {{ experience.fromDate | shortDate }} {{ experience.toDate ? ('- ' + (experience.toDate | shortDate)) : ' ' }}</span>
                                </div>
                              </div>
                              <div class="pb-2 w-100 d-flex justify-content-end mat-color-gray-600" style="font-size: 10px">{{ experience.yearsExperience | yearsToFormatted }}</div>
                            </div>
                          </div>
                          <mat-divider *ngIf="results.overview && results.overview.experience.length - 1 > i" class="mat-background-gray-300" style="margin-left: -10px; margin-right: -10px;"></mat-divider>
                        </div>
                      </mat-expansion-panel>
                    </div>

                    <div *ngIf="results.overview && results.overview.education.length > 0">
                      <mat-expansion-panel class="mt-4 bg-white" expanded="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title> Education </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div *ngFor="let item of results.overview?.education; let i = index">
                          <div class="d-flex flex-column" style="padding-top: 10px; padding-bottom: 10px;">
                            <div class="d-flex flex-row">
                              <div class="w-50">
                                <span class="d-block body-md fw-medium"> {{ item.schoolName }} </span>
                              </div>
                              <div class="w-50 d-flex flex-row justify-content-end">
                                <span class="d-block body-sm"> {{ item.fromDate | shortDate }} {{ item.toDate ? ('- ' + (item.toDate | shortDate)) : ' ' }}</span>
                              </div>
                            </div>

                            <div class="d-flex flex-row mt-1" *ngIf="item.major || item.degreeName">
                              <div>
                                <span class="d-flex align-items-center body-sm"> <div class="mat-background-accent me-2" style="width: 7px; height: 7px; border-radius: 50%;"></div> {{ item.major }}{{ item.major && item.degreeName ? '-' : ' ' }} {{ item.degreeName }} </span>
                                <span class="invisible body-sm" *ngIf="!item.major && !item.degreeName"> no-data </span>
                              </div>
                            </div>
                          </div>
                          <mat-divider *ngIf="results.overview && results.overview.education.length - 1 > i" class="mat-background-gray-300"></mat-divider>
                        </div>
                      </mat-expansion-panel>
                    </div>

                    <div *ngIf="results.overview && results.overview.certifications.length > 0" class="mt-3">
                      <mat-expansion-panel class="bg-white" expanded="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title> Certifications </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div *ngFor="let item of results.overview?.certifications; let i = index">
                          <div class="d-flex justify-content-between align-items-center" style="padding-top: 10px; padding-bottom: 10px;">
                            <span class="d-block body-md fw-medium"> {{ item.skillName }} </span>
                            <span class="d-block body-sm"> {{ item.yearsExperience | yearsToFormatted }} </span>                          
                          </div>
                          <mat-divider *ngIf="results.overview && results.overview.certifications.length - 1 > i" class="mat-background-gray-300"></mat-divider>
                        </div>
                      </mat-expansion-panel>
                    </div>

                    <div *ngIf="results.overview && results.overview.skills.length > 0">
                      <mat-expansion-panel class="mt-4 bg-white" expanded="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title> Skills </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div *ngFor="let item of results.overview?.skills; let i = index">
                          <div class="d-flex justify-content-between align-items-center" style="padding-top: 10px; padding-bottom: 10px;">
                            <span class="d-block body-md fw-medium"> {{ item.skillName }} </span>
                            <span class="d-block body-sm"> {{ item.yearsExperience | yearsToFormatted }} </span>                          
                          </div>
                          <mat-divider *ngIf="results.overview && results.overview.skills.length - 1 > i" class="mat-background-gray-300"></mat-divider>
                        </div>
                      </mat-expansion-panel>
                    </div>
                  </ng-container>
                </div>
              </div>
            </mat-tab>
            <ng-container *ngIf="!data.isOmniSearch">
              <mat-tab label="CASE INSIGHTS">
                <div *ngIf="TabSelected$.value === 1">
                  <ng-container
                    *ngIf="{
                    analysisDetail: analysisDetail$ | async,
                    
                  } as results">
                    <div class="d-flex justify-content-between">
                      <div class="flex-column flex-shrink-1 insight-width">
                        <div class="d-flex flex-row mat-background-primary-500">
                          <span class="d-block insightLable fw-medium"> Career Experience (C) </span>
                        </div>
                      </div>
                      <div class="flex-column flex-shrink-1 insight-width">
                        <div class="d-flex flex-row mat-background-primary-500">
                          <span class="d-block insightLable fw-medium"> Availability (A) </span>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between text-black">
                      <div class="flex-column bg-white insight-width insight-padding">
                        <div *ngIf="results.analysisDetail?.careerScore?.hasInfo">
                          <div class="d-flex flex-row" style="width: 91%">
                            <mat-progress-bar value="{{ results.analysisDetail?.careerScore?.score }}" mode="determinate"></mat-progress-bar>
                          </div>
                          <div class="progresssBarLable">{{ results.analysisDetail?.careerScore?.score }}%</div>

                          <ng-container *ngFor="let item of results.analysisDetail?.careerScore?.insights">
                            <div class="d-flex flex-row text-black mb-1">
                              <div class="w-50">
                                <span class="d-block body-sm fw-medium"> {{ item.title }}</span>
                              </div>
                              <div class="w-50">
                                <span class="d-block body-sm text-end"> {{ item.value }}</span>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="flex-column bg-white text-black insight-padding insight-width">
                        <div *ngIf="results.analysisDetail?.availabilityScore?.hasInfo">
                          <div class="d-flex flex-row" style="width: 91%">
                            <mat-progress-bar value="{{ results.analysisDetail?.availabilityScore?.score }}" mode="determinate"></mat-progress-bar>
                          </div>
                          <div class="progresssBarLable">{{ results.analysisDetail?.availabilityScore?.score }}%</div>

                          <ng-container *ngFor="let item of results.analysisDetail?.availabilityScore?.insights">
                            <div class="d-flex flex-row mb-1">
                              <div class="w-50">
                                <span class="d-block body-sm fw-medium"> {{ item.title }}</span>
                              </div>
                              <div class="w-50">
                                <span class="d-block body-sm text-end"> {{ item.value }}</span>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between mt-4">
                      <div class="flex-column flex-shrink-1 insight-width">
                        <div class="d-flex flex-row mat-background-primary-500">
                          <span class="d-block insightLable fw-medium"> Skill Experience (S) </span>
                        </div>
                      </div>
                      <div class="flex-column flex-shrink-1 insight-width">
                        <div class="d-flex flex-row mat-background-primary-500">
                          <span class="d-block insightLable fw-medium"> Education Background (E) </span>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-between">
                      <div class="flex-column bg-white text-black insight-padding insight-width">
                        <div *ngIf="results.analysisDetail?.skillScore?.hasInfo">
                          <div class="d-flex flex-row" style="width: 91%">
                            <mat-progress-bar value="{{ results.analysisDetail?.skillScore?.score }}" mode="determinate"></mat-progress-bar>
                          </div>
                          <div class="progresssBarLable">{{ results.analysisDetail?.skillScore?.score }}%</div>

                          <ng-container *ngFor="let item of results.analysisDetail?.skillScore?.insights">
                            <div class="d-flex flex-row mb-1">
                              <div class="w-50">
                                <span class="d-block body-sm fw-medium"> {{ item.title }}</span>
                              </div>
                              <div class="w-50">
                                <span class="d-block body-sm text-end"> {{ item.value }}</span>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="flex-column bg-white text-black insight-padding insight-width">
                        <div *ngIf="results.analysisDetail?.educationScore?.hasInfo">
                          <div class="d-flex flex-row" style="width: 91%">
                            <mat-progress-bar value="{{ results.analysisDetail?.educationScore?.score }}" mode="determinate"></mat-progress-bar>
                          </div>
                          <div class="progresssBarLable">{{ results.analysisDetail?.educationScore?.score }}%</div>

                          <div *ngIf="results.analysisDetail?.educationScore?.hasInfo">
                            <ng-container *ngFor="let item of results.analysisDetail?.educationScore?.insights">
                              <div class="d-flex flex-row mb-1">
                                <div class="w-50">
                                  <span class="d-block body-sm fw-medium"> {{ item.title }}</span>
                                </div>
                                <div class="w-50">
                                  <span class="d-block body-sm text-end"> {{ item.value }}</span>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </mat-tab>
            </ng-container>
            <mat-tab label="JOBS">
              <div *ngIf="(!data.isOmniSearch && TabSelected$.value === 2) || (data.isOmniSearch && TabSelected$.value === 1)">
                <ng-container
                  *ngIf="{
                    jobs: jobs$ | async
                  } as results">
                  <div class="d-flex flex-column mt-1">
                    <div>
                      <mat-expansion-panel class="bg-white text-black mb-3" expanded="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title> Current Active Jobs </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="results.jobs && results.jobs.activeProjects">
                          <div *ngFor="let item of results.jobs?.activeProjects; let i = index">
                            <div class="d-flex flex-column mt-2 p-2">
                              <div class="d-flex flex-row">
                                <div class="w-50">
                                  <span class="d-block body-md fw-medium">
                                    {{ item.jobTitle ? (item.jobTitle | titlecase) : '  ' }}
                                    | {{ item.companyName }}
                                  </span>
                                </div>
                                <div class="w-50 d-flex flex-row justify-content-end body-sm">
                                  <span class="d-block fw-medium"> Last Contacted:&nbsp;</span>
                                  <span>{{ item.lastContactedDate }}</span>
                                </div>
                              </div>

                              <div class="d-flex flex-row mt-1">
                                <div class="w-50">
                                  <span class="d-block body-sm">
                                    {{ item.priorityDesc ? item.priorityDesc + ' | ' : '' }}
                                    {{ item.stageDesc ? item.stageDesc + ' | ' : '' }}
                                    {{ item.assignedRecruiter }}
                                  </span>
                                </div>
                                <div class="d-flex justify-content-end w-50 body-sm">
                                  <span class="d-block fw-medium"> Next Outreach Date:&nbsp;</span>
                                  <span>{{ item.nextOutreachDate }}</span>
                                </div>
                              </div>
                            </div>
                            <mat-divider *ngIf="results.jobs && results.jobs.activeProjects.length - 1 > i"></mat-divider>
                          </div>

                          <div *ngIf="results.jobs.activeProjects.length === 0">
                            <div class="d-flex flex-column mt-2 p-2">
                              <div class="d-flex flex-row">
                                <div class="w-100"><span class="d-block"> No current active jobs </span></div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </mat-expansion-panel>
                    </div>
                    <div>
                      <mat-expansion-panel class="bg-white text-black mb-3" expanded="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title> Other Search Matches </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="results.jobs && results.jobs.otherProjectsmatched">
                          <div *ngFor="let item of results.jobs?.otherProjectsmatched; let i = index">
                            <div class="d-flex flex-column mt-2 p-2">
                              <div class="d-flex flex-row">
                                <div class="w-50">
                                  <span class="d-block body-md fw-medium">
                                    {{ item.jobTitle ? (item.jobTitle | titlecase) : ' ' }}
                                    | {{ item.companyName }}
                                  </span>
                                </div>
                                <div class="w-50 d-flex flex-row justify-content-end body-sm">
                                  <span class="d-block fw-medium"> Last Contacted:&nbsp;</span>
                                  <span>{{ item.lastContactedDate }}</span>
                                </div>
                              </div>

                              <div class="d-flex flex-row mt-1">
                                <span class="d-block body-sm">
                                  {{ item.priorityDesc ? item.priorityDesc + ' | ' : '' }}
                                  {{ item.stageDesc ? item.stageDesc + ' | ' : '' }}

                                  {{ item.assignedRecruiter }}
                                </span>
                              </div>
                            </div>
                            <mat-divider *ngIf="results.jobs && results.jobs.otherProjectsmatched.length - 1 > i" class="mat-background-gray-300"></mat-divider>
                          </div>

                          <div *ngIf="results.jobs.otherProjectsmatched.length === 0">
                            <div class="d-flex flex-column mt-2 p-2">
                              <div class="d-flex flex-row">
                                <div class="w-100"><span class="d-block"> No other search matches </span></div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </mat-expansion-panel>
                    </div>

                    <div>
                      <mat-expansion-panel class="bg-white text-black mb-3" expanded="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title> Job History </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="results.jobs && results.jobs.projectHistory">
                          <div *ngFor="let item of results.jobs?.projectHistory; let i = index">
                            <div class="d-flex flex-column mt-2 p-2">
                              <div class="d-flex flex-row">
                                <div class="w-50">
                                  <span class="d-block body fw-medium">
                                    {{ item.jobTitle ? (item.jobTitle | titlecase) : '  ' }}
                                    | {{ item.companyName }}
                                  </span>
                                </div>
                                <div class="w-50 d-flex flex-row justify-content-end body-sm">
                                  <span class="d-block fw-medium">Last Contacted:&nbsp;</span>
                                  <span>{{ item.lastContactedDate }}</span>
                                </div>
                              </div>

                              <div class="d-flex flex-row mt-1">
                                <span class="d-block"> {{ item.priorityDesc ? item.priorityDesc + ' | ' : '' }} {{ item.stageDesc ? item.stageDesc + ' | ' : '' }} {{ item.assignedRecruiter }} </span>
                              </div>
                            </div>
                            <mat-divider *ngIf="results.jobs && results.jobs.projectHistory.length - 1 > i" class="mat-background-gray-300"></mat-divider>
                          </div>

                          <div *ngIf="results.jobs.projectHistory.length === 0">
                            <div class="d-flex flex-column mt-2 p-2">
                              <div class="d-flex flex-row">
                                <div class="w-100"><span class="d-block"> No job history </span></div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </mat-expansion-panel>
                    </div>
                  </div>
                </ng-container>
              </div>
            </mat-tab>

            <mat-tab label="NOTES">
              <div *ngIf="(!data.isOmniSearch && TabSelected$.value === 3) || (data.isOmniSearch && TabSelected$.value === 2)">
                <app-notes [candidate]="currentCandidate" [jobId]="data.jobId" [viewType]="data.viewType"> </app-notes>
              </div>
            </mat-tab>

            <mat-tab label="TASKS">
              <div *ngIf="(!data.isOmniSearch&& TabSelected$.value === 4) || (data.isOmniSearch && TabSelected$.value === 3)">
                <app-tasks [candidate]="currentCandidate" [jobId]="data.jobId"></app-tasks>
              </div>
            </mat-tab>

            <ng-container *ngIf="!data.isOmniSearch"> 
            <mat-tab label="THREADS">
              <div class="threads" *ngIf="(!data.isOmniSearch && TabSelected$.value === 5)  ">
                <div class="d-flex flex-column">
                  <ng-container *ngIf="{ emailsThreads: emailsThreads$ | async } as emails">
                    <ng-container *ngIf="emails.emailsThreads && emails.emailsThreads.length > 0; else nothreads">
                      <div *ngFor="let thread of emails.emailsThreads | groupByPipe : 'emailThreadId'">
                        <mat-expansion-panel class="border bg-white mt-3" expanded="true">
                          <mat-expansion-panel-header>
                            <mat-panel-title class="fw-normal"> {{ thread.key }} </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-container>
                            <mat-stepper orientation="vertical" [linear]="true" #stepper>
                              <mat-step *ngFor="let email of thread.value" completed="true">
                                <ng-template matStepLabel>
                                  <div (click)="openEmail(email.emailId)">
                                    <div class="d-flex flex-row justify-content-between">
                                      <span class="mat-color-primary mat-button">{{ email.subject }}</span>
                                      <span class="d-block body"> {{ email.sentDateEpoch * 1000 | date : 'medium' }} </span>
                                    </div>

                                    <div class="d-flex flex-row">
                                      <span class="d-block body-md mat-color-gray-600" [innerHTML]="email.messagePreview | sanitizeHtml"></span>
                                    </div>
                                  </div>
                                </ng-template>
                              </mat-step>
                            </mat-stepper>
                          </ng-container>
                        </mat-expansion-panel>
                      </div>
                    </ng-container>
                    <ng-template #nothreads>
                      <mat-expansion-panel class="bg-white" expanded="true">
                        <mat-expansion-panel-header>
                          <mat-panel-title> Threads </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-container>
                          <div class="d-flex flex-row justify-content-between pt-2">
                            <span class="d-block"> No threads </span>
                          </div>
                        </ng-container>
                      </mat-expansion-panel>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </mat-tab>
            </ng-container>
            <mat-tab label="DETAILS">
              <div class="threads" *ngIf="(!data.isOmniSearch && TabSelected$.value === 6) || (data.isOmniSearch && TabSelected$.value === 4)">
                <ng-container *ngIf="result.candidate">
                  <div class="d-flex flex-column" [formGroup]="candidateForm">
                    <div class="d-flex mat-background-primary-500">
                      <div class="d-flex flex-row flex-shrink-1 p-2 w-100">
                        <span class="text-white fw-medium">Name</span>
                        <mat-icon *ngIf="!showHideEditDetails[0]" class="md-icon-16 c-pointer ms-2 text-white" (click)="$event.stopPropagation(); showHideEditDetails[0] = true">edit</mat-icon>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-100 flex-shrink-1 bg-white text-black">
                        <div class="d-flex flex-row justify-content-between px-4 py-3">
                          <div class="flex-column">
                            <label class="fw-medium"> First Name </label>
                            <label class="d-block hint mat-color-gray-600" *ngIf="!showHideEditDetails[0]"> {{ result.candidate.firstName }} </label>
                            <input type="text" class="form-control mt-1" formControlName="firstName" style="width: 170px" *ngIf="showHideEditDetails[0]" />
                          </div>

                          <div class="flex-column">
                            <label class="fw-medium"> Last Name </label>
                            <label class="d-block hint mat-color-gray-600" *ngIf="!showHideEditDetails[0]"> {{ result.candidate.lastName }} </label>
                            <input type="text" class="form-control mt-1" formControlName="lastName" style="width: 170px" *ngIf="showHideEditDetails[0]" />
                          </div>

                          <div class="flex-column">
                            <label class="fw-medium"> Preferred Name </label>
                            <label class="d-block hint mat-color-gray-600" *ngIf="!showHideEditDetails[0]"> {{ result.candidate.preferredName }} </label>
                            <input type="text" class="form-control mt-1" formControlName="preferredName" style="width: 170px" *ngIf="showHideEditDetails[0]" />
                          </div>
                          <div class="flex-column" *ngIf="showHideEditDetails[0]">
                            <button mat-raised-button color="primary" class="mt-4" (click)="saveName()">
                              <mat-icon>save</mat-icon>
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex mat-background-primary-500 mt-4">
                      <div class="d-flex flex-row flex-shrink-1 p-2 w-100">
                        <span class="text-white fw-medium">Contact</span>
                        <mat-icon
                          *ngIf="!showHideEditDetails[1]"
                          class="md-icon-16 c-pointer ms-2 text-white"
                          (click)="$event.stopPropagation(); editContactInfo(result.candidate.phones, result.candidate.emails)"
                          >edit</mat-icon
                        >
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-100 flex-shrink-1 bg-white text-black">
                        <div class="d-flex flex-row justify-content-between px-4 py-3">
                          <div class="flex-column">
                            <label class="fw-medium"> Email Address </label>
                            <span class="d-block hint mat-color-gray-600" *ngIf="result.candidate?.emails && result.candidate.emails.length > 0"> {{ result.candidate.emails[0].email }}</span>
                            <span *ngIf="result.candidate?.emails?.length === 0" class="d-block hint">No email available </span>
                          </div>

                          <div class="flex-column">
                            <label class="fw-medium"> Phone Number </label>

                            <span class="d-block hint mat-color-gray-600" *ngIf="result.candidate?.phones && result.candidate.phones.length > 0"> {{ result.candidate.phones[0].phoneNumber }}</span>
                            <span *ngIf="result.candidate && result.candidate.phones?.length === 0" class="d-block hint">No phone available </span>
                          </div>

                          <div class="flex-column invisible">
                            <label class="fw-medium"> Phone number </label>
                            <label class="d-block hint mat-color-gray-600"> (904) 056 5452 </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex mat-background-primary-500 mt-4">
                      <div class="d-flex flex-row flex-shrink-1 p-2 w-100">
                        <span class="text-white fw-medium">Location</span>
                        <mat-icon *ngIf="!showHideEditDetails[2]" class="md-icon-16 c-pointer ms-2 text-white" (click)="$event.stopPropagation(); showHideEditDetails[2] = true">edit</mat-icon>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-100 flex-shrink-1 bg-white text-black">
                        <div class="d-flex flex-row justify-content-between px-4 py-3">
                          <div class="flex-column">
                            <label class="fw-medium"> City/State </label>
                            <label class="d-block hint mat-color-gray-600" *ngIf="!showHideEditDetails[2]">{{ result.candidate.cityState }} </label>
                            <div class="custom-height mt-1">
                              <app-input-select
                                *ngIf="showHideEditDetails[2]"
                                label=""
                                iconSuffix="expand_more"
                                [subscriptSizingValue]="'dynamic'"
                                [api]="searchCitystate"
                                fieldClass="customHeight mat-color-gray-600"
                                [text]="result.candidate.cityState"
                                [value]="result.candidate.geoId + ''"
                                (valueChange)="candidateForm.controls.geoId.setValue($event)">
                              </app-input-select>
                            </div>
                          </div>
                          <div class="flex-column" *ngIf="showHideEditDetails[2]">
                            <button mat-raised-button color="primary" class="mt-4" (click)="saveLocation()">
                              <mat-icon>save</mat-icon>
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex mat-background-primary-500 mt-4">
                      <div class="d-flex flex-row flex-shrink-1 p-2 w-100">
                        <span class="text-white fw-medium">Work Status</span>
                        <mat-icon *ngIf="!showHideEditDetails[3]" class="md-icon-16 c-pointer ms-2 text-white" (click)="$event.stopPropagation(); showHideEditDetails[3] = true">edit</mat-icon>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-100 flex-shrink-1 text-black bg-white">
                        <div class="d-flex flex-row justify-content-between px-4 py-3">
                          <div class="flex-column">
                            <label class="fw-medium"> Work Status </label>
                            <label class="d-block hint mat-color-gray-600" *ngIf="!showHideEditDetails[3]"> {{ result.candidate.workStatusDesc }} </label>

                            <select class="form-select mt-1" formControlName="workStatusId" *ngIf="showHideEditDetails[3]">
                              <ng-container *ngIf="{ WorkStatus: WorkStatus$ | async } as result">
                                <ng-container *ngIf="result.WorkStatus">
                                  <option *ngFor="let item of result.WorkStatus" [value]="item.workStatusId">
                                    {{ item.workStatusDesc }}
                                  </option>
                                </ng-container>
                              </ng-container>
                            </select>
                          </div>
                          <div class="flex-column" *ngIf="showHideEditDetails[3]">
                            <button mat-raised-button color="primary" class="mt-4" (click)="saveWorkStatus()">
                              <mat-icon>save</mat-icon>
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex mat-background-primary-500 mt-4">
                      <div class="d-flex flex-row flex-shrink-1 p-2 w-100">
                        <span class="text-white fw-medium"> Work Preferences</span>
                        <mat-icon *ngIf="!showHideEditDetails[4]" class="md-icon-16 c-pointer ms-2 text-white" (click)="$event.stopPropagation(); showHideEditDetails[4] = true">edit</mat-icon>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-100 flex-shrink-1 text-black bg-white">
                        <div class="d-flex justify-content-between px-4 py-3">
                          <div style="width: 170px">
                            <label class="fw-medium"> Desired Employment Type </label>
                            <label class="d-block hint mat-color-gray-600" *ngIf="!showHideEditDetails[4]"> {{ result.candidate.positionTypes[0]?.positionTypeDesc }} </label>
                            <div class="custom-height mt-2" *ngIf="showHideEditDetails[4]">
                              <ng-container *ngIf="{ positionTypes: positionTypes$ | async } as result">
                                <ng-container *ngIf="result.positionTypes">
                                  <mat-form-field class="no-underline shrink-form-field" style="max-height: 35px; width: 158px" subscriptSizing="dynamic">
                                    <mat-select multiple="true" formControlName="positionTypeId">
                                      <mat-option *ngFor="let item of result.positionTypes" [value]="item.positionTypeId">
                                        {{ item.positionType }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </ng-container>
                              </ng-container>
                            </div>
                          </div>
                          <div>
                            <label class="fw-medium"> Desired Range (USD) </label>
                            <label class="d-block hint mat-color-gray-600" *ngIf="!showHideEditDetails[4]">
                              From $ {{ result.candidate.minPayRate }} - ${{ result.candidate.maxPayRate }} {{ result.candidate.payTypeDesc }}
                            </label>
                            <div class="d-flex align-items-center mt-2" *ngIf="showHideEditDetails[4]">
                              <ng-container *ngIf="{ payRateTypes: payRateTypes$ | async } as result">
                                <ng-container *ngIf="result.payRateTypes">
                                  <label>From:</label>
                                  <div class="custom-height">
                                    <input type="number" class="form-control ms-2" formControlName="minPayRate" style="width: 60px" />
                                  </div>

                                  <label class="ms-2">To:</label>
                                  <div class="custom-height">
                                    <input type="number" class="form-control ms-2" formControlName="maxPayRate" style="width: 60px" />
                                  </div>

                                  <div class="custom-height ms-2">
                                    <select class="form-select" formControlName="payTypeId">
                                      <option *ngFor="let item of result.payRateTypes" [value]="item.payRateId">
                                        {{ item.payRateDesc }}
                                      </option>
                                    </select>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </div>
                          </div>
                          <div style="width: 140px">
                            <label class="fw-medium"> Earliest Available Date </label>
                            <label class="d-block hint mat-color-gray-600" *ngIf="!showHideEditDetails[4]"> {{ candidateForm.value.availableDate ? (candidateForm.value.availableDate | date) : 'Not specified'  }} </label>
                            <div class="custom-height mt-2" *ngIf="showHideEditDetails[4]">
                              <mat-form-field style="width: 140px" subscriptSizing="dynamic">
                                <input matInput [matDatepicker]="picker1" formControlName="availableDate" />
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div>
                          <div *ngIf="showHideEditDetails[4]">
                            <button mat-raised-button color="primary" style="margin-top: 25px" (click)="saveWorkPreferences()">
                              <mat-icon>save</mat-icon>
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div class="col-3">
          <div class="bg-white">
            <div class="activity-header">Activity</div>
            <ng-container *ngIf="candidateActivities.length > 0; else noActivities">
              <div *ngFor="let item of candidateActivities">
                <mat-expansion-panel class="activity-panel bg-white" expanded="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ item.date | formatMonthYear }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div *ngFor="let activity of item.activities" class="activity-entry">
                    <div class="d-flex flex-column mt-2">
                      <div class="d-flex flex-row">
                        <div class="w-100">
                          <div class="body-md fw-medium">{{ activity.title }}</div>
                          <div class="body-sm">
                            {{ activity.createdDate * 1000 | date }}
                          </div>
                        </div>
                      </div>
                      <div class="d-flex flex-row mt-1 pb-1">
                        <div class="w-100">
                          <span class="body-md mat-color-gray-600">{{ activity.summaryDesc }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </div>
            </ng-container>
            <ng-template #noActivities>
              <div class="d-flex flex-row mt-2">
                <span class="d-block px-3 pt-1 pb-3">No activity</span>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>
</ng-container>

<ng-template #preview>
  <ng-container *ngIf="{ emailDetails: email$ | async } as email">
    <ng-container *ngIf="email.emailDetails">
      <div class="d-flex align-items-center mt-2" mat-dialog-title>
        <label class="text-primary">{{ email.emailDetails.jobTitle }}</label>
        <button mat-dialog-close mat-mini-fab color="primary" class="ms-auto">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <mat-divider></mat-divider>

      <div class="mt-1" mat-dialog-content>
        <div class="d-flex">
          <div class="flex-column flex-grow-1">
            <!--From-->
            <div class="m-2">
              <label class="body-md pe-3"> From: </label>
              <label> {{ email.emailDetails.fromAddress }} </label>
            </div>

            <mat-divider></mat-divider>

            <!--To-->

            <div class="d-flex align-items-center m-2">
              <label class="body-md pe-3"> To: </label>

              <label class="   "> {{ email.emailDetails.toAddress }} </label>
            </div>

            <mat-divider style="font-size: 1rem"></mat-divider>

            <!--Subject-->
            <div class="d-flex m-2">
              <label class="body-md pe-3"> Subject: </label>

              <label> {{ email.emailDetails.subject }} </label>
            </div>

            <mat-divider></mat-divider>

            <!--Body-->
            <div class="d-flex mt-3" style="min-height: 21vw">
              <div class="p-3 emailBody pe-none" [innerHTML]="email.emailDetails.messageBody | safe : 'html'"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <mat-divider></mat-divider>
</ng-template>
