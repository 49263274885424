import { Component, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject, of } from 'rxjs';
import { takeUntil, map, switchMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { mapSuccessData, mapSuccessDataTable } from 'src/app/utils/rxjs-operators';

import { CandidatesService } from 'src/app/services/api/candidates.service';
import { EmployeesService } from 'src/app/services/api/employees.service';
import { JobPostingsService } from 'src/app/services/api/job-postings.service';
import { Task } from 'src/app/services/api/tasks.service';
import { XrefService, Company } from 'src/app/services/api/xref.service';

import { DataTable } from 'src/app/services/api.service';
import { ContextService } from 'src/app/services/context.service';
import { DialogData } from 'src/app/services/dialog.service';
import { createSuccessResult } from 'src/app/utils/api-helpers';

@Component({
  selector: 'app-pr-task-dialog',
  templateUrl: './pr-task-dialog.component.html',
  styleUrls: ['./pr-task-dialog.component.scss']
})
export class TaskDialogComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  form = this.formBuilder.group({
    title: ['', [Validators.required]]
  });

  showOptions: { [key in OptionsKey]?: boolean } = {};
  textKey: { [key in OptionsKey]: OptionsText } = {
    assignToId: 'assignToName',
    profileRef: 'candidateDisplayName',
    companyId: 'companyName',
    jobId: 'jobTitle'
  };

  searchRecruiter = (searchValue?: string) => this.employees.search(searchValue).pipe(
    mapSuccessData(data => data.map(x => ({ value: x.userId, text: x.nameDisplay }))),
    takeUntil(this.destroy$)
  );

  searchCandidate = (searchValue?: string) => of(searchValue).pipe(
    map(searchValue => searchValue && searchValue.length >= 3 ? searchValue : undefined),
    switchMap(searchValue => {
      if (searchValue)
        return this.candidates.get({ searchValue }).pipe(
          mapSuccessDataTable(item => ({ value: item.profileRef, text: item.displayName })),
          mapSuccessData(data => data.dataTable)
        );

      return of(createSuccessResult([]));
    }),
    takeUntil(this.destroy$)
  );

  searchCompany = (searchValue?: string) => this.xref.get<DataTable<Company>>({
    path: 'companies',
    params: (searchValue || '')?.length === 0 ? { context: 'jobs' } : { searchValue }
  }).pipe(
    mapSuccessDataTable(item => ({ value: +item.companyId, text: item.companyName, typeaheadText: item.companyName })),
    mapSuccessData(data => data.dataTable),
    takeUntil(this.destroy$)
  );

  searchJob = (searchValue?: string) => this.jobPostings.getJobs({ searchValue, viewId: 1000 }).pipe(
    mapSuccessDataTable(item => ({ value: +item.jobId, text: item.jobTitle })),
    mapSuccessData(data => data.dataTable),
    takeUntil(this.destroy$)
  );

  constructor(
    private candidates: CandidatesService,
    private employees: EmployeesService,
    private jobPostings: JobPostingsService,
    private xref: XrefService,

    private context: ContextService,

    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<TaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData<Task>
  ) {
    _data.item = { ...this.context.latest, ..._data.item };

    Object.keys(this.textKey).forEach(key => {
      this.showOptions[key as OptionsKey] = !!this._data.item[key as OptionsKey];
    });

    this.form.controls.title.setValue(this._data.item.title);
  }

  toggleKey(key: OptionsKey) {
    this.showOptions[key] = !this.showOptions[key];

    if (!this.showOptions[key]) {
      (this._data.item as any)[key] = undefined;
      (this._data.item as any)[this.textKey[key]] = undefined;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(result: any): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.dialogRef.close(result);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

type OptionsKey = 'assignToId' | 'profileRef' | 'companyId' | 'jobId';
type OptionsText = 'assignToName' | 'candidateDisplayName' | 'companyName' | 'jobTitle';