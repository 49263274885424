import { Component, OnDestroy } from '@angular/core';
import { Subject, of, BehaviorSubject, merge } from 'rxjs';
import { takeUntil, filter, switchMap, share, map, tap } from 'rxjs/operators';

import { truthy, mapSuccessData, distinctUntilChangedDeep } from 'src/app/utils/rxjs-operators';

import { CandidatesService } from 'src/app/services/api/candidates.service';
import { XrefService, RuleGroup } from 'src/app/services/api/xref.service';

import { ContextService } from 'src/app/services/context.service';

@Component({
  selector: 'app-pr-candidate-sidebar',
  templateUrl: './pr-candidate-sidebar.component.html',
  styleUrls: ['./pr-candidate-sidebar.component.scss']
})
export class CandidateSidebarComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  tabs = [
    { text: 'Overview' },
    { text: 'Jobs Matched' },
    { text: 'Notes' },
    { text: 'Tasks' }
  ];
  tab$ = new BehaviorSubject<typeof this.tabs[number]>(this.tabs[0]);

  offset$ = new Subject<number>();
  showPrev = false;
  showNext = false;

  candidate$ = merge(
    this.candidates.sidebarShow$.pipe(
      truthy()
    ),
    this.candidates.sidebarShowTable$.pipe(
      truthy(),
      tap(({ table, i }) => {
        this.showPrev = i > 0;
        this.showNext = i < Math.min(table.pagination.numResults, table.pagination.totalEntries) - 1;
      }),
      map(({ table, i }) => table.dataTable[i])
    )
  ).pipe(
    filter(data => !!data.profileRef),
    distinctUntilChangedDeep(),
    takeUntil(this.destroy$)
  );

  overview$ = this.candidate$.pipe(
    switchMap(candidate => this.candidates.overview(candidate.profileRef)),
    takeUntil(this.destroy$)
  );

  jobsMatched$ = this.candidate$.pipe(
    switchMap(candidate => this.candidates.jobsMatched(candidate.profileRef)),
    takeUntil(this.destroy$)
  );

  caseInsights$ = this.candidate$.pipe(
    switchMap(candidate => candidate.jobId ? this.candidates.analysisDetail(candidate.profileRef, candidate.jobId) : of(null)),
    takeUntil(this.destroy$)
  );

  ruleGroups$ = this.xref.get<RuleGroup[]>({
    path: 'rule-groups'
  }).pipe(
    mapSuccessData(data => data.map(x => ({ isOpen: false, ...x }))),
    takeUntil(this.destroy$)
  );

  constructor(
    private candidates: CandidatesService,
    private context: ContextService,
    private xref: XrefService
  ) {
    this.candidate$.pipe(
      share(),
      takeUntil(this.destroy$)
    ).subscribe(candidate => {
      this.context.push({
        profileRef: candidate.profileRef,
        candidateDisplayName: candidate.displayName
      }, this.destroy$);
    });
  }

  closeSidebar() {
    this.candidates.closeSidebar();
  }

  prev() {
    const v = this.candidates.sidebarShowTable$.value;

    if (v) {
      const table = v.table;
      const i = Math.max(0, Math.min(v.i - 1, table.pagination.numResults - 1));

      if (i !== v.i) {
        this.candidates.sidebarShowTable$.next({ table, i });
      }
    }
  }

  next() {
    const v = this.candidates.sidebarShowTable$.value;

    if (v) {
      const table = v.table;
      const i = Math.max(0, Math.min(v.i + 1, table.pagination.numResults - 1));

      if (i !== v.i) {
        this.candidates.sidebarShowTable$.next({ table, i });
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}