import { Pipe, PipeTransform } from '@angular/core';

import { ApiResult, ApiResultError, ApiResultSuccess } from 'src/app/services/api.service';

@Pipe({ name: 'apiResultsMerge' })
export class ApiResultsMergePipe implements PipeTransform {
  transform<T extends Record<string, ApiResult<unknown> | null>>(results: T) {
    type MappedFinishedData = { [key in keyof T]: T[key] extends ApiResult<infer U, infer V> | null ? ApiResultSuccess<U, V> | ApiResultError : never };
    type MappedSuccessData = { [key in keyof T]: T[key] extends ApiResult<infer U, infer V> | null ? ApiResultSuccess<U, V> : never };

    const logAndReturn = <U>(merged: U): U => {
      // console.log(results);
      // console.log(merged);
      return merged;
    };

    const all = Object.values(results);

    if (all.some(x => !x || !x.finished)) {
      return logAndReturn({
        finished: false as const,
        success: undefined,
        data: results
      });
    }

    if (all.some(x => !x || !x.finished || !x.finished)) {
      return logAndReturn({
        finished: true as const,
        success: false as const,
        data: results as any as MappedFinishedData
      });
    }

    return logAndReturn({
      finished: true as const,
      success: true as const,
      data: results as any as MappedSuccessData
      // data: Object.entries(results).map(([key, value]) => ({ [key]: (value as ApiResultSuccess<any>).data })).reduce((a, x) => ({ ...a, ...x }), {}) as MappedDataTuple
    });
  }
}

// type Record2<K extends keyof any, T> = {
//   [P in K]: T;
// };

// const results: Record<string, ApiResult<string> | null> = { abc: { finished: true, success: true, data: '1', request: null as any, response: null as any } };

// type T = typeof results

// const d = results as { [key in keyof T]: T[key] extends ApiResult<infer U, infer V> | null ? ApiResultSuccess<U, V> : never };
// const sdfsdf = d.abc;
