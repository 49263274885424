import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';
import { AuthGuard } from './auth.guard';

@Injectable()
export class NegateAuthGuard {
  constructor(
    private auth: AuthService,
    private guard: AuthGuard,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.auth.userInfo$.pipe(
      map(() => {
        if (this.check(route)) {
          return true;
        }

        const landingPage = route.queryParams.returnUrl || this.auth.landingPage;
        void this.router.navigateByUrl(landingPage);
        return false;
      })
    );
  }

  check(route: ActivatedRouteSnapshot) {
    return !this.guard.check(route);
  }
}