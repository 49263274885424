import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMonthYear'
})
export class FormatMonthYearPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || value.length <= 4) {
      return value;
    }

    const month = value.slice(0, value.length - 4);
    const year = value.slice(-4);

    return `${month} ${year}`;
  }
}
