<div class="d-flex text-bg-danger" style="height: 1.5rem"></div>

<div class="border border-danger" mat-dialog-content>
  <div class="align-items-center d-flex flex-row">
    <mat-icon class="md-icon-38 text-danger">warning</mat-icon>
    <div class="ms-3">
      <label class="fw-bold">Error</label>
      <br />
      <label class="mb-1">Something went wrong and the server was unable to complete the request.</label>
      <br />
      <label class="mt-1">Please try again later.</label>
    </div>

    <mat-icon mat-dialog-close class="ms-2 md-icon-22 mat-color-gray-600">highlight_off</mat-icon>
  </div>
</div>
