import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Socket } from 'ngx-socketio2-updated';

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnDestroy {
  private destroy$ = new Subject<void>();

  private rooms: string[] = [];

  constructor(
    private socket: Socket
  ) {
    this.socket.on('connect').pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.socket.emit('join', this.rooms);
    });
  }

  joinRoom(room: string) {
    this.rooms.push(room);
    this.socket.emit('join', room);
  }

  leaveRoom(room?: string) {
    this.socket.emit('leave', room || this.rooms);

    if (room) {
      this.rooms.splice(this.rooms.indexOf(room), 1);
    } else {
      this.rooms = [];
    }
  }

  emit(event: string, msg: string) {
    this.socket.emit(event, msg);
  }

  get<T>(event: string) {
    return this.socket.on<T>(event).pipe(
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}