import { Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
 
import { saveAs } from 'file-saver';
 
import { of, Subject, takeUntil } from 'rxjs';
import { CandidatesService } from 'src/app/services/api/candidates.service';
import { FilesService } from 'src/app/services/files.service';


@Component({
  selector: 'app-preview-resume',
  templateUrl: './preview-resume.component.html',
  styleUrls: ['./preview-resume.component.css']
})
export class PreviewResumeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  allowVersions = true;
  isLoading = true;
  fileUrl: string | undefined = undefined;
  fileType: string | undefined = undefined;
  fileHtml: string | undefined = undefined;
  selectedfileIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private filesService: FilesService,
    public candidatesService: CandidatesService) {}

  ngOnInit() {
    this.allowVersions = this.data.allowVersions;
    this.selectFile(0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setType(fileName: string) {
    this.fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
  }

  selectFile(index: number) {
    const version = this.data.versions[index];
    this.isLoading = true;
    this.setType(version.fileName);

    switch (this.fileType) {
      case 'pdf':
      case 'docx':
      case 'doc':
      case 'html':
        this.handleFile(version, this.fileType);
        break;
      default:
        this.downloadFile(version);
    }
  }

  private handleFile(version: any, type: string) {
    let service;
    if (type === 'pdf') {
      service = of(version.url);
    } else if (type === 'html') {
      service = this.filesService.fetchHtmlContent(version.url);
    } else {
      service = this.filesService.convertDocxToHtml(version.url);
    }

    service.pipe(
      takeUntil(this.destroy$)
    ).subscribe(response => {
      if (type === 'pdf') {
        this.fileUrl = response;
      } else {
        this.fileHtml = response;
      }
      this.isLoading = false;
    });
  }

  private downloadFile(version: any) {
    saveAs(version.url, version.fileName);
    this.isLoading = false;
  }
}

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }
}
