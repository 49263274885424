
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogClose } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

import { BehaviorSubject, Subject, combineLatest, startWith, switchMap, takeUntil } from 'rxjs';
import { Pagination } from 'src/app/services/api.service';

import { NoteBody, NoteItem, NotesService } from 'src/app/services/api/notes.service';
import { AddNoteComponent } from '../add-note/add-note.component';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class NotesComponent implements OnInit, OnDestroy, OnChanges {

  @Input() candidate: any;
  @Input() jobId: any;
  @Input() viewType: any;

  @ViewChild('addNote', { static: false }) addNoteModal?: TemplateRef<any>;

  @ViewChild('innerClose') close?: MatDialogClose;

  currentCandidate$ = new BehaviorSubject<any | undefined>(undefined);


  notesForm = this.fb.group({
    notes: this.fb.array([])
  });

  submitted = false;
  private destroy$ = new Subject<void>();
  notesList: NoteItem[] = [];


  notesTablePaginationOffset$ = new BehaviorSubject<any | undefined>(undefined);

  notes$ = combineLatest([
    this.currentCandidate$,
    this.notesTablePaginationOffset$.pipe(

      startWith({} as Pagination)
    ),
    this.noteService.refreshNotes$.asObservable()

  ]).pipe(
    switchMap(([candidate, pagination]) => this.noteService.getNotes(candidate.profileRef, pagination)),

    takeUntil(this.destroy$)
  );


  searchValue$ = new BehaviorSubject<string>('');

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private noteService: NotesService


  ) {

  }

  ngOnInit() {


    this.notes$.pipe(takeUntil(this.destroy$)).subscribe(notes => {

      notes.dataTable.forEach(x => x.shortNote = x.note?.substring(0, 40));

      this.notesList = notes.dataTable;


    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currentCandidate$.next(changes.candidate.currentValue);
  }

  addNoteAction() {
    this.dialog.open(AddNoteComponent, {
      minWidth: '900px', data: {
        jobId: this.jobId,
        candidate: this.candidate

      }
    });
  }


  showEditNoteForm(noteIndex: number) {

    this.dialog.open(AddNoteComponent, {
      minWidth: '900px', data: {
        jobId: this.jobId,
        candidate: this.candidate,
        editMode: true,
        note: this.notesList[noteIndex]
      }
    });

  }

  deleteNote(noteIndex: number) {

    const body: NoteBody = {
      noteId: this.notesList[noteIndex].noteId,
      profileRef: this.candidate.profileRef,
      noteTypeId: this.notesList[noteIndex].noteTypeId,
      noteTitle: this.notesList[noteIndex].noteTitle,
      note: this.notesList[noteIndex].note,
      jobs: [Number(this.jobId)],
      isActive: 0,
      taskDueDateEpoch: '',
      isConfidential: this.notesList[noteIndex].isConfidential
    };
    this.noteService.saveNote(body).pipe(takeUntil(this.destroy$)).subscribe();

  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  changePage(event: PageEvent) {
    this.notesTablePaginationOffset$.next({ offset: event.pageIndex * event.pageSize, numResults: event.pageSize });
  }
}
