import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';

import { ErrorAccessDeniedComponent } from './error-access-denied.component';

@NgModule({
  declarations: [ErrorAccessDeniedComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,

    MatButtonModule
  ]
})
export class ErrorAccessDeniedModule { }
