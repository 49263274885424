import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DataTable } from './../api.service';
import { XrefService } from './xref.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService implements OnDestroy {
  private destroy$ = new Subject<void>();

  get = (companyId: string) => this.xref.get<Company>({
    path: 'companies',
    params: { companyId },
    cacheDuration: 0
  }).pipe(
    takeUntil(this.destroy$)
  );

  search = (searchValue?: string) => this.xref.get<DataTable<Company>>({
    path: 'companies',
    params: { searchValue },
    cacheDuration: 0
  }).pipe(
    takeUntil(this.destroy$)
  );

  companyMatches = () => this.xref.get<DataTable<RawCompanyMatch>>({
    path: 'company-matches', cacheDuration: 0
  }).pipe(
    takeUntil(this.destroy$)
  );

  saveCompanyMatches = (merges: RawCompanyMatch[]) => this.xref.post<void>({
    path: 'company-matches',
    body: { merges }
  }).pipe(
    takeUntil(this.destroy$)
  );

  searchCompanies = (searchValue?: string) => this.xref.get<DataTable<Company>>({
    path: 'companies',
    params: (searchValue || '') ? { searchValue } : {context: 'jobs'},
    cacheDuration: -1
  }).pipe(
    takeUntil(this.destroy$)
  );

  constructor(
    private xref: XrefService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface Company {
  companyId: string;
  companyName: string;
  companySearch?: string;
  companyTypeId?: string;
  isActive?: '0' | '1';
}

export interface CompanyRaw {
  companyId: string;
  companyRawId: string;
  aliasCompanyId: string;
  companyName: string;
  matchProbability: string;
  recruiterId: string;
  searchValue: string;
  isValid: '0' | '1';
  hasSpellingErrors: '0' | '1';
}

export interface RawCompanyMatch {
  companyId: string;
  companyName?: string;
  matchCompanyId: string;
  matchCompanyName?: string;
  isValid?: 0 | 1;
}