import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { DateTimePickerModule } from 'src/app/components/date-time-picker/date-time-picker.module';
import { InputSelectModule } from 'src/app/components/input-select/input-select.module';
import { InputTextModule } from 'src/app/components/input-text/input-text.module';

import { NoteDialogComponent } from './pr-note-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,

    DateTimePickerModule,
    InputSelectModule,
    InputTextModule
  ],
  declarations: [NoteDialogComponent],
  exports: [NoteDialogComponent]
})
export class NoteDialogModule { }
