<div class="campaign-modal">
  <div class="p-3" mat-dialog-title>
    <div class="w-100 d-flex justify-content-between align-items-center">
      <label class="mat-color-primary-500">Add Candidates to Campaign</label>
      <mat-spinner class="m-1" [diameter]="30" color="accent" *ngIf="loading"></mat-spinner>

      <button mat-dialog-close class="modal-close-icon-btn">
        <mat-icon class="md-icon-14">close</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content>
    <form [formGroup]="form">
      <div>
        <div>
          <div class="fw-medium text-black body-md mb-1">Add:</div>
          <section class="d-flex align-items-center" formGroupName="Add">
            <div class="form-check d-flex align-items-center me-4">
              <input class="form-check-input mt-0 me-2" type="checkbox" id="currentSelection" (change)="checkAddSelected('CurrentSelection')" formControlName="CurrentSelection" />
              <label class="form-check-label" for="currentSelection"> Current Selection </label>
            </div>

            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center me-3">
                <input class="form-check-input mt-0 me-2" type="checkbox" id="priority" (change)="checkAddSelected('Priority')" formControlName="Priority" />
                <label class="form-check-label" for="priority"> Priority </label>
              </div>

              <div [ngClass]="form.controls.Add.controls.priorityIds.hasError('required') ? 'border border-danger rounded' : ''">
                <mat-form-field
                  style="max-height: 35px; max-width: 174px"
                  class="mat-multi-select"
                  [ngClass]="form.controls.Add.controls.priorityIds.hasError('required') ? 'no-underline shrink-form-field border border-danger rounded' : 'no-underline shrink-form-field'">
                  <mat-select formControlName="priorityIds" multiple>
                    <mat-option *ngFor="let item of priorityCounts" [value]="item.priorityId"> {{ item.priorityDesc }} {{ '(' }} {{ item.cnt }} {{ ')' }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </section>

          <div class="w-100 mat-background-gray-400 mt-3" style="height: 1px"></div>

          <div class="d-flex flex-row mt-2">
            <mat-error *ngIf="submitted && form.controls.Add.hasError('mustselect')"> * Select one </mat-error>
          </div>
        </div>

        <div class="w-100">
          <div class="d-flex justify-content-between align-items-center">
            <label class="mt-2 fw-medium text-black body-md">Recipients:</label>
            <div class="d-flex align-items-center mt-2 p-2" style="border-radius: 5px">
              <mat-icon style="overflow: visible" class="text-warning mat-color-gray-600 md-icon-18">warning</mat-icon>
              <label class="ms-1 mat-color-gray-600 body-lg"> Candidates in gray won't receive message; no email address could be found.</label>
            </div>
          </div>
        </div>
        <div class="p-2 emails-border mt-3" style="height: 12rem; width: 100%; overflow-y: auto; overflow-x: auto">
          <div class="d-flex flex-row align-items-center mt-2" *ngIf="emailListLoading">
            <mat-spinner class="me-3" matSuffix color="accent" diameter="24"> </mat-spinner>
            <label>Loading contact information </label>
          </div>

          <div class="d-flex flex-column">
            <mat-chip
              [matTooltip]="user.email"
              style="width: fit-content"
              *ngFor="let user of emailsList"
              [removable]="true"
              (removed)="onUserRemoved(user)"
              [ngClass]="user.email ? 'mat-background-accent-light fw-normal mt-2' : 'mt-2'">
              {{ user.displayName }} <ng-container *ngIf="user.email">({{ user.email }}) </ng-container>

              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="mt-4 border-top">
    <div class="d-flex justify-content-end align-items-center" mat-dialog-actions>
      <button mat-stroked-button color="primary" class="me-3" mat-dialog-close><mat-icon>cancel</mat-icon>Close</button>
      <button mat-flat-button color="primary" [disabled]="!form.valid || emailsList.length === 0 || loading" (click)="submit()"><mat-icon>save</mat-icon> Save</button>
    </div>
  </div>
</div>
