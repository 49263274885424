<mat-form-field [appearance]="appearance" [ngClass]="(label ? '' : 'fix-infix') + ' ' + fieldClass">
  <mat-icon class="pe-2" matPrefix *ngIf="iconPrefix">{{iconPrefix}}</mat-icon>
  <mat-label>{{label || ' '}}</mat-label>

  <input #trigger matInput type="text" [value]="_valueDate" [disabled]="disabled" [matDatepickerFilter]="filterFuture"
    [matDatepicker]="picker" (dateChange)="$event.value ? setDate($event.value) : null" />

  <button mat-icon-button matSuffix (click)="picker.open()" *ngIf="!disabled && format.includes('date')">
    <mat-icon>calendar_today</mat-icon>
  </button>
  <button mat-icon-button matSuffix (click)="openOverlay = trigger" *ngIf="!disabled && format.includes('time')">
    <mat-icon>access_time</mat-icon>
  </button>
</mat-form-field>

<mat-datepicker #picker disabled="false"></mat-datepicker>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="$any(trigger).parentNode.parentNode"
  [cdkConnectedOverlayPositions]="overlayPositions" [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOpen]="openOverlay === trigger" (backdropClick)="openOverlay = undefined">
  <div class="overlay-panel scroll-y mh-300px">
    <mat-accordion>

      <ng-container *ngFor="let partOfDay of partsOfDay; index as index;">
        <mat-expansion-panel *ngIf="partOfDay.show">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{partOfDay.text}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-option (click)="setTime(item.value)" *ngFor="let item of times | filterBy: ['group']: index + 1">
            {{item.text}}
          </mat-option>
        </mat-expansion-panel>
      </ng-container>

    </mat-accordion>
  </div>
</ng-template>