import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCardModule } from '@angular/material/card';

import { UserAvatarComponent } from './user-avatar.component';

@NgModule({
  declarations: [UserAvatarComponent],
  imports: [
    CommonModule,

    MatCardModule
  ],
  exports: [UserAvatarComponent]
})
export class UserAvatarModule { }
