import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';

import { ErrorNotFoundComponent } from './error-not-found.component';

@NgModule({
  declarations: [ErrorNotFoundComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,

    MatButtonModule
  ]
})
export class ErrorNotFoundModule { }
