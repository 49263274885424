import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private scrollCommand = new BehaviorSubject<void | undefined>(undefined);

  // Observable to be consumed by components
  public scrollCommand$ = this.scrollCommand.asObservable();

  constructor() {}

  // Method to trigger scroll
  triggerScrollToTop() {
    this.scrollCommand.next();
  }

  scrollToTop(element: ElementRef | undefined) {
    if (element) {
      element.nativeElement.scrollTop = 0;
    }
  }
}
