import { Observable, pipe, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, tap, switchMap } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

import { ApiResult, DataTable } from 'src/app/services/api.service';
import { HttpRequestBase } from 'src/app/services/http.service';

export function distinctUntilChangedDeep<T>() {
  return pipe(
    distinctUntilChanged((prev: T, curr: T) => isEqual(prev, curr))
  );
}

export function truthy() {
  return pipe(
    filter(<T>(value: T | null | undefined): value is T => value !== null && value !== undefined)
  );
}

export function truthyData() {
  return pipe(
    filter(<T, R extends HttpRequestBase = HttpRequestBase>(result: ApiResult<T | null | undefined, R>): result is ApiResult<T, R> => !result.finished || !result.success || (result.data !== null && result.data !== undefined))
  );
}

export function tapUnsubscribe<T>(callback: () => void) {
  return (obs: Observable<T>) => {
    return new Observable<T>(subscriber => {
      const sub = obs.subscribe({
        next: value => subscriber.next(value),
        error: (err: unknown) => subscriber.error(err),
        complete: () => subscriber.complete()
      });
      return () => {
        callback();
        sub.unsubscribe();
      };
    });
  };
}

export function mapSuccessData<T, U, R extends HttpRequestBase = HttpRequestBase>(project: (value: T) => U) {
  return pipe(
    map((result: ApiResult<T, R>): ApiResult<U, R> => (result.success ? { ...result, data: project(result.data) } : result))
  );
}

export function mapSuccessDataTable<T, U, R extends HttpRequestBase = HttpRequestBase>(project: (value: T) => U) {
  return pipe(
    map((result: ApiResult<DataTable<T>, R>): ApiResult<DataTable<U>, R> => (result.success ? { ...result, data: { ...result.data, dataTable: result.data.dataTable.map(project) } } : result))
  );
}

export function switchMapSuccess<T, U>(project: (value: T) => Observable<U>) {
  return pipe(
    switchMap((result: ApiResult<T>) => result.success ? project(result.data) : of(result))
  );
}

export function tapLoading<T>(next: () => void) {
  return pipe(
    tap((result: ApiResult<T>) => !result.finished ? next() : null)
  );
}

export function tapFinished<T>(next: () => void) {
  let ranOnce = true;

  return pipe(
    tap((result: ApiResult<T>) => {
      if (!result.finished) {
        ranOnce = false;
      } else if (!ranOnce && result.finished) {
        ranOnce = true;
        next();
      }
    }),
    tapUnsubscribe(() => {
      if (!ranOnce) {
        ranOnce = true;
        next();
      }
    })
  );
}

export function tapSuccess<T>(next: (value: T) => void) {
  return pipe(
    tap((result: ApiResult<T>) => result.success ? next(result.data) : null)
  );
}

export function finishedOnly<T, R extends HttpRequestBase = HttpRequestBase>() {
  return pipe(
    map((result: ApiResult<T, R>) => result.finished ? result : null),
    truthy()
  );
}

export function successOnly<T, R extends HttpRequestBase = HttpRequestBase>() {
  return pipe(
    map((result: ApiResult<T, R>) => result.success ? result : null),
    truthy()
  );
}

export function successData<T, R extends HttpRequestBase = HttpRequestBase>() {
  return pipe(
    successOnly<T, R>(),
    map(result => result.data)
  );
}

export function successDataTable<T, R extends HttpRequestBase = HttpRequestBase>() {
  return pipe(
    successOnly<DataTable<T>, R>(),
    map(result => result.data.dataTable)
  );
}

export function delayedLoading<T>(delay: number) {
  return pipe(
    switchMap((result: ApiResult<T>) => {
      if (!result.finished && delay > 0) {
        return timer(delay).pipe(
          switchMap(() => of(result))
        );
      }

      return of(result);
    })
  );
}