import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { IframeService } from 'src/app/services/iframe.service';

@Injectable()
export class IframeGuard {

  constructor(
    private auth: AuthService,
    private iframe: IframeService,
    private router: Router
  ) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.iframe.isInIframe) {
      const landingPage = this.auth.landingPage;
      void this.router.navigate([landingPage], landingPage.startsWith('/login') ? { queryParams: { returnUrl: state.url } } : {});
      return false;
    }

    return true;
  }
}
