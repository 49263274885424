import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgMathPipesModule } from 'ngx-pipes-updated';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { DateTimePickerModule } from 'src/app/components/date-time-picker/date-time-picker.module';
import { InputTextModule } from 'src/app/components/input-text/input-text.module';

import { JobDescDialogComponent } from './pr-job-desc-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgMathPipesModule,
    NgxDropzoneModule,

    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressBarModule,

    DateTimePickerModule,
    InputTextModule
  ],
  declarations: [JobDescDialogComponent],
  exports: [JobDescDialogComponent]
})
export class JobDescDialogModule { }
