<div class="d-flex flex-row justify-content-between m-1 mat-small align-items-center">
  <div class="d-flex body-md">
    Showing
    {{ Math.min(pageIndex * pageSize + 1, length) | number : '1.0' : 'en-US' }}
    <span class="mx-1">–</span>
    {{ Math.min((pageIndex + 1) * pageSize, length) | number : '1.0' : 'en-US' }}
    <ng-container *ngIf="length !== null">
      <span class="mx-1">of</span>
      {{ length | number : '1.0' : 'en-US' }} entries
    </ng-container>
  </div>
  <div class="d-flex ms-2" *ngIf="length > 0">
    <div class="d-flex align-items-center body-md">Rows per page:</div>
    <div class="d-flex ms-2 custom-height" style="width: 6rem">
      <mat-form-field class="no-underline shrink-form-field" style="max-height: 28px">
        <mat-select [value]="pageSize + ''" (selectionChange)="changePageSize($event)">
          <mat-option value="25"> 25 </mat-option>
          <mat-option value="50"> 50 </mat-option>
          <mat-option value="75"> 75 </mat-option>
          <mat-option value="100"> 100 </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex ms-2" *ngIf="pagination && pagination.maxPages > 1">
    <div class="d-flex align-items-center body-md">Jump to:</div>
    <div class="d-flex ms-2 custom-height" style="width: 6rem">
      <mat-form-field class="no-underline shrink-form-field" style="max-height: 28px">
        <mat-select [value]="pageIndex + ''" (selectionChange)="jumpTo($event)">
          <mat-option *ngFor="let page of pagesList" [value]="page - 1 + ''">
            {{ page }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div></div>
  <div class="d-flex align-items-center customBtn" *ngIf="pagination && pagination.maxPages > 1">
    <ng-container
      *ngTemplateOutlet="
        button;
        context: {
          icon: 'keyboard_arrow_left',
          incr: -1,
          disabled: pageIndex === 0
        }
      ">
    </ng-container>

    <ng-container *ngFor="let page of pagination.pages; trackBy: trackByFn">
      <button mat-raised-button class="ms-2" [ngClass]="page === pagination.index + 1 ? 'mat-primary' : 'mat-basic'" (click)="navigateToPage(page - 1)">
        {{ page }}
      </button>
    </ng-container>

    <ng-container
      *ngTemplateOutlet="
        button;
        context: {
          icon: 'keyboard_arrow_right',
          incr: 1,
          disabled: length !== null && (pageIndex + 1) * pageSize >= length
        }
      ">
    </ng-container>
  </div>
</div>

<ng-template #button let-icon="icon" let-incr="incr" let-disabled="disabled">
  <button
    mat-icon-button
    class="ms-2"
    [ngClass]="{
    'mat-color-gray-dark' : !disabled,
    'me-2': icon === 'keyboard_arrow_left',
    'ms-3': icon === 'keyboard_arrow_right',
  }"
    (click)="incrementPage(incr)"
    [disabled]="disabled">
    <mat-icon class="md-icon-20">{{ icon }}</mat-icon>
  </button>
</ng-template>
