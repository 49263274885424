import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPhoneMask]'
})
export class PhoneMaskDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) 
  onInputChange(event: any) {
    const initalValue = this.el.nativeElement.value;
    this.el.nativeElement.value = this.transform(initalValue, event.inputType === 'deleteContentBackward');
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  transform(value: string, backspace: boolean): string {
    let newVal = value.replace(/\D/g, '');
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = `(${newVal})`;
    } else if (newVal.length <= 6) {
      newVal = `(${newVal.slice(0, 3)}) ${newVal.slice(3)}`;
    } else if (newVal.length <= 10) {
      newVal = `(${newVal.slice(0, 3)}) ${newVal.slice(3, 6)}-${newVal.slice(6)}`;
    } else {
      newVal = `(${newVal.slice(0, 3)}) ${newVal.slice(3, 6)}-${newVal.slice(6, 10)}`;
    }
    return newVal;
  }
}
