<ng-container *ngIf="{ value: uploadResult$ | async } as uploadResult">
  <div mat-dialog-title>{{_data.dialog?.title}}</div>

  <div mat-dialog-content class="scroll-y">

    <ngx-dropzone [multiple]="!_data.item.profileRef" [accept]="fileFormats + (!_data.item.profileRef ? ', .zip' : '')"
      [maxFileSize]="100 * 1024 * 1024"
      [disabled]="!!uploadResult.value && uploadResult.value.uploaded + uploadResult.value.failed < uploadResult.value.progress.length"
      (change)="onSelect($event)">
      <ngx-dropzone-label>
        <mat-icon class="large">cloud_upload</mat-icon>

        <ng-container *ngIf="!uploadResult.value">
          <div>
            Drop {{!_data.item.profileRef ? 'files' : 'file'}} here or click to upload.
          </div>
          <div class="hint mat-small">
            Supported file formats: {{fileFormats}}{{!_data.item.profileRef ? ', .zip' : ''}}.
            {{!_data.item.profileRef ? 'Files' : 'File'}} should be less than 100 MB.
          </div>
        </ng-container>

        <ng-container *ngIf="uploadResult.value">
          <mat-progress-bar style="width: 300px" mode="determinate"
            [value]="(uploadResult.value.progress | average) * 100" *ngIf="uploadResult.value.progress">
          </mat-progress-bar>

          <div class="hint mat-small mat-color-accent-dark" fxLayout="row" *ngIf="uploadResult.value.uploaded > 0">
            <mat-icon class="me-2">done</mat-icon>
            <span fxFlex="grow" fxLayoutAlign="start center">
              {{uploadResult.value.uploaded}} file(s) uploaded successfully.
            </span>
          </div>
          <div class="hint mat-small mat-color-red-dark" fxLayout="row" *ngIf="uploadResult.value.tooLarge > 0">
            <mat-icon class="me-2">clear</mat-icon>
            <span fxFlex="grow" fxLayoutAlign="start center">
              {{uploadResult.value.tooLarge}} file(s) are too big.
            </span>
          </div>
          <div class="hint mat-small mat-color-red-dark" fxLayout="row"
            *ngIf="uploadResult.value.failed - uploadResult.value.tooLarge > 0">
            <mat-icon class="me-2">clear</mat-icon>
            <span fxFlex="grow" fxLayoutAlign="start center">
              {{uploadResult.value.failed - uploadResult.value.tooLarge}} file(s) failed to upload.
            </span>
          </div>
        </ng-container>

      </ngx-dropzone-label>
    </ngx-dropzone>

  </div>

  <div mat-dialog-actions *ngIf="!_data.dialog?.disableClose">
    <div class="m-auto"></div>
    <button mat-button (click)="onNoClick()">Close</button>
  </div>
</ng-container>