import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, switchMap, startWith } from 'rxjs/operators';

import { successData, tapSuccess } from 'src/app/utils/rxjs-operators';

import { ApiService, DataTable } from './../api.service';
import { XrefService } from './xref.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService implements OnDestroy {
  private destroy$ = new Subject<void>();

  sidebarShow$ = new Subject<void>();
  sidebarClose$ = new Subject<void>();

  filters = () => this.xref.get<Filter[]>({
    path: 'task-filters'
  }).pipe(
    takeUntil(this.destroy$)
  );

  public refreshTasks$ = new BehaviorSubject<any>(null);
  get = (filterId: number, profileRef?: string, pagination = {}) => this.refreshTasks$.pipe(
    startWith(null),
    switchMap(() => this.api.get<DataTable<Task>>({
      path: 'tasks',
      params: { filterId, profileRef, ...pagination }
    })),
    takeUntil(this.destroy$)
  );

  getCounts = () => this.api.get<Record<string, string>>({
    path: 'tasks/counts'
  }).pipe(
    takeUntil(this.destroy$)
  );

  save = (task: Task) => this.api.post<void>({
    path: 'tasks',
    body: task
  }).pipe(
    tapSuccess(() => this.refreshTasks$.next(true)),
    takeUntil(this.destroy$)
  );

  showSidebar() {
    this.sidebarShow$.next();
  }

  closeSidebar() {
    this.sidebarClose$.next();
  }

  getTasks = (profileRef: string, pagination = {}, filterId = 1000) => this.api.get<DataTable<TaskItem>>({
    path: 'tasks',
    params: { profileRef, filterId, ...pagination }
  }).pipe(
    successData(),
    takeUntil(this.destroy$)
  );

  saveTask = (task: TaskBody) => this.api.post<void>({
    path: 'tasks',
    body: task
  }).pipe(
    tapSuccess(() => this.refreshTasks$.next(true)),
    successData(),
    takeUntil(this.destroy$)
  );

  getTask = (taskId?: number) => this.api.get<any[]>({
    path: 'tasks/jobs',
    params: { taskId }
  }).pipe(
    // mapSuccessData (x=>x.dataTable[0]),
    successData(),
    takeUntil(this.destroy$)
  );

  constructor(
    private api: ApiService,
    private xref: XrefService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface Task {
  taskId: number;
  title: string;
  note: string;
  assignToId: number;
  assignToName: string;
  profileId: number;
  profileRef: string;
  candidateDisplayName: string;
  jobId: number;
  jobTitle: string;
  companyId: number;
  companyName: string;
  startDateTime: string; //////////////////// Convert to epoch
  taskDueDateEpoch: number; //////////////////// Remove epoch from label
  candidateThumbUrl: string;
  recruiterThumbUrl: string;
  isActive: 0 | 1;
  isCompleted: 0 | 1;
  isOwner: 0 | 1;
}

export interface Filter {
  filterId: number;
  filterDesc: string;
}

export interface TaskItem {
  taskId: number
  assignToId: number
  assignToName: string
  title: string
  startDateTime: any
  taskDueDateEpoch: number
  profileId: number
  profileRef: string
  candidateDisplayName: string
  isComplete: number
  note: string
  shortNote: string
  jobPostingId: number
  jobTitle: string
  companyId: any
  companyName: any
  isOwner: number;
  show: boolean;
  isConfidential: number;
}

export interface TaskBody {
  jobs?: number[];
  assignToId: number;
  companyId?: number;

  profileRef: string;
  title?: string | null;
  note?: string | null;
  taskDueDateEpoch: number;
  isCompleted: number;
  isActive: number;
  taskId?: number;
  isConfidential: number;
}