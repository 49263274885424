<button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="candidatesMenu"
  (menuOpened)="recruiterSearchControl.setValue(null); groupSearchControl.setValue(null);" *ngIf="items.length > 0">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #candidatesMenu backdropClass="shade" yPosition="below">
  <!-- <button mat-menu-item [matMenuTriggerFor]="assignEmployeeMenu">
    <mat-icon class="mat-color-gray-dark">people</mat-icon>
    <span>Assign to</span>
  </button>

  <mat-divider></mat-divider>
  <button mat-menu-item [matMenuTriggerFor]="assignGroupMenu">
    <mat-icon class="mat-color-gray-dark">group_work</mat-icon>
    <span>Assign to Group</span>
  </button> -->

  <ng-container *ngIf="items.length === 1">
    <button mat-menu-item (click)="addNote(items)">
      <mat-icon class="mat-color-gray-dark">sticky_note_2</mat-icon>
      <span>Add Note</span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngIf="items.length === 1">
    <button mat-menu-item (click)="addTask(items)">
      <mat-icon class="mat-color-gray-dark">notifications</mat-icon>
      <span>Add Task</span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngIf="context.latest.candidateMenu">
    <button mat-menu-item>
      <mat-icon class="mat-color-gray-dark">email</mat-icon>
      <span>Send Email</span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngIf="context.latest.candidateMenu">
    <button mat-menu-item>
      <mat-icon class="mat-color-gray-dark">contact_phone</mat-icon>
      <span>Request Contact Info</span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container
    *ngIf="context.latest.candidateMenu === 'results' && (items | filterBy: ['isShortListed']: '0').length > 0">
    <button mat-menu-item (click)="addShortList(items, '1')">
      <mat-icon class="mat-color-gray-dark">playlist_add</mat-icon>
      <span>Add to Short List</span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngIf="context.latest.candidateMenu && (items | filterBy: ['isShortListed']: '1').length > 0">
    <button mat-menu-item (click)="addShortList(items, '0')">
      <mat-icon class="mat-color-gray-dark">clear_all</mat-icon>
      <span>Remove from Short List</span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>

  <!-- <ng-container *ngIf="(items | filterBy: ['watchListedFlag']: '0').length > 0">
    <button mat-menu-item (click)="addWatchList(items)">
      <mat-icon class="mat-color-gray-dark">star_outline</mat-icon>
      <span>Add to Watch List</span>
    </button>
    <mat-divider></mat-divider>
  </ng-container> -->

  <ng-container
    *ngIf="context.latest.candidateMenu === 'results' && context.latest.jobId && (items | filterBy: ['isActive']: '0').length > 0">
    <button mat-menu-item (click)="saveCandidates(items, { jobId: context.latest.jobId, isActive: '1' })">
      <mat-icon class="mat-color-gray-dark">clear</mat-icon>
      <span>Add to Search</span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container
    *ngIf="context.latest.candidateMenu === 'results' && context.latest.jobId && (items | filterBy: ['isActive']: '1').length > 0">
    <button mat-menu-item (click)="saveCandidates(items, { jobId: context.latest.jobId, isActive: '0' })">
      <mat-icon class="mat-color-gray-dark">clear</mat-icon>
      <span>Remove from Search</span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngIf="items.length === 1">
    <button mat-menu-item (click)="uploadResumes(items)">
      <mat-icon class="mat-color-gray-dark">cloud_upload</mat-icon>
      <span>Replace Resume</span>
    </button>
    <mat-divider></mat-divider>
  </ng-container>

  <ng-container *ngIf="context.latest.candidateMenu">
    <button mat-menu-item (click)="addRestrictedList(items)">
      <mat-icon class="mat-color-gray-dark">cancel</mat-icon>
      <span>Remove from All Searches</span>
    </button>
  </ng-container>
</mat-menu>

<!-- <mat-menu #assignEmployeeMenu backdropClass="shade" yPosition="below" class="mh-300px">
  <mat-form-field class="w-100 no-underline"  (click)="$event.stopPropagation();">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Search for recruiter</mat-label>
    <input [formControl]="recruiterSearchControl" type="text" matInput />
  </mat-form-field>

  <mat-divider></mat-divider>

  <button mat-menu-item [disableRipple]="true" (click)="saveCandidates(items, { userId: recruiter.value })"
    *ngFor="let recruiter of (recruiters$ | async)">
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="ellipsis">{{recruiter?.text}}</span>
    </div>
  </button>
</mat-menu>

<mat-menu #assignGroupMenu backdropClass="shade" yPosition="below" class="mh-300px">
  <mat-form-field class="w-100 no-underline"  (click)="$event.stopPropagation();">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Search for group</mat-label>
    <input [formControl]="groupSearchControl" type="text" matInput />
  </mat-form-field>

  <mat-divider></mat-divider>

  <button mat-menu-item [disableRipple]="true" (click)="saveCandidates(items, { groupId: group.value })"
    *ngFor="let group of (groups$ | async)">
    <div fxFlex="grow" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="ellipsis">{{group?.text}}</span>
    </div>
  </button>
</mat-menu> -->

<ng-template #confirmTemplate let-data>
  <div mat-dialog-title>{{data.dialog.title}}</div>

  <div mat-dialog-content class="scroll-y">
    Are you sure you want to permanently add
    {{data.item.candidates.length > 1 ? 'these candidates' : 'this candidate'}}
    to the restricted list?
  </div>

  <div mat-dialog-actions>
    <div class="m-auto"></div>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true">Confirm</button>
  </div>
</ng-template>