import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

import { DateTimePickerModule } from 'src/app/components/date-time-picker/date-time-picker.module';
import { InputSelectModule } from 'src/app/components/input-select/input-select.module';
import { InputTextModule } from 'src/app/components/input-text/input-text.module';

import { TaskDialogComponent } from './pr-task-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatInputModule,

    DateTimePickerModule,
    InputSelectModule,
    InputTextModule
  ],
  declarations: [TaskDialogComponent],
  exports: [TaskDialogComponent]
})
export class TaskDialogModule { }
