import { Component } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { ProgressService } from 'src/app/services/progress.service';

@Component({
  selector: 'app-header-bar',
  templateUrl: './pr-header-bar.component.html',
  styleUrls: ['./pr-header-bar.component.scss']
})
export class HeaderBarComponent {

  constructor(
    public auth: AuthService,
    public progress: ProgressService
  ) { }

}
