// https://material.angular.io/guide/creating-a-custom-form-field-control#implementing-the-methods-and-properties-of-matformfieldcontrol
import { Component, OnDestroy, Input, ViewChild, ElementRef, HostBinding, Optional, Self, DoCheck } from '@angular/core';
import { Subject, Observable, of, merge } from 'rxjs';
import { takeUntil, debounceTime, switchMap, startWith, distinctUntilChanged, map, share } from 'rxjs/operators';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl, FormBuilder } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';

import { ApiResult, Item } from 'src/app/services/api.service';

@Component({
  selector: 'app-input-select-control',
  templateUrl: './input-select-control.component.html',
  styleUrls: ['./input-select-control.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: InputSelectControlComponent }]
})
export class InputSelectControlComponent<T> implements OnDestroy, MatFormFieldControl<T>, DoCheck {
  private destroy$ = new Subject<void>();

  static nextId = 0;

  @Input() api?: ((x: string) => Observable<ApiResult<Item<T>[]>>);
  @Input() clearOnSelect = false;

  _value?: T;
  _text?: string;

  stateChanges = new Subject<void>();
  @HostBinding() id = `app-input-select-control-${InputSelectControlComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  focused = false;
  private _placeholder?: string;
  private _required = false;
  private _disabled = false;
  controlType = 'app-input-select-control';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_: any) => { };
  onTouched = () => { };

  get errorState(): boolean {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  @Input()
  set value(value: T) {
    if (value !== this._value) {
      this._value = value;
      this._text = '';
      this.stateChanges.next();
    }
  }

  @Input()
  set text(text: string | null) {
    this._text = text || '';
  }

  @Input()
  get placeholder() {
    return this._placeholder || '';
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    return !this._value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(value) {
    this._required = !!value;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = !!value;
    this.stateChanges.next();
  }

  searchText$ = new Subject<string>();
  clearItems$ = new Subject<void>();
  items$ = merge(
    this.searchText$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith(''),
      switchMap(searchValue =>
        this.api?.(searchValue).pipe(
          switchMap(result => {
            if (result.success && result.data.length === 0 && this.api) {
              return this.api('');
            }

            return of(result);
          })
        ) || of(null)
      )
    ),
    this.clearItems$.pipe(
      map(() => null)
    )
  ).pipe(
    share(),
    takeUntil(this.destroy$)
  );

  @ViewChild('inputRef', { static: false }) inputRef?: ElementRef<HTMLInputElement>;

  constructor(
    fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,

    @Optional() @Self() public ngControl: NgControl
  ) {
    fm.monitor(elRef.nativeElement, true).pipe(
      takeUntil(this.destroy$)
    ).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    event.stopPropagation();

    if (this.inputRef && event.target !== this.inputRef?.nativeElement) {
      this.inputRef.nativeElement.focus();
    }
  }

  writeValue(val: T): void {
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngDoCheck() {
    this.stateChanges.next();
  }

  setItem(item: Item<T>) {
    this.onChange(item.value);

    if (this.clearOnSelect) {
      if (this.inputRef) this.inputRef.nativeElement.value = '';
    } else {
      this._value = item.value;
      this._text = item.text;
    }

    if (this.inputRef) this.inputRef.nativeElement.blur();
  }

  onTextInput(textInputRef: string) {
    // if the text entered into the search input is empty
    if (!textInputRef) {
      const emptyItem: Item<any, any> = {
        value: '',
        text: ''
      };

      // set the selected dropdown value/text to also be empty
      this.setItem(emptyItem);
    }

    this.searchText$.next(textInputRef);
  }

  displayFn = (item: Item<T>) => item ? item.text : '';

  focus() {
    this.searchText$.next('');
  }

  blur(event: FocusEvent) {
    if (event.relatedTarget) return;

    if (this.inputRef) this.inputRef.nativeElement.value = this._text || '';
    this.clearItems$.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stateChanges.complete();
  }
}