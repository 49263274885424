import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, of, share, switchMap, takeUntil } from 'rxjs';
import { ApiService, DataTable } from 'src/app/services/api.service';
import { CandidatesService } from 'src/app/services/api/candidates.service';
import { CompaniesService } from 'src/app/services/api/companies.service';
import { EmployeesService } from 'src/app/services/api/employees.service';
import { JobPostingHeader, JobPostingsService } from 'src/app/services/api/job-postings.service';
import { TaskBody, TaskItem, TasksService } from 'src/app/services/api/tasks.service';
import { AuthService } from 'src/app/services/auth.service';
import { ContextService } from 'src/app/services/context.service';
import { mapSuccessData } from 'src/app/utils/rxjs-operators';
@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AddTaskComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();


  editMode: any;
  viewMode: any;

  jobs: any[] = [];

  addTaskForm = this.fb.group({
    title: new FormControl<string>('', Validators.required),
    task: new FormControl<string>('', Validators.required),
    isConfidential: new FormControl<boolean>(false, Validators.required),
    createdDate: new FormControl<Date>(new Date()),
    show: new FormControl<boolean>(false),
    isComplete: new FormControl<boolean>(false),
    assignedToId: new FormControl<any>(null),
    companyId: new FormControl<any>(null),
    profileRef: new FormControl<any>(null)
  }
  );

  controlTypes = {

    // companies: {
    //   name: 'Company',
    //   xref: (   ) => (searchValue?: string) => of(null).pipe(
    //     switchMap(() => this.xref.get<DataTable<Company>>({
    //       path: 'companies',
    //       params: (searchValue || '') ? { searchValue } :   {context: 'jobs'}
    //     })),
    //     mapSuccessData(data => data.dataTable),
    //     share(),
    //     takeUntil(this.destroy$)
    //   ),
    //   valueKey: 'companyId',
    //   textKey: 'companyName'
    // } as any ,
    jobs: {
      name: 'Job',
      xref: () => (searchValue?: string) => of(null).pipe(
        switchMap(() => this.api.post<DataTable<JobPostingHeader>>({
          path: 'job-postings',
          body: (searchValue || '') ? { searchValue, viewId: 1000 } : { viewId: 1000 }
        })),
        mapSuccessData(data => data.dataTable),
        share(),
        takeUntil(this.destroy$)
      ),
      valueKey: 'jobId',
      textKey: 'jobTitle'
    } as any
    // candidates: {
    //   name: 'Candidate', 
    //   xref:  (   ) => (searchValue?: string) => of(null).pipe(
    //     switchMap(() => this.api.post<DataTable<any>>({
    //       path: 'candidates',
    //       body: (searchValue || '') ? { searchValue } : {}
    //     })),
    //     mapSuccessData(data => data.dataTable),
    //     share(),
    //     takeUntil(this.destroy$)
    //   ),
    //   valueKey: 'profileRef',
    //   textKey: 'displayName'
    // } as any,


  };


  candidate: any;


  searchRecruiter = (searchValue?: string) => this.employees.search(searchValue).pipe(
    mapSuccessData(data => data.map(x => ({ value: x.userId.toString(), text: x.nameDisplay }))),
    takeUntil(this.destroy$)
  );

  searchCandidate = (searchValue?: string) => this.candidatesService.search(searchValue).pipe(
    mapSuccessData(data => data.map(x => ({ value: x.profileRef.toString(), text: x.displayName }))),
    takeUntil(this.destroy$)
  );

  searchCompany = (searchValue?: string) => this.companiesService.searchCompanies(searchValue).pipe(
    mapSuccessData(data => data.dataTable.map(x => ({ value: x.companyId.toString(), text: x.companyName }))),
    takeUntil(this.destroy$)
  );

  companyText? = '';
  candidateText? = '';
  assignToText? = '';
  task?: TaskItem;

  constructor(
    private fb: FormBuilder,

    private taskService: TasksService,
    private api: ApiService,
    private jobPostings: JobPostingsService,

    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddTaskComponent>,
    private employees: EmployeesService,
    private candidatesService: CandidatesService,
    private companiesService: CompaniesService,
    public auth: AuthService,
    public context: ContextService

  ) {

  }

  ngOnInit() {

    this.candidate = this.data.candidate;
    this.task = this.data.task;
    this.editMode = this.data.editMode;
    this.viewMode = this.data.viewMode;

    if (!this.editMode && !this.viewMode) {
      this.jobs.push({ jobId: this.context.latest?.jobId, jobTitle: this.context.latest?.jobTitle });
      this.addTaskForm.patchValue({
        profileRef: this.data.candidate?.profileRef,
        companyId: this.context.latest?.companyId,
        assignedToId: this.addTaskForm.value.assignedToId
      });

      this.candidateText = this.data.candidate.displayName;
      this.companyText = this.context.latest?.companyName;
      this.assignToText = this.auth.userInfo?.firstName + ' ' + this.auth.userInfo?.lastName;

    }

    else {
      this.taskService.getTask(this.task?.taskId).pipe(takeUntil(this.destroy$)).subscribe(
        (taskJobs: any) => {

          if (this.task) {

            this.addTaskForm.patchValue({
              title: this.task?.title,
              task: this.task?.note,
              isConfidential: this.task?.isConfidential === 1,
              isComplete: this.task?.isComplete === 1,
              createdDate: new Date(this.task.taskDueDateEpoch),
              profileRef: this.task?.profileRef,
              companyId: this.task?.companyId,
              assignedToId: this.task?.assignToId
            });
            this.jobs = taskJobs;
            this.candidateText = this.task?.candidateDisplayName;
            this.companyText = this.task?.companyName;
            this.assignToText = this.task?.assignToName;
          }
        }
      );

    }
  }


  submit() {
    const body: TaskBody = {
      title: this.addTaskForm.value.title,
      note: this.addTaskForm.value.task,
      isActive: 1,
      taskDueDateEpoch: Math.round(this.addTaskForm.value.createdDate?.getTime() || new Date().getTime() / 1000),
      taskId: this.task?.taskId,
      isConfidential: this.addTaskForm.value.isConfidential ? 1 : 0,
      companyId: Number(this.addTaskForm.value.companyId),
      jobs: this.jobs.map(x => Number(x.jobId)),
      profileRef: this.addTaskForm.value.profileRef,
      isCompleted: this.addTaskForm.value.isComplete ? 1 : 0,
      assignToId: this.addTaskForm.value.assignedToId
    };
    this.taskService.saveTask(body).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
    });

  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {

    this.dialogRef.close();
  }


  select(control: string, $event: any) {
    this.addTaskForm.get(control)?.setValue($event);
  }

}
