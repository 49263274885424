<div mat-dialog-title>{{_data.dialog?.title}}</div>

<div mat-dialog-content fxLayout="column" fxFlex="grow" class="h-0 scroll-y" [formGroup]="form">

  <div fxLayout="row" *ngIf="_data.item.isOwner === 1">
    <span class="no-wrap m-auto" style="width: 40%">Title:</span>

    <mat-form-field class="w-100 fix-infix" [hideRequiredMarker]="true">
      <input #input matInput (input)="_data.item.noteTitle = input.value" formControlName="title">
      <mat-error *ngIf="form.controls.title.hasError('required')">Required</mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" *ngIf="_data.item.isOwner === 1">
    <app-input-text class="w-100" label="Description" [multiline]="true" [(value)]="_data.item.note">
    </app-input-text>
  </div>

  <div fxLayout="row" *ngIf="_data.item.isOwner !== 1 && _data.item.note && _data.item.note.length > 0">
    <app-input-text class="w-100" label="Description" [multiline]="true" [disabled]="true" [(value)]="_data.item.note">
    </app-input-text>
  </div>

  <ng-container *ngIf="noteTypes$ | async as noteTypes">
    <div fxLayout="row" *ngIf="noteTypes.success">
      <span class="no-wrap m-auto" style="width: 40%">Category:</span>

      <mat-form-field class="w-100">
        <mat-select [(value)]="_data.item.noteTypeId" [disabled]="_data.item.isOwner !== 1">
          <mat-option [value]="item.value" *ngFor="let item of noteTypes.data">
            {{item.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <div fxLayout="row" *ngIf="showOptions.taskDueDateEpoch">
    <span class="no-wrap m-auto" style="width: 40%">Due Date:</span>

    <app-date-time-picker class="w-100" iconPrefix="add_alarm" [disabled]="_data.item.isOwner !== 1"
      [(value)]="_data.item.taskDueDateEpoch">
    </app-date-time-picker>
  </div>

  <div fxLayout="row" *ngIf="showOptions.profileRef">
    <span class="no-wrap m-auto" style="width: 40%">Candidate:</span>

    <app-input-select class="w-100" iconPrefix="person" iconSuffix="search" [api]="searchCandidate"
      [disabled]="_data.item.isOwner !== 1" [(value)]="_data.item.profileRef" [(text)]="_data.item[textKey.profileRef]">
    </app-input-select>
  </div>

  <div fxLayout="row" *ngIf="showOptions.companyId">
    <span class="no-wrap m-auto" style="width: 40%">Company:</span>

    <app-input-select class="w-100" iconPrefix="business" iconSuffix="search" [api]="searchCompany"
      [disabled]="_data.item.isOwner !== 1" [(value)]="_data.item.companyId" [(text)]="_data.item[textKey.companyId]">
    </app-input-select>
  </div>

  <div fxLayout="row" *ngIf="showOptions.jobId">
    <span class="no-wrap m-auto" style="width: 40%">Job:</span>

    <app-input-select class="w-100" iconPrefix="work" iconSuffix="search" [api]="searchJob"
      [disabled]="_data.item.isOwner !== 1" [(value)]="_data.item.jobId" [(text)]="_data.item[textKey.jobId]">
    </app-input-select>
  </div>

</div>

<div mat-dialog-content fxLayout="row" fxLayoutAlign="start center" class="pt-4" *ngIf="_data.item.isOwner === 1">

  <div fxLayout="row" class="mt-3 mb-1 pb-1">
    <span class="no-wrap m-auto pe-2">Tags:</span>

    <div class="w-100" fxLayout="row">
      <mat-chip-listbox class="ms-2">
        <mat-chip-option color="primary" [selected]="showOptions.profileRef" (click)="toggleKey('profileRef')">
          Candidate
        </mat-chip-option>
      </mat-chip-listbox>
      <mat-chip-listbox class="ms-2">
        <mat-chip-option color="primary" [selected]="showOptions.companyId" (click)="toggleKey('companyId')">
          Company
        </mat-chip-option>
      </mat-chip-listbox>
      <mat-chip-listbox class="ms-2">
        <mat-chip-option color="primary" [selected]="showOptions.jobId" (click)="toggleKey('jobId')">
          Job
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>

</div>

<div mat-dialog-actions>
  <button mat-button (click)="toggleKey('taskDueDateEpoch')"
    *ngIf="_data.item.isOwner === 1 && !showOptions.taskDueDateEpoch">
    <mat-icon class="px-2">alarm_add</mat-icon>
    <span>Create Task</span>
  </button>
  <div class="m-auto"></div>
  <button mat-button (click)="close()" *ngIf="_data.item.isOwner !== 1">Close</button>
  <button mat-button (click)="close()" *ngIf="_data.item.isOwner === 1">Cancel</button>
  <button mat-button (click)="submit(_data.item)" *ngIf="_data.item.isOwner === 1">Save Note</button>
</div>