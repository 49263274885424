import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, of, share, startWith, switchMap, takeUntil } from 'rxjs';
import { CandidatesService } from 'src/app/services/api/candidates.service';
import { ProgressStages, XrefService } from 'src/app/services/api/xref.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { createSuccessResult } from 'src/app/utils/api-helpers';
import { successData } from 'src/app/utils/rxjs-operators';

@Component({
  selector: 'app-change-stage-modal',
  templateUrl: './change-stage-modal.component.html',
  styleUrls: ['./change-stage-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangeStageModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  form = this.fb.group(
    {
      stage: this.fb.group({})
    },
    {
      validators: [OneSelected('stage')]
    }
  );

  progressStages$ = this.xrefService
    .get<ProgressStages[]>({
      path: 'progress-stages',
      params: { context: this.data.context }
    })
    .pipe(successData(), takeUntil(this.destroy$));

  submitted = false;
  loading = false;
  counts: number;
  selectedStage = '';
  successMessage = '';
  candidateStageList: ProgressStages[] = [];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ChangeStageModalComponent>,
    private snackBar: MatSnackBar,

    public xrefService: XrefService,
    public candidatesService: CandidatesService,
    private refreshSvc: RefreshService
  ) {
    this.counts = this.data?.selectedCandidates.length;
  }

  ngOnInit() {
    this.progressStages$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.candidateStageList = data;
      this.candidateStageList.forEach(element => {
        this.stage.addControl(element.stageId?.toString(), new FormControl(false));
      });
    });
  }

  get stage() {
    return this.form.get('stage') as FormGroup;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkSelected(id: string, name: string) {
    this.selectedStage = name;
    if (this.stage.get(id)?.value)
      Object.keys(this.stage.controls).forEach(key => {
        if (id !== key) this.stage.get(key)?.setValue(false, { emitEvent: false });
      });
  }

  submit() {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;
      const restrictedCandidate = this.data.selectedCandidates.find((candidate: any) => candidate.isRestricted);
      const stageId = Object.keys(this.form.controls.stage.controls).find(key => this.form.controls.stage.get(key)?.value === true);
      const body: any = {
        jobId: this.data.jobId,
        stageId: Number(stageId),
        candidates: this.data.selectedCandidates.map((x: any) => ({ profileRef: x.profileRef })),
        sourcedMethodId: 2000
      };

      of(null)
        .pipe(
          switchMap(() => this.candidatesService.changeStage(body)),
          switchMap(result => {
            if (result.finished) {
              this.loading = false;

              if (result.success) {
                const isSingleCandidate = this.counts === 1;
                const candidateName = isSingleCandidate ? this.data.selectedCandidates[0].candidateName : '';
                const baseMessage = isSingleCandidate ? `${candidateName} was added to stage: ${this.selectedStage}` : `${this.counts} candidates were added to stage: ${this.selectedStage}`;

                const restrictedMessage = restrictedCandidate
                  ? isSingleCandidate
                    ? `. ${candidateName} is restricted and will receive any communication sent. Please verify before proceeding.`
                    : '. Some candidates are restricted and will receive any communication sent. Please verify before proceeding.'
                  : '';

                this.successMessage = baseMessage + restrictedMessage;
              } else {
                this.successMessage = 'An error occurred, please try again later';
              }

              this.dialogRef.close();

              this.refreshSvc.setRefreshStagesObs(true);
              this.snackBar.open(this.successMessage, 'Close', { duration: 5 * 1000 });
            }

            return of(result);
          }),
          share(),
          startWith(createSuccessResult(undefined)),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }
}

function OneSelected(controlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control = formGroup.get(controlName);
    if (Object.values(control?.value).findIndex(x => x === true) === -1) {
      control?.setErrors({ mustselect: true });
    } else {
      control?.setErrors(null);
    }
    return null;
  };
}
