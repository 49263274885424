export function nullToUndefined(obj: any) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null) {
      obj[key] = undefined;
    }
  });
  return obj;
}

export function capitalizeWords(str: string) {
  if (!str) return str;

  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
