import { EmailsService, emailList } from 'src/app/services/api/emails.service';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subject, filter, of, share, startWith, switchMap, takeUntil } from 'rxjs';
import { CandidatesService, bulkAddToCampaign } from 'src/app/services/api/candidates.service';
import { JobPostingsService, PriorityCounts, stageCount } from 'src/app/services/api/job-postings.service';

import { Campaigntype, CandidatePrioritization, ProgressStages, XrefService } from 'src/app/services/api/xref.service';
import { RefreshService } from 'src/app/services/refresh.service';

import { createSuccessResult } from 'src/app/utils/api-helpers';
import { successData, tapFinished, tapLoading } from 'src/app/utils/rxjs-operators';

@Component({
  selector: 'app-add-to-campaign-modal',
  templateUrl: './add-to-campaign-modal.component.html',
  styleUrls: ['./add-to-campaign-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddToCampaignModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  form = this.fb.group(
    {
      Add: this.fb.group({
        CurrentSelection: [{ value: this.data?.selectedCandidates.length > 0, disabled: this.data?.selectedCandidates.length === 0 }],
        Priority: [],
        priorityIds: new FormControl<number[]>([]),
        allIndicator: []
      })
    },
    {
      validators: [OneSelected('Add'), checkRequired('Priority', 'priorityIds')]
    }
  );

  candidatePrioritization$ = this.xrefService.getCandidatePrioritizations().pipe(successData(), takeUntil(this.destroy$));

  candidatesProgressCounts$ = this.jobPostingService.getCandidatesStageeCounts(this.data.jobId).pipe(successData(), takeUntil(this.destroy$));

  priorityCounts$ = this.jobPostingService.getPriorityCounts(this.data.jobId).pipe(successData(), takeUntil(this.destroy$));

  refreshEmailList$ = new BehaviorSubject<any>(this.data?.selectedCandidates.length > 0 ? { selectedCandidates: this.data?.selectedCandidates } : null);

  emailList$ = this.refreshEmailList$.asObservable().pipe(
    filter(body => body),
    switchMap((body: any) => this.emailsService.emailList({ jobId: this.data?.jobId, ...body })),
    tapLoading(() => {
      this.emailListLoading = true;
    }),
    tapFinished(() => {
      this.emailListLoading = false;
    }),
    successData(),
    takeUntil(this.destroy$)
  );

  priorityCounts: PriorityCounts[] = [];
  ProgressCounts: stageCount[] = [];

  priorityList: CandidatePrioritization[] = [];
  ProgressList: ProgressStages[] = [];
  campaigntype: Campaigntype[] = [];
  submitted = false;
  counts = 0;

  successMessage = '';
  selectedCampaign = '';
  loading = false;
  emailListLoading = false;
  emailsList: emailList[] = [];
  emilaListIsEdited = false;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddToCampaignModalComponent>,
    private snackBar: MatSnackBar,

    public xrefService: XrefService,
    public jobPostingService: JobPostingsService,
    public candidatesService: CandidatesService,
    private refreshSvc: RefreshService,
    private emailsService: EmailsService
  ) {}

  ngOnInit() {
    this.candidatePrioritization$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.priorityList = value;
      this.priorityCounts$.pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.priorityCounts = data.priorityCounts.filter(x => this.priorityList.findIndex(i => i.priorityId === x.priorityId) > -1);
      });
    });

    this.form.controls.Add.controls.priorityIds.disable();

    this.candidatesProgressCounts$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.ProgressCounts = data;
    });

    this.form.controls.Add.controls.Priority.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data === true) {
        this.form.controls.Add.controls.priorityIds.enable();
      } else {
        this.form.controls.Add.controls.priorityIds.disable();
      }
    });

    this.form.controls.Add.controls.priorityIds.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data?.length && data.length > 0) {
        this.refreshEmailList$.next({ priorityIds: data });
      }
    });

    this.form.controls.Add.controls.CurrentSelection.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value === true) {
        this.refreshEmailList$.next({ selectedCandidates: this.data?.selectedCandidates });
      }
    });

    this.emailList$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.emilaListIsEdited = false;
      this.emailsList = value;
    });
  }

  get f() {
    return this.form.controls;
  }

  get addFormGroupControls() {
    return this.form.get('Add') as FormGroup;
  }

  checkAddSelected(control: string) {
    if (this.form.controls.Add.get(control)?.value === true) {
      Object.keys(this.form.controls.Add.controls).forEach(key => {
        if (control !== key) this.form.controls.Add.get(key)?.setValue(null, { emitEvent: false });
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submit() {
    this.submitted = true;

    if (!this.form.valid) return;
    this.loading = true;

    const body: bulkAddToCampaign = {
      jobId: this.data.jobId,
      allIndicator: this.addFormGroupControls.get('allIndicator')?.value ? 1 : 0,
      selectedCandidates: this.emilaListIsEdited ? this.emailsList.map(x => x.profileRef) : this.form.controls.Add.value.CurrentSelection ? this.data?.selectedCandidates : [],
      priorityIds: this.form.controls.Add.controls.priorityIds.value ? this.form.controls.Add.controls.priorityIds.value : []
    };

    of(null)
      .pipe(
        switchMap(() => this.candidatesService.addToCampaignBulk(body)),
        switchMap(result => {
          if (result.finished) {
            this.loading = false;
            this.successMessage = result.success
              ? this.emailsList.length + `${this.emailsList.length === 1 ? ' candidate was' : ' candidates were'}` + ' added to campaign '
              : 'An error occured, please try again later';

            this.dialogRef.close();

            this.refreshSvc.setRefreshObs(true);

            this.snackBar.open(this.successMessage, 'Close', { duration: 5 * 1000 });
          }

          return of(result);
        }),
        share(),
        startWith(createSuccessResult(undefined)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onUserRemoved(topping: emailList) {
    this.emilaListIsEdited = true;
    const emails = this.emailsList as any[];
    this.removeFirst(emails, topping);
    this.emailsList = emails; // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
}

function OneSelected(controlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control = formGroup.get(controlName);
    if (Object.values(control?.value).findIndex(x => x === true) === -1) {
      control?.setErrors({ mustselect: true });
    } else {
      control?.setErrors(null);
    }
    return null;
  };
}

function checkRequired(controlName: string, requiredControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control = formGroup.get('Add');

    if (control?.get(controlName)?.value === true && (control?.get(requiredControlName)?.value === null || control?.get(requiredControlName)?.value?.length === 0)) {
      control?.get(requiredControlName)?.setErrors({ required: true });
    } else {
      control?.get(requiredControlName)?.setErrors(null);
    }

    return null;
  };
}
