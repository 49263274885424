import { Component, OnDestroy, Inject } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil, switchMap, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';

import { IntakeService } from 'src/app/services/api/intake.service';

import { DialogData } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-pr-job-desc-dialog',
  templateUrl: './pr-job-desc-dialog.component.html',
  styleUrls: ['./pr-job-desc-dialog.component.scss']
})
export class JobDescDialogComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  fileFormats = '.pdf, .docx, .doc, .rtf, .html';

  upload$ = new Subject<File[]>();
  uploadResult$ = this.upload$.pipe(
    switchMap(files => this.intake.upload(files)),
    tap(result => {
      if (this._data.dialog?.closeAfterSuccess && result.uploaded === result.progress.length) {
        timer(1000)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.dialogRef.close();
          });
      }
    }),
    takeUntil(this.destroy$)
  );

  constructor(
    private intake: IntakeService,

    private dialogRef: MatDialogRef<JobDescDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData<{ profileRef?: string }>
  ) {}

  onSelect(event: NgxDropzoneChangeEvent) {
    if (event.addedFiles.length > 0) {
      this.upload$.next(event.addedFiles);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
