import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class AdminGuard {

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.userInfo$.pipe(
      map(() => {
        if (this.check()) {
          return true;
        }

        const landingPage = this.auth.landingPage;
        void this.router.navigate([landingPage], landingPage.startsWith('/login') ? { queryParams: { returnUrl: state.url } } : {});
        return false;
      })
    );
  }

  check() {
    return this.auth.isAuthenticated && this.auth.isAdmin;
  }
}
