import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//import { EmailActionsComponent } from './email-actions.component';
import { CandidateQuestionnaireComponent } from './candidate-questionnaire/candidate-questionnaire.component';

const routes: Routes = [{
  path: '',
  component: CandidateQuestionnaireComponent,
  data: { preload: true }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmailActionsRoutingModule { }
