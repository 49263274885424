
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

import { BehaviorSubject, Subject, combineLatest, startWith, switchMap, takeUntil } from 'rxjs';

import { Pagination } from 'src/app/services/api.service';
import { TaskBody, TaskItem, TasksService } from 'src/app/services/api/tasks.service';
import { AddTaskComponent } from '../add-task/add-task.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class TasksComponent implements OnInit, OnDestroy, OnChanges {

  @Input() candidate: any;
  @Input() jobId: any;


  currentCandidate$ = new BehaviorSubject<any | undefined>(undefined);

  submitted = false;
  private destroy$ = new Subject<void>();
  tasksList: TaskItem[] = [];

  tasksTablePaginationOffset$ = new BehaviorSubject<any>(0);

  tasks$ = combineLatest([
    this.currentCandidate$,
    this.tasksTablePaginationOffset$.pipe(

      startWith({} as Pagination)
    ),
    this.taskService.refreshTasks$.asObservable()
  ]).pipe(
    switchMap(([candidate, pagination]) => this.taskService.getTasks(candidate.profileRef, pagination)),

    takeUntil(this.destroy$)
  );


  constructor(
    private dialog: MatDialog,
    private taskService: TasksService

  ) {

  }

  ngOnInit() {


    this.tasks$.pipe(takeUntil(this.destroy$)).subscribe(tasks => {

      tasks.dataTable.forEach(x => x.shortNote = x.note?.substring(0, 40));
      this.tasksList = tasks.dataTable;

    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currentCandidate$.next(changes.candidate.currentValue);
  }

  addTaskAction() {


    this.dialog.open(AddTaskComponent, {
      minWidth: '900px', data: {
        jobId: this.jobId,
        candidate: this.candidate

      },
      panelClass: 'task-module'
    });
  }


  showEditTaskForm(taskIndex: number) {


    this.dialog.open(AddTaskComponent, {
      minWidth: '900px', data: {
        jobId: this.jobId,
        candidate: this.candidate,
        editMode: true,
        task: this.tasksList[taskIndex]
      },
      panelClass: 'task-module'
    });

  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changePage(event: PageEvent) {
    this.tasksTablePaginationOffset$.next({ offset: event.pageIndex * event.pageSize, numResults: event.pageSize });
  }

  complete(taskIndex: number, $event: boolean) {

    const body: TaskBody = {
      taskId: this.tasksList[taskIndex].taskId,
      profileRef: this.tasksList[taskIndex].profileRef,
      title: this.tasksList[taskIndex].title,
      note: this.tasksList[taskIndex].note,
      //jobId:  this.tasksList[taskIndex].jobId ,
      isActive: 1,
      taskDueDateEpoch: this.tasksList[taskIndex].taskDueDateEpoch,
      assignToId: this.tasksList[taskIndex].assignToId,
      isCompleted: $event ? 1 : 0,
      isConfidential: this.tasksList[taskIndex].isConfidential

    };

    this.taskService.saveTask(body).pipe(takeUntil(this.destroy$)).subscribe();
  }
  deleteTask(taskIndex: number) {

    const body: TaskBody = {
      taskId: this.tasksList[taskIndex].taskId,
      profileRef: this.tasksList[taskIndex].profileRef,
      title: this.tasksList[taskIndex].title,
      note: this.tasksList[taskIndex].note,
      //jobId:  this.tasksList[taskIndex].jobId ,
      isActive: 0,
      taskDueDateEpoch: this.tasksList[taskIndex].taskDueDateEpoch,
      assignToId: this.tasksList[taskIndex].assignToId,
      isCompleted: this.tasksList[taskIndex].isComplete,
      isConfidential: this.tasksList[taskIndex].isConfidential

    };
    this.taskService.saveTask(body).pipe(takeUntil(this.destroy$)).subscribe();
  }
}
