<div class="notes">
  <div class="d-flex flex-row">
    <div class="w-100 flex-shrink-1">
      <div class="d-flex flex-row mat-background-primary-500">
        <div class="w-50 d-flex align-items-center ps-2">
          <span class="text-white fw-medium">Notes</span>
        </div>
        <div class="w-50 d-flex flex-row justify-content-end align-items-center">
          <button mat-button color="primary" class="me-2 text-white" (click)="addNoteAction()">
            <mat-icon class="md-icon-12">add_circle</mat-icon>
            <span>Add Note</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row">
    <div class="w-100 flex-shrink-1 text-black notes-content bg-white">
      <ng-container *ngIf="{ notes: notes$ | async } as results">
        <ng-container *ngIf="results.notes">
          <ng-container *ngFor="let note of notesList; let i = index">
            <div class="d-flex flex-column single-note">
              <div class="d-flex flex-row">
                <div class="w-50 d-flex flex-column justify-content-center" [matTooltip]="results.notes.dataTable[i].note">
                  <div class="d-flex flex-row">
                    <span class="d-block body" style="font-weight: 500"> {{ results.notes.dataTable[i].noteTitle }} </span>
                  </div>
                  <div class="d-flex flex-row mt-1">
                    <span class="d-block body-sm">
                      {{ results.notes.dataTable[i].shortNote }}
                    </span>
                  </div>
                </div>
                <div class="w-50 d-flex flex-row justify-content-end align-items-center">
                  <div class="body">
                    {{ results.notes.dataTable[i].createdDate }}
                  </div>
                  <div class="ms-3">
                    <button mat-icon-button class="ms-5 action-button" (click)="showEditNoteForm(i)">
                      <mat-icon class="action-icon mat-color-gray-600">edit</mat-icon>
                    </button>
                  </div>
                  <div class="ms-2">
                    <button mat-icon-button class="ms-4 action-button" (click)="deleteNote(i)">
                      <mat-icon class="action-icon mat-color-gray-600">remove_circle</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="d-flex flex-row justify-content-end p-2">
            <app-paginator
              [pageIndex]="results.notes.pagination.offset / results.notes.pagination.numResults"
              [pageSize]="results.notes.pagination.numResults"
              [length]="results.notes.pagination.totalEntries"
              [hideIfSinglePage]="false"
              (page)="changePage($event)">
            </app-paginator>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
