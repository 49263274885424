import { ApiResultError, ApiResultLoading, ApiResultSuccess } from 'src/app/services/api.service';
import { ApiResponse, HttpRequestBase } from 'src/app/services/http.service';

export function createLoadingResult<R extends HttpRequestBase>(request: R = undefined as any): ApiResultLoading {
  return { finished: false as const, success: undefined, request };
}

export function createSuccessResult<T, R extends HttpRequestBase>(result: T, request: R = undefined as any, response: ApiResponse<unknown> = undefined as any): ApiResultSuccess<T> {
  return { finished: true as const, success: true as const, data: result, request, response };
}

export function createErrorResult<R extends HttpRequestBase>(error: Error, request: R = undefined as any, response: ApiResponse<unknown> = undefined as any): ApiResultError {
  return { finished: true as const, success: false as const, error, request, response };
}