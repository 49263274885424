<ng-container *ngIf="candidateStageList.length > 0">
  <div class="change-stage-modal">
    <div class="p-3" mat-dialog-title>
      <div class="w-100 d-flex justify-content-between align-items-center">
        <label class="mat-color-primary-500">Change Stage</label>

        <button mat-dialog-close class="modal-close-icon-btn">
          <mat-icon class="md-icon-14">close</mat-icon>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="mt-1" mat-dialog-content>
      <div class="container">
        <div class="row">
          <div class="col">
            <form [formGroup]="form">
              <section formGroupName="stage">
                <ng-container *ngFor="let stage of candidateStageList | groupByPipe : 'stageGroupId'; let i = index">
                  <label class="mt-2 fw-medium text-primary">
                    {{ stage.value[0]?.stageGroupDesc }}
                  </label>

                  <ng-container *ngFor="let value of stage.value">
                    <div class="d-flex flex-row mt-1 w-100">
                      <mat-checkbox class="flex-grow-1" [formControlName]="value.stageId + ''" (change)="checkSelected(value.stageId + '', value.stageDesc)">
                        {{ value.stageDesc }}
                      </mat-checkbox>
                    </div>
                  </ng-container>

                  <mat-divider class="my-2"></mat-divider>
                </ng-container>
              </section>

              <div class="d-flex flex-row mt-2">
                <mat-error *ngIf="submitted && form.controls.stage.hasError('mustselect')"> * Select one </mat-error>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 border-top">
      <div class="d-flex justify-content-end align-items-center" mat-dialog-actions>
        <button mat-stroked-button color="primary" class="me-3" mat-dialog-close><mat-icon>cancel</mat-icon>Close</button>
        <button mat-flat-button color="primary" [disabled]="!form.valid || loading" (click)="submit()"><mat-icon>check_circle</mat-icon> Apply</button>
      </div>
    </div>
  </div>
</ng-container>
