import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil, map, startWith, switchMap, tap, filter } from 'rxjs/operators';

import { ApiService, ApiUploadResult } from './../api.service';
import { RefreshService } from '../refresh.service';

@Injectable({
  providedIn: 'root'
})
export class ResumesService implements OnDestroy {
  private destroy$ = new Subject<void>();

  sidebar$ = new Subject<void>();

  upload = (files: File[], profileRef?: string, sus?: any, isCandidateQuestionnaire?: boolean) => {
    return combineLatest(
      files.map(file => {
        const body = new FormData();
        body.append('file', file, file.name);
        if (profileRef) {
          body.append('profileRef', profileRef);
        }
        if (sus) {
          body.append('sus', sus);
        }

        if (isCandidateQuestionnaire) {
          return this.api.upload<void>({ path: 'resume/questionnaire-upload', body });
        } else {
          return this.api.upload<void>({ path: 'resume/upload', body });
        }
      })
    ).pipe(
      map(results => {
        const result: ApiUploadResult = {
          progress: results.map(x => x.progress[0]),
          uploaded: results.map(x => x.uploaded).reduce((a, b) => a + b, 0),
          failed: results.map(x => x.failed).reduce((a, b) => a + b, 0),
          tooLarge: results.map(x => x.tooLarge).reduce((a, b) => a + b, 0)
        };
        return result;
      }),
      tap(result => {
        if (profileRef && result.uploaded + result.failed === result.progress.length) {
          this.refreshProfileRef$.next(profileRef);
          this.refreshSvc.setRefreshObs(true);
        }
      })
    );
  };

  public refreshProfileRef$ = new BehaviorSubject<string>('');
  versions = (profileRef: string) =>
    this.refreshProfileRef$.pipe(
      filter(x => x === profileRef),
      startWith(null),
      switchMap(() =>
        this.api.post<{ allowVersions: boolean; versions: FileVersion[] }>({
          path: 'resume/versions',
          body: { profileRef }
        })
      ),
      takeUntil(this.destroy$)
    );

  constructor(private api: ApiService, private refreshSvc: RefreshService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

interface FileVersion {
  fileName: string;
  dateCreated: string;
  downloadToken: string;
}
