<ng-container
  *ngIf="{
    notes: notes$ | async,
    noteFilter: noteFilter$ | async,
    noteFilters: noteFilters$ | async
  } as results">
  <div style="width: 400px" fxLayout="column" fxFlex="grow" *ngIf="sidebar">
    <ng-container *ngTemplateOutlet="contentControl"> </ng-container>
  </div>

  <div class="w-100" fxLayout="column" fxFlex="grow" *ngIf="!sidebar">
    <ng-container *ngTemplateOutlet="contentControl"> </ng-container>
  </div>

  <ng-template #contentControl>
    <div class="w-100 p-3 mat-background-primary mat-color-primary-contrast" fxLayout="row" fxLayoutAlign="start center" *ngIf="header">
      <button mat-icon-button (click)="closeSidebar()">
        <mat-icon>arrow_forward</mat-icon>
      </button>

      <div class="ms-2" fxLayout="column" fxLayoutAlign="center start" fxFlex="grow">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h3 class="m-0 no-wrap">Notes</h3>

          <ng-container *ngIf="results.noteFilters && results.noteFilters.success">
            <button mat-icon-button class="ms-2 default-size" [matMenuTriggerFor]="taskMenu">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>

            <mat-menu #taskMenu>
              <button mat-menu-item *ngFor="let item of results.noteFilters.data" (click)="noteFilter$.next(item)">
                {{ item.filterDesc }}
              </button>
            </mat-menu>
          </ng-container>
        </div>

        <span class="mat-small" *ngIf="results.noteFilter && results.noteFilter.filterDesc"> Showing {{ results.noteFilter.filterDesc }} </span>
      </div>

      <button mat-icon-button (click)="noteDialog('New Note')">
        <mat-icon>add</mat-icon>
      </button>

      <mat-divider></mat-divider>
    </div>

    <div class="w-100 p-3" fxLayout="row" fxLayoutAlign="start center" *ngIf="!header && filter">
      <button mat-icon-button (click)="noteDialog('New Note')">
        <mat-icon>add</mat-icon>
      </button>

      <span class="spacer"></span>

      <span class="mat-small" *ngIf="results.noteFilter && results.noteFilter.filterDesc"> Showing {{ results.noteFilter.filterDesc }} </span>

      <ng-container *ngIf="results.noteFilters && results.noteFilters.success">
        <button mat-icon-button class="ms-2" [matMenuTriggerFor]="taskMenu">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu #taskMenu>
          <button mat-menu-item *ngFor="let item of results.noteFilters.data" (click)="noteFilter$.next(item)">
            {{ item.filterDesc }}
          </button>
        </mat-menu>
      </ng-container>

      <mat-divider></mat-divider>
    </div>

    <ng-container *ngTemplateOutlet="listControl"> </ng-container>
  </ng-template>

  <ng-template #listControl>
    <div class="w-100 h-0 p-relative" fxLayout="column" fxFlex="grow" *ngIf="results.notes">
      <div class="full-shade" *ngIf="!results.notes.finished">
        <mat-spinner color="accent"></mat-spinner>
      </div>

      <ng-container *ngIf="results.notes.success">
        <div class="middle" *ngIf="results.notes.data.dataTable.length === 0">There are no notes</div>

        <ng-container *ngIf="results.notes.data.dataTable.length > 0">
          <div class="scroll-y" fxFlex="grow">
            <ng-container *ngFor="let item of results.notes.data.dataTable">
              <div class="p-3 c-pointer hover-row" fxLayout="row" fxLayoutAlign="start center" (click)="viewNote(item)">
                <div class="w-100" fxLayout="row" fxFlex="grow" fxLayoutAlign="space-between center">
                  <div class="px-3 w-0" fxFlex="grow" fxLayout="column">
                    <span class="my-0 ellipsis" fxFlex="grow">{{ item.noteTitle }}</span>

                    <div class="w-100" fxLayout="row" fxFlex="grow" fxLayoutAlign="space-between center">
                      <span class="hint mat-small pe-4 ellipsis" fxFlex="grow">{{ item.candidateDisplayName }}</span>
                      <span class="hint mat-small no-wrap">{{ item.createdDate | date : 'mediumDate' }}</span>
                    </div>
                  </div>
                </div>

                <button mat-icon-button (click)="noteDialog('Edit Note', item); $event.stopPropagation()" *ngIf="item.isOwner === 1">
                  <mat-icon>edit</mat-icon>
                </button>

                <button mat-icon-button (click)="saveNote(item, { isActive: 0 }); $event.stopPropagation()" *ngIf="item.isOwner === 1">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>

              <mat-divider></mat-divider>
            </ng-container>
          </div>

          <app-paginator
            [pageIndex]="results.notes.data.pagination.offset / results.notes.data.pagination.numResults"
            [pageSize]="results.notes.data.pagination.numResults"
            [length]="results.notes.data.pagination.totalEntries"
            (page)="changePage($event)">
          </app-paginator>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
