import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { Note } from './api/notes.service';

import { NoteDialogComponent } from 'src/app/components/pr-note-dialog/pr-note-dialog.component';
import { TaskDialogComponent } from 'src/app/components/pr-task-dialog/pr-task-dialog.component';
import { ResumesDialogComponent } from 'src/app/components/pr-resumes-dialog/pr-resumes-dialog.component';
import { JobDescDialogComponent } from 'src/app/components/pr-job-desc-dialog/pr-job-desc-dialog.component';
import { NotificationComponent } from '../components/shared/notification/notification.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService implements OnDestroy {
  private destroy$ = new Subject<void>();

  sidebar$ = new Subject<void>();

  uploadResumes(data: DialogData<{ profileRef?: string }>) {
    return this.showDialog(ResumesDialogComponent, data).afterClosed();
  }

  uploadJobDescription(data: DialogData<any>) {
    return this.showDialog(JobDescDialogComponent, data).afterClosed();
  }

  note(data: DialogData<Note>) {
    return this.showDialog(NoteDialogComponent, data).afterClosed();
  }

  task(data: DialogData<any>) {
    return this.showDialog(TaskDialogComponent, data).afterClosed();
  }

  showError() {
    this.dialog.open(NotificationComponent, {
      position: { bottom: '10px', right: '10px' }
    }).afterClosed();
  }
  constructor(
    private dialog: MatDialog
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showDialog<T>(component: ComponentType<any> | TemplateRef<any>, data?: DialogData<T>) {
    return this.dialog.open<ComponentType<any>, DialogData<T>, T>(component, {
      width: data?.dialog?.width || '600px',
      autoFocus: false,
      height: data?.dialog?.height,
      maxHeight: '90vh',
      disableClose: data?.dialog?.disableClose,
      data
    });
  }
}

export interface DialogData<T> {
  dialog?: {
    title: string;
    width?: string;
    height?: string;
    disableClose?: boolean;
    closeAfterSuccess?: boolean;
  };
  item: T;
}