import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { AddTaskComponent } from './add-task/add-task.component';
import { TasksComponent } from './tasks/tasks.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { PaginatorModule } from '../paginator/paginator.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { InputTextModule } from '../input-text/input-text.module';
import { ChipTypeaheadModule } from '../pr-chip-typeahead/pr-chip-typeahead.module'; 
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core'; 
import { InputSelectModule } from '../input-select/input-select.module';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker'; 

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'MM/dd/yyyy' // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'MM/dd/yyyy', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,  
    TextFieldModule, 
    MatInputModule, 
    PaginatorModule,
    MatCheckboxModule,
    MatChipsModule,
    InputTextModule,
    ChipTypeaheadModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    InputSelectModule
  ],
  declarations: [AddTaskComponent, TasksComponent ],
  exports: [AddTaskComponent, TasksComponent ],
  providers: [  
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }]
})
export class TasksModuleModule { }
