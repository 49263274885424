import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from './../api.service';
import { Candidate } from './candidates.service';

@Injectable({
  providedIn: 'root'
})
export class WatchListService {
  get$ = this.api.get<Candidate[]>({
    path: 'recruiter/follow'
  });

  save = (candidates: any[]) =>
    this.api
      .post<void>({
        path: 'recruiter/follow',
        body: { candidates }
      })
      .pipe(map(result => ({ ...result, candidates })));

  constructor(private api: ApiService) {}
}
