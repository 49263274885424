import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatCamelCaseToText' })
export class FormatCamelCaseToTextPipe implements PipeTransform {
  transform(text: string) {
    return text
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      // uppercase the first character
      .replace(/^./, str => { return str.toUpperCase(); });
  }
}