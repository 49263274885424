<ng-container *ngIf="!iframe.isInIframe">
  <app-header-bar *ngIf="auth.isAuthenticated || router.url.substring(1, 6) === 'login'">
    <ng-container center *ngIf="auth.isAuthenticated && auth.seats.coreApp && context.headerIconSet === 'home'">
      <app-omni-search class="w-100"></app-omni-search>
    </ng-container>

    <ng-container right *ngIf="auth.isAuthenticated">
      <!-- <button mat-icon-button>
        <mat-icon>help</mat-icon>
      </button> -->
      <button mat-icon-button matTooltip="Upload Resumes" matTooltipPosition="below" (click)="uploadResumes()" *ngIf="auth.isAuthenticated && auth.seats.coreApp">
        <mat-icon>file_upload</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Notes" matTooltipPosition="below" (click)="showRightSidebar('notes')" *ngIf="auth.isAuthenticated && auth.seats.coreApp">
        <mat-icon>sticky_note_2</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Tasks" matTooltipPosition="below" (click)="showRightSidebar('tasks')" *ngIf="auth.isAuthenticated && auth.seats.coreApp">
        <mat-icon>notifications</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="authMenu">
        <mat-icon>person</mat-icon>
      </button>

      <mat-menu #authMenu yPosition="below">
        <a mat-menu-item routerLink="/profile/settings">Profile</a>
        <a mat-menu-item routerLink="/admin" *ngIf="auth.isAdmin">Admin</a>
        <button mat-menu-item (click)="auth.logout()">
          <span>Logout</span>
        </button>
      </mat-menu>
    </ng-container>
  </app-header-bar>
</ng-container>
<mat-sidenav-container fxFlex="grow" [autosize]="true">
  <mat-sidenav class="shadow" mode="side" opened *ngIf="auth.isAuthenticated && !iframe.isInIframe && !hideSidebar">
    <div fxLayout="column" class="p-0 h-100" style="width: 90px">
      <ng-container *ngTemplateOutlet="navItemsTemplate; context: { hidden: false }"> </ng-container>

      <div fxFlex="grow"></div>

      <ng-container *ngIf="showHiddenNav">
        <ng-container *ngTemplateOutlet="navItemsTemplate; context: { hidden: true }"> </ng-container>
      </ng-container>

      <ng-template #navItemsTemplate let-hidden="hidden">
        <ng-container *ngFor="let item of context.navItems">
          <ng-container *ngIf="item.hidden ? hidden : !hidden || item.hidden">
            <ng-container *ngIf="item.isAdmin ? auth.isAdmin : true">
              <ng-container *ngIf="item.role ? (auth.roles | pick : item.role | values)[0] : true">
                <ng-container *ngIf="item.seat ? (auth.seats | pick : item.seat | values)[0] : true">
                  <ng-container *ngIf="item.isProRank ? auth.isProRank : true">
                    <a class="m-3 text-decoration-none" [routerLink]="item.url" *ngIf="!item.newWindow">
                      <ng-container *ngTemplateOutlet="navItemTemplate; context: { item: item }"> </ng-container>
                    </a>

                    <a class="m-3 text-decoration-none" (click)="!item.newWindow || nav.openWindow(item.url)" *ngIf="item.newWindow">
                      <ng-container *ngTemplateOutlet="navItemTemplate; context: { item: item }"> </ng-container>
                    </a>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>

      <ng-template #navItemTemplate let-item="item">
        <div class="c-pointer" [ngClass]="window.location.pathname.includes(item.url) ? 'mat-color-primary' : 'mat-color-gray-dark'" fxLayout="column" fxLayoutAlign="start center">
          <mat-icon *ngIf="!item.isSvgIcon">{{ item.icon }}</mat-icon>
          <mat-icon [svgIcon]="item.icon" *ngIf="item.isSvgIcon"></mat-icon>
          <span class="text-center" style="font-size: 85%">{{ item.name }}</span>
        </div>
      </ng-template>

      <a class="m-3 text-decoration-none" (click)="showHiddenNav = !showHiddenNav" *ngIf="auth.isProRank && (context.navItems | filterBy : ['hidden'] : true).length > 0">
        <div class="c-pointer mat-color-gray-dark" fxLayout="column" fxLayoutAlign="start center">
          <mat-icon>more_horiz</mat-icon>
          <span style="font-size: 90%" *ngIf="!showHiddenNav">More</span>
          <span style="font-size: 90%" *ngIf="showHiddenNav">Less</span>
        </div>
      </a>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

  <mat-sidenav #rightSidebar position="end" (closedStart)="rightSideBar = ''" *ngIf="auth.isAuthenticated">
    <div class="h-100" *ngIf="rightSideBar === 'candidate'">
      <app-pr-candidate-sidebar></app-pr-candidate-sidebar>
    </div>
    <div class="h-100" *ngIf="rightSideBar === 'notes'">
      <app-pr-notes-list></app-pr-notes-list>
    </div>
    <div class="h-100" *ngIf="rightSideBar === 'tasks'">
      <app-pr-tasks-list></app-pr-tasks-list>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
