import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ApiService, ApiUploadResult } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class IntakeService implements OnDestroy {
  private destroy$ = new Subject<void>();

  saveForm = (form: JobDescForm) => this.api.post<void>({
    path: 'intake/save',
    body: { form }
  }).pipe(
    takeUntil(this.destroy$)
  );

  saveCriteria = (jobRoleId: string | null, jobDescText: string | null) => this.api.post<CriteriaResult[]>({
    path: 'intake/criteria',
    body: { jobRoleId, jobDescText }
  }).pipe(
    takeUntil(this.destroy$)
  );

  upload = (files: File[]) => {
    return combineLatest(
      files.map(file => {
        const body = new FormData();
        body.append('file', file, file.name);

        return this.api.upload<void>({ path: 'intake/save', body });
      })
    ).pipe(
      map(results => {
        const result: ApiUploadResult = {
          progress: results.map(x => x.progress[0]),
          uploaded: results.map(x => x.uploaded).reduce((a, b) => a + b, 0),
          failed: results.map(x => x.failed).reduce((a, b) => a + b, 0),
          tooLarge: results.map(x => x.tooLarge).reduce((a, b) => a + b, 0)
        };
        return result;
      })
    );
  };

  constructor(
    private api: ApiService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface JobDescForm {
  jobDesc?: string,
  jobFamilyId?: string,
  jobGroupId?: string,
  jobRoleId?: string,
}

interface CriteriaResult {
  key: string
  items: {
    value: number
    text: string
    mandatory?: boolean
    preferred?: boolean
  }[]
}
