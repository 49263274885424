import { CandidatesService, bulkAddToShortList } from 'src/app/services/api/candidates.service';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, of, share, startWith, switchMap, takeUntil } from 'rxjs';
import { CandidatePrioritization, XrefService } from 'src/app/services/api/xref.service';
import { createSuccessResult } from 'src/app/utils/api-helpers';
import { successData } from 'src/app/utils/rxjs-operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-prioritize-modal',
  templateUrl: './prioritize-modal.component.html',
  styleUrls: ['./prioritize-modal.component.css']
})
export class PrioritizeModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  form = this.fb.group(
    {
      prioritize: this.fb.group({})
    },
    {
      validators: [OneSelected('prioritize')]
    }
  );

  candidatePrioritization$ = this.xrefService.getCandidatePrioritizations().pipe(successData(), takeUntil(this.destroy$));

  submitted = false;
  counts: number;
  selectedPrioritize = '';
  successMessage = '';
  candidatePrioritizationList: CandidatePrioritization[] = [];
  loading = false;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PrioritizeModalComponent>,
    private snackBar: MatSnackBar,
    private refreshSvc: RefreshService,

    public xrefService: XrefService,
    public candidatesService: CandidatesService
  ) {
    this.counts = this.data?.selectedCandidates.length;
  }

  ngOnInit() {
    this.candidatePrioritization$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.candidatePrioritizationList = data;
      this.candidatePrioritizationList.forEach(element => {
        this.prioritize.addControl(element.priorityId?.toString(), new FormControl(false));
      });
    });
  }

  get prioritize() {
    return this.form.get('prioritize') as FormGroup;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkSelected(id: string, name: string) {
    this.selectedPrioritize = name;
    if (this.prioritize.get(id)?.value)
      Object.keys(this.prioritize.controls).forEach(key => {
        if (id !== key) this.prioritize.get(key)?.setValue(false, { emitEvent: false });
      });
    else this.selectedPrioritize = '';
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      this.loading = true;
      const priorityId = Object.keys(this.form.controls.prioritize.controls).find(key => this.form.controls.prioritize.get(key)?.value === true);
      const body: bulkAddToShortList = {
        jobId: this.data.jobId,
        priorityId: Number(priorityId),
        candidates: this.data.selectedCandidates
      };

      of(null)
        .pipe(
          switchMap(() => this.candidatesService.addToShortList(body)),
          switchMap(result => {
            if (result.finished) {
              this.loading = false;

              this.successMessage = result.success
                ? this.counts + `${this.counts === 1 ? ' candidate was' : ' candidates were'}` + ' added to priority ' + this.selectedPrioritize
                : 'An error occured, please try again later';

              this.dialogRef.close();

              this.refreshSvc.setRefreshObs(true);

              this.snackBar.open(this.successMessage, 'Close', { duration: 5 * 1000 });
            }

            return of(result);
          }),
          share(),
          startWith(createSuccessResult(undefined)),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }
}

function OneSelected(controlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control = formGroup.get(controlName);
    if (Object.values(control?.value).findIndex(x => x === true) === -1) {
      control?.setErrors({ mustselect: true });
    } else {
      control?.setErrors(null);
    }
    return null;
  };
}
