import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as mammoth from 'mammoth';
import { Observable, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient) { }

  convertDocxToHtml(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      switchMap(blob => {
        return this.convertBlobToHtml(blob);
      }),
      catchError(() => {
        return throwError(() => new Error('Failed to convert DOCX to HTML'));
      })
    );
  }

  private convertBlobToHtml(blob: Blob): Observable<string> {
    return new Observable<string>(subscriber => {
      const reader = new FileReader();
      reader.onloadend = () => {
        mammoth.convertToHtml({ arrayBuffer: reader.result as ArrayBuffer })
          .then(result => {
            subscriber.next(result.value);
            subscriber.complete();
          })
          .catch(error => subscriber.error(error));
      };
      reader.onerror = error => subscriber.error(error);
      reader.readAsArrayBuffer(blob);
    });
  }

  fetchHtmlContent(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'text' });
  }
}
