import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor() {}

  private refreshObs$ = new BehaviorSubject<any>(null);
  private refreshStages$ = new BehaviorSubject<any>(null);

  getRefreshObs(): Observable<any> {
    return this.refreshObs$;
  }

  setRefreshObs(trigger: boolean) {
    this.refreshObs$.next(trigger);
  }

  getRefreshStagesObs(): Observable<any> {
    return this.refreshStages$;
  }

  setRefreshStagesObs(trigger: boolean) {
    this.refreshStages$.next(trigger);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
