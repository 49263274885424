import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { EmailActionsComponent } from './email-actions.component';
import { EmailActionsRoutingModule } from './email-actions.routing.module';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InputSelectControlModule } from 'src/app/components/input-select-control/input-select-control.module';
import { CandidateQuestionnaireComponent } from './candidate-questionnaire/candidate-questionnaire.component';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { PhoneFormControlDirective } from 'src/app/directives/phone-form-control.directive';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'MM/dd/yyyy' // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'MM/dd/yyyy', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};
@NgModule({
  imports: [

    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    EmailActionsRoutingModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    InputSelectControlModule,
    SharedModule,
    MatStepperModule,
    MatDatepickerModule

  ],
  declarations: [EmailActionsComponent, CandidateQuestionnaireComponent, PhoneFormControlDirective],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }]
})
export class EmailActionsModule { }
