import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgArrayPipesModule } from 'ngx-pipes-updated';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';

import { CandidateMenuModule } from 'src/app/components/pr-candidate-menu/pr-candidate-menu.module';
import { NotesListModule } from 'src/app/components/pr-notes-list/pr-notes-list.module';
import { TasksListModule } from 'src/app/components/pr-tasks-list/pr-tasks-list.module';
import { UserAvatarModule } from 'src/app/components/user-avatar/user-avatar.module';

import { ApiResultsMergePipeModule } from 'src/app/pipes/api-results-merge.module';

import { CandidateSidebarComponent } from './pr-candidate-sidebar.component';
import { FormatContactInfoPipeModule } from 'src/app/pipes/format-contact-info.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgArrayPipesModule,

    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTabsModule,

    CandidateMenuModule,
    NotesListModule,
    TasksListModule,
    UserAvatarModule,

    ApiResultsMergePipeModule,
    FormatContactInfoPipeModule
  ],
  declarations: [CandidateSidebarComponent],
  exports: [CandidateSidebarComponent]
})
export class CandidateSidebarModule { }
