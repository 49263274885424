<ng-container *ngIf="{
  jwtParams: jwtParams$ | async,
  saveFormSubmit: saveFormSubmit$ | async,
  jobRoleLevels: jobRoleLevels$ | async,
  criteria: criteria$ | async
} as results">

  <ng-container *ngIf="{
      saveLoading: results.saveFormSubmit && results.saveFormSubmit.submitted && results.saveFormSubmit.valid && !results.saveFormSubmit.result,
      saveFinished: results.saveFormSubmit && results.saveFormSubmit.submitted && results.saveFormSubmit.valid && results.saveFormSubmit.result && results.saveFormSubmit.result.finished,
    } as status">

    <ng-container *ngIf="{
        finished: status.saveFinished,
        loading: status.saveLoading
      } as status">

      <div style="height: 30%;" fxLayout="column" fxLayoutAlign="center center" *ngIf="status.loading">
        <mat-spinner color="accent"></mat-spinner>
      </div>

      <div fxLayout="column" fxFlex="grow" fxLayoutAlign="center center" *ngIf="results.saveFormSubmit">
        <div class="m-4 p-4 floating-panel w-75 scroll-y" cdkScrollable>
          <div fxLayout="column" fxLayoutAlign="center center">
            <div class="p-3 w-100">
              <h1 class="text-center">Intake Form</h1>

              <mat-stepper [linear]="true" #stepper>
                <!--Step One: Job Role/Desc/Family/etc-->
                <mat-step [stepControl]="form" label="Job Group">
                  <form (ngSubmit)="saveForm()" [formGroup]="form" method="post" fxLayout="column" novalidate>
                    <div>
                      <div class="row">
                        <div class="col-6 py-4">
                          <div class="container-fluid mt-2 px-0">
                            <div class="row my-2">
                              <div class="col">
                                <mat-label>Job Role</mat-label>
                                <mat-form-field class="w-100"
                                  [class.no-underline]="!results.saveFormSubmit.submitted || !form.controls.jobRoleId.hasError('required')">
                                  <mat-label>Job Role</mat-label>
                                  <app-input-select-control name="jobRoleId" autocomplete="jobRoleId"
                                    formControlName="jobRoleId" [api]="searchRoles"
                                    [required]="hasRequiredField(form.controls.jobRoleId)">
                                  </app-input-select-control>
                                  <mat-icon class="px-2" matSuffix>search</mat-icon>
                                  <mat-error *ngIf="form.controls.jobRoleId.hasError('required')">
                                    Job Role is required.
                                  </mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <div class="container-fluid mt-4 px-0">
                            <div class="row">
                              <div class="col">
                                <mat-label>Job Family</mat-label>
                                <mat-form-field class="w-100 no-underline">
                                  <mat-label>Job Family</mat-label>
                                  <app-input-select-control name="jobSubFamilyId" autocomplete="jobSubFamilyId"
                                    formControlName="jobSubFamilyId" [api]="searchSubFamilies"
                                    [text]="form.controls.jobSubFamilyText.value">
                                  </app-input-select-control>
                                  <mat-icon class="px-2" matSuffix>search</mat-icon>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <div class="container-fluid mt-4 px-0">
                            <div class="row">
                              <div class="col-6">
                                <mat-label>Job Level</mat-label>
                                <mat-form-field class="w-100 no-underline"
                                  *ngIf="results.jobRoleLevels && results.jobRoleLevels.success">
                                  <mat-label>Job Level</mat-label>

                                  <mat-select multiple>
                                    <mat-option [value]="level.value" *ngFor="let level of results.jobRoleLevels.data">
                                      {{ level.text }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <div class="container-fluid mt-4 px-0">
                            <mat-label>Location</mat-label>
                            <div class="row my-2">
                              <div class="col-6">
                                <mat-form-field class="no-underline mb-2 w-100">
                                  <mat-label>City</mat-label>
                                  <app-input-select-control name="geoId" autocomplete="geoId" formControlName="geoId"
                                    [api]="searchCity">
                                  </app-input-select-control>
                                  <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <div class="container-fluid mt-4 px-0">
                            <mat-label>Remote or Onsite</mat-label>
                            <div class="row my-2">
                              <div class="col ps-0">
                                <mat-radio-group formControlName="remoteOrOnsite">
                                  <mat-radio-button value="remote">Remote</mat-radio-button>
                                  <mat-radio-button value="onsite" class="ps-2">Onsite</mat-radio-button>
                                </mat-radio-group>
                              </div>
                            </div>
                          </div>
                          <div class="container-fluid mt-4 px-0">
                            <mat-label>Salary Range</mat-label>
                            <div class="row my-2">
                              <div class="col-4">
                                <div class="d-flex align-items-center gap-2">
                                  <mat-label>From</mat-label>
                                  <mat-form-field class="no-underline">
                                    <span matTextPrefix>$&nbsp;</span>
                                    <input matInput type="text" formControlName="salaryFrom" />
                                    <span matTextSuffix>&nbsp;annually</span>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="col-4">
                                <div class="d-flex align-items-center gap-2">
                                  <mat-label>To</mat-label>
                                  <mat-form-field class="no-underline">
                                    <span matTextPrefix>$&nbsp;</span>
                                    <input matInput type="text" formControlName="salaryTo" />
                                    <span matTextSuffix>&nbsp;annually</span>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 py-4">
                          <div class="mt-2">
                            <mat-label>Job Description</mat-label>
                            <div class="row pb-2" *ngIf="false">
                              <div class="col-4">
                                <button color="primary" mat-raised-button (click)="uploadJobDescription()">
                                  <mat-icon class="mat-color-white">cloud_upload</mat-icon>
                                  Upload
                                </button>
                              </div>
                            </div>
                            <div class="row pt-2">
                              <div class="col">
                                <div class="NgxEditor__Wrapper m-auto">
                                  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                                  <ngx-editor [editor]="editor" [placeholder]="'Enter job description...'"
                                    [required]="hasRequiredField(form.controls.jobDesc)" formControlName="jobDesc">
                                  </ngx-editor>
                                </div>
                                <mat-error class="mat-small"
                                  *ngIf="results.saveFormSubmit.submitted && form.controls.jobDescText.hasError('required')">
                                  Job Description is required.
                                </mat-error>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3" fxLayoutAlign="end">
                        <button color="primary" mat-raised-button matStepperNext>
                          <mat-icon>arrow_forward</mat-icon>Next
                        </button>
                      </div>
                    </div>
                  </form>
                </mat-step>
                <!--Step Two: Roles/Skills/Certifications-->
                <mat-step label="Criteria">
                  <ng-container *ngIf="{
                    certificates: allJobCertifications$ | async,
                    skills: allJobSkills$ | async,
                    jobRoles: allJobRoles$ | async,
                  } as dropdownResults">

                    <div class="row">
                      <!--State toggles-->
                      <ng-container *ngIf="results.criteria && results.criteria.success">
                        <ng-container *ngFor="let criteria of results.criteria.data">
                          <div class="py-2">
                            <div class="card h-100">
                              <div class="card-header">
                                <mat-label class="fw-medium">{{criteria.key | formatCamelCaseToText}}</mat-label>
                              </div>
                              <div class="row p-4">
                                <!--Required-->
                                <div class="col">
                                  <div class="card h-100">
                                    <div class="card-header">Required</div>
                                    <div class="card-body">
                                      <app-chip-typeahead class="w-100" [api]="searchCriteria(criteria.key)"
                                        [addText]="criteria.key | formatCamelCaseToText" [textKey]="'text'"
                                        [valueKey]="'value'" [(items)]="criteria.required"
                                        (itemsChange)="refreshSearchCriteria$.next()"></app-chip-typeahead>
                                    </div>
                                  </div>
                                </div>
                                <!--Preferred-->
                                <div class="col">
                                  <div class="card h-100">
                                    <div class="card-header">Preferred</div>
                                    <div class="card-body">
                                      <app-chip-typeahead class="w-100" [api]="searchCriteria(criteria.key)"
                                        [addText]="criteria.key | formatCamelCaseToText" [textKey]="'text'"
                                        [valueKey]="'value'" [(items)]="criteria.preferred"
                                        (itemsChange)="refreshSearchCriteria$.next()"></app-chip-typeahead>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                      <div class="mt-3" fxLayoutAlign="space-between">
                        <button color="primary" mat-raised-button (click)="previous(stepper)">
                          <mat-icon>arrow_backward</mat-icon>Previous
                        </button>
                        <button color="primary" mat-raised-button matStepperNext>
                          <mat-icon>arrow_forward</mat-icon>Next
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </mat-step>
                <!--Step Three: Candidates (TBD)-->
                <mat-step label="Candidates">
                  <h1>Candidate placeholder</h1>
                  <div class="mt-3" fxLayoutAlign="space-between">
                    <button color="primary" mat-raised-button matStepperPrevious>
                      <mat-icon>arrow_backward</mat-icon>Previous
                    </button>
                    <button color="primary" mat-raised-button matStepperNext>
                      <mat-icon>check</mat-icon>Submit
                    </button>
                  </div>
                </mat-step>
              </mat-stepper>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #confirmPreviousStepTemplate let-data>
    <div mat-dialog-title>{{data.dialog.title}}</div>

    <div mat-dialog-content class="scroll-y">
      If you return to job description, any changes made during this step will be reverted.
    </div>

    <div mat-dialog-actions>
      <div class="m-auto"></div>
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [mat-dialog-close]="true">Confirm</button>
    </div>
  </ng-template>
</ng-container>