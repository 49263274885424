<mat-toolbar class="mat-elevation-z5 d-flex justify-content-between" color="primary">
  <div class="d-flex">
    <ng-content select="[left]"></ng-content>
    <a class="ms-2" fxLayout="row" fxLayoutAlign="start center" [routerLink]="auth.landingPage">
      <img class="c-pointer" width="160" height="40" style="outline: 0; height: auto; width: 100%" alt="ProRank" src="assets/images/logo.png" />
    </a>
  </div>

  <div class="d-flex" style="flex: 0 0 50%">
    <span class="spacer"></span>
    <ng-content select="[center]"></ng-content>
    <span class="spacer"></span>
  </div>

  <div class="d-flex align-items-center justify-content-end mat-body">
    <div class="me-5 text-center body-sm" *ngIf="auth.name">
      <div class="mb-1">Logged in as</div>
      <div class="ls-1">
        <b>{{ auth.name }}</b>
      </div>
    </div>
    <ng-content select="[right]"></ng-content>
  </div>
</mat-toolbar>

<mat-progress-bar class="mat-elevation-z5" color="accent" [mode]="progress.mode.value" [value]="progress.value.value" *ngIf="progress.mode.value !== 'none'"> </mat-progress-bar>
